import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { DialogAppLicenseModify } from '../licenses/licenses.component';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css'],
})
export class TermsAndConditionsComponent implements OnInit {
  constructor(
    private _location: Location,
    public dialogRef: MatDialogRef<DialogAppLicenseModify>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}
  goBack() {
    this.dialogRef.close();
  }
}
