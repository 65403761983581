<div class="ces_main_div">
  <div class="ces_header">
    <span *ngIf="!isModify">Add Account Manager</span>
    <span *ngIf="isModify">Modify Account Manager Details</span>
    <div class="header_quik_functions">
      <img
        src="../../../assets/img/reset_icon.svg"
        alt=""
        *ngIf="isModify"
        (click)="handleChangePasswordBMUser()"
      />
      <img
        src="../../../assets/img/remove_icon_bold.svg"
        alt=""
        *ngIf="isModify"
        (click)="handleDeleteBMUser()"
      />
      <img src="../../../assets/img/close.svg" alt="" (click)="close()" />
    </div>
  </div>
  <div class="ces_content_main_div">
    <div class="ces_inp_main_div">
      <div class="ces_pd_main_div">
        <div class="ces_pd_inp_main_div">
          <div class="ces_pd_inp_sub_div">
            <label for="name">Name</label>
            <input type="text" [(ngModel)]="bmName" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" [(ngModel)]="bmEmail" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" [(ngModel)]="bmNumber" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">Address</label>
            <input type="text" [(ngModel)]="bmAddress" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="name">City</label>
            <input type="text" [(ngModel)]="bmCity" />
          </div>
          <div class="license_modify_pd_inp_sub_div" *ngIf="isModify">
            <label for="pin_code">Status</label>
            <div class="dropdown">
              <button
                class="btn dropdown-toggle updateSelect input_disable"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="
                  width: 100%;
                  text-align: left;
                  min-height: 48px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span *ngIf="bmtxtStatus == ''">&nbsp;</span>
                <span
                  *ngIf="bmtxtStatus != ''"
                  [ngClass]="bmtxtStatus == 'active'?'colorGreen':bmtxtStatus == 'inactive'?'colorRed':'colorBlue'"
                >
                  {{bmtxtStatus}}</span
                >
              </button>
              <ul class="dropdown-menu" style="width: 100%">
                <li
                  class="dropdown-item"
                  style="color: #12c058"
                  (click)="handleStatusChange('Active')"
                >
                  Active
                </li>
                <li
                  class="dropdown-item"
                  style="color: #d43738"
                  (click)="handleStatusChange('Inactive')"
                >
                  In Active
                </li>
                <!-- <li class="dropdown-item" style="color: #406CDE;"
                                    (click)="handleStatusChange('Unassigned')">
                                        Unassigned</li> -->
              </ul>
            </div>
          </div>
          <!-- <div class="ces_pd_inp_sub_div" *ngIf="isModify">
                    <label for="name">Address</label>
                    <input type="text"  [(ngModel)]="bmCity">
                </div> -->
          <div class="ces_pd_inp_sub_div">
            <label for="address">State</label>
            <ng-select
              class="multi-select"
              [items]="allState"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              placeholder="Select state"
              (change)="onMaterialGroupChange($event)"
              [(ngModel)]="bmSelectedState"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>

          <!-- <div class="ces_pd_inp_sub_div" *ngIf="isModify">
                    <label for="password">Assign Account Manager</label>
                    <div style="display: flex; justify-content: space-between;"  (click)="handleAssignment('am',assign_account_manager?assign_account_manager.id:0,data.id)">
                        <span>{{assign_account_manager == null ? 'None' :
                            assign_account_manager.name}}</span>
                        <img src="../../../assets/img/arrow_drop_down_ICON.svg" alt="">
                    </div>                
                </div>  -->

          <div class="ces_pd_inp_sub_div" *ngIf="!isModify">
            <label for="password">Create Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="conpasswordToggleHide?'password':'text'"
                name="bmPassword"
                id=""
                [(ngModel)]="bmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="conpasswordToggleHide"
                (click)="conpasswordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!conpasswordToggleHide"
                (click)="conpasswordToggleHide = true"
              />
            </div>
          </div>
          <div class="ces_pd_inp_sub_div" *ngIf="!isModify">
            <label for="confirm_password">Confirm Password</label>
            <div class="passwordToggler">
              <input
                autocomplete="off"
                [type]="passwordToggleHide?'password':'text'"
                name="bmConfirmPassword"
                id=""
                [(ngModel)]="bmConfirmPassword"
              />
              <img
                src="../../../assets/img/eye-close-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="passwordToggleHide"
                (click)="passwordToggleHide = false"
              />
              <img
                src="../../../assets/img/eye-2-line.svg"
                alt=""
                style="cursor: pointer; margin-right: 10px"
                *ngIf="!passwordToggleHide"
                (click)="passwordToggleHide = true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="flex: 1">&nbsp;</div>
  <div class="ces_btn_main_div" style="margin-bottom: 2rem">
    <div class="ces_pd_btn_div">
      <div (click)="close()">CANCEL</div>
      <div (click)="addNewUser()" *ngIf="!isModify">ADD</div>
      <div (click)="modifyNewUser()" *ngIf="isModify">Update</div>
    </div>
  </div>
</div>

<style>
  .add_user_header {
    display: flex;
    gap: 26px;
    color: #484a46;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.28px;
  }

  .add_user_header input[type="radio"] {
    cursor: pointer;
    accent-color: #0a9b45;
  }

  .add_user_header span {
    margin-left: 10px;
  }
</style>
