<div class="modify_doc_main_div">
  <div style="display: contents;">
      <div class="header_main" style="justify-content: space-between;">
          <div class="header_main" style="padding: 10px 0;">
              <img src="../../../assets/img/back_arrow.svg" alt="" style="cursor: pointer;" (click)="close()">
              <span class="modify_doc_header_div" *ngIf="!data.addNew">MODIFY {{typeTitle}}</span>
              <span class="modify_doc_header_div" *ngIf="data.addNew">CREATE {{typeTitle}}</span>
          </div>
      </div>
      <!-- Application -->
      <div *ngIf="type.application" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Document Name</label>
                  <!-- <input type="text" name="name" [(ngModel)]="docName"> -->
                  <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'" [items]="allTypeOfDocs" bindLabel="name" bindValue="name"
                  [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true" name="documentName"
                  placeholder="Select Document Name" 
                  [(ngModel)]="docName"
                  (change)="onDocumentNameChange($event)"
                  [disabled]="role== '1' || role== '2' ?false:true"
                  >
                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                </ng-template>
              </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Description</label>
                  <input type="email" name="description" [(ngModel)]="docDes" [ngClass]="role!=3&&role!=5 ? '':'blocked'" [disabled]="role== '1' || role== '2' ?false:true">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="state">Status</label>
                  <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                      [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                      placeholder="Select Document Status" 
                      [(ngModel)]="selectedStatus"
                      >

                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                      </ng-template>
                  </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                <label for="upload">Upload Documents</label>
                <div class="license_upload_sub_div" [class.disabled]="!isModifying">
                  <input type="file" 
                         accept="image/png,image/jpg,.pdf" 
                         (change)="onImageChange($event)"
                         #fileInput_image 
                         hidden 
                         id="file_inp_doc" 
                         [disabled]="!isModifying" />
                  <div (click)="isModifying && fileInput_image.click()" 
                       [class.disabled-button]="!isModifying">
                    Choose file
                  </div>
                  <div>
                    <span *ngIf="!isModifying">{{docName && 'TEMPLATE -'}} {{docName}}</span>
                    <span *ngIf="isModifying && fileName == ''">No file chosen</span>
                    <span *ngIf="isModifying && fileName != ''">{{fileName}}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div> -->
              <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

              </div>
               
          </div>
          <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
              <div class="d-flex justify-content-between" style="padding-bottom: 1rem;">
                  <label for="state">Recent Status Changes</label>
                  <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
              </div>
              
              <div class="license_modify_pd_main_divq headerr" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              border-bottom: 2px solid #ddd;
              padding: 10px 0;">
                  <div style="min-width:5rem;">User Name</div>
                  <div style="min-width:5rem;">From Status</div>
                  <div style="min-width:5rem;">To Status</div>
                  <div style="min-width:15rem;">Status Changed on</div>
              </div>

              <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
              <div class="license_modify_pd_main_divq" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              padding: 10px 0;">
                  <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                  <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                  <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                  <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
              </div>
              </div>
          </div> 
      </div>
      <!-- Testing -->
       <div style=" display: grid; grid-template-columns: 90% 10%;">
      <div *ngIf="type.testing" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Product Name</label>
                  <!-- <input type="text" name="name" [(ngModel)]="productName"> -->
                  <ng-select [items]="products"
                  bindLabel="product_name"
                  bindValue="product_name"
                  [typeahead]="productNameInput$"
                  [(ngModel)]="productName"
                  (ngModelChange)="onProductNameChange($event)"
                  placeholder="Select or search an product name">
               </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Product ID</label>
                  <!-- <input type="email" name="id" [(ngModel)]="productId"> -->
                  <ng-select [items]="products"
                  bindLabel="product_id"
                  bindValue="product_id"
                  [typeahead]="productIdInput$"
                  [(ngModel)]="productId"
                  (ngModelChange)="onProductIdChange($event)"
                  placeholder="Select or search an product Id">
               </ng-select>
              </div>
              
            
              <div class="license_modify_pd_inp_sub_div">
                  <label for="date">Product Date</label>
                  <input type="date" name="date" [(ngModel)]="testingDate">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="state">Status</label>
                  <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                      [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                      placeholder="Select Document Status" 
                      [(ngModel)]="selectedStatus">

                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                      </ng-template>
                  </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="upload">Upload Documents</label>
                  <div class="license_upload_sub_div">
                      <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                          #fileInput_image hidden id="file_inp_doc" />
                      <div (click)="fileInput_image.click()">
                          Choose file
                      </div>
                      <div>
                          <span *ngIf="fileName == ''">No file chosen</span>
                          <span *ngIf="fileName != ''">{{fileName}}</span>
                      </div>
                  </div>
              </div>
              <!-- <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div> -->
              <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

              </div>
          </div>
          <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
              <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                  <label for="state">Recent Status Changes</label>
                  <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
              </div>
              
              <div class="license_modify_pd_main_divq headerr" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              border-bottom: 2px solid #ddd;
              padding: 10px 0;">
                  <div style="min-width:5rem;">User Name</div>
                  <div style="min-width:5rem;">From Status</div>
                  <div style="min-width:5rem;">To Status</div>
                  <div style="min-width:15rem;">Status Changed on</div>
              </div>

              <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
              <div class="license_modify_pd_main_divq" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              padding: 10px 0;">
                  <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                  <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                  <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                  <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
              </div>
              </div>
          </div> 
      </div>
       <!-- Training -->
       <div *ngIf="type.training" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Employee Name</label>
                  <!-- <input type="text" name="name" [(ngModel)]="trainingName" > -->
                   <ng-select [items]="employees"
                      bindLabel="employee_name"
                      bindValue="employee_name"
                      [typeahead]="employeeNameInput$"
                      [(ngModel)]="trainingName"
                      (ngModelChange)="onEmployeeNameChange($event)"  
                      placeholder="Select or search an employee">
                      </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Employee ID</label>
                  <!-- <input type="email" name="id" [(ngModel)]="trainingId" > -->
                  <ng-select [items]="employees"
                     bindLabel="employee_id"
                     bindValue="employee_id"
                     [typeahead]="employeeIdInput$"
                     [(ngModel)]="trainingId"
                     (ngModelChange)="onEmployeeIdChange($event)"
                     placeholder="Select or search an employee ID">
                  </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Training Date</label>
                  <input type="date" name="date" [(ngModel)]="trainingDate" >
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="state">Status</label>
                  <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                      [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                      placeholder="Select Document Status" 
                      [(ngModel)]="selectedStatus">

                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                      </ng-template>
                  </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="upload">Upload Documents</label>
                  <div class="license_upload_sub_div">
                      <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                          #fileInput_image hidden id="file_inp_doc" />
                      <div (click)="fileInput_image.click()">
                          Choose file
                      </div>
                      <div>
                          <span *ngIf="fileName == ''">No file chosen</span>
                          <span *ngIf="fileName != ''">{{fileName}}</span>
                      </div>
                  </div>
              </div>
              <!-- <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div> -->
              <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

              </div>
          </div>
          <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
              <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                  <label for="state">Recent Status Changes</label>
                  <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
              </div>
              
              <div class="license_modify_pd_main_divq headerr" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              border-bottom: 2px solid #ddd;
              padding: 10px 0;">
                  <div style="min-width:5rem;">User Name</div>
                  <div style="min-width:5rem;">From Status</div>
                  <div style="min-width:5rem;">To Status</div>
                  <div style="min-width:15rem;">Status Changed on</div>
              </div>

              <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
              <div class="license_modify_pd_main_divq" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              padding: 10px 0;">
                  <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                  <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                  <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                  <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
              </div>
              </div>
          </div> 
      </div>
       <!-- Health -->
       <div *ngIf="type.health" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Employee Name</label>
                  <!-- <input type="text" name="name" [(ngModel)]="healthName"> -->
                  <ng-select [items]="employees"
                      bindLabel="employee_name"
                      bindValue="employee_name"
                      [typeahead]="employeeNameInput$"
                      [(ngModel)]="healthName"
                      (ngModelChange)="onEmployeeNameChange($event)"  
                      placeholder="Select or search an employee">
                  </ng-select>
              </div>

              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Employee ID</label>
                  <!-- <input type="email" name="id" [(ngModel)]="healthId"> -->
                  <ng-select [items]="employees"
                      bindLabel="employee_id"
                      bindValue="employee_id"
                      [typeahead]="employeeIdInput$"
                      [(ngModel)]="healthId"
                      (ngModelChange)="onEmployeeIdChange($event)"
                      placeholder="Select or search an employee ID">
               </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Report Date</label>
                  <input type="date" name="date" [(ngModel)]="healthDate">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="state">Status</label>
                  <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                      [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                      placeholder="Select Document Status" 
                      [(ngModel)]="selectedStatus">

                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                      </ng-template>
                  </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="upload">Upload Documents</label>
                  <div class="license_upload_sub_div">
                      <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                          #fileInput_image hidden id="file_inp_doc" />
                      <div (click)="fileInput_image.click()">
                          Choose file
                      </div>
                      <div>
                          <span *ngIf="fileName == ''">No file chosen</span>
                          <span *ngIf="fileName != ''">{{fileName}}</span>
                      </div>
                  </div>
              </div>
              <!-- <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div> -->
              <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

              </div>
          </div>
          <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
              <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                  <label for="state">Recent Status Changes</label>
                  <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
              </div>
              
              <div class="license_modify_pd_main_divq headerr" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              border-bottom: 2px solid #ddd;
              padding: 10px 0;">
                  <div style="min-width:5rem;">User Name</div>
                  <div style="min-width:5rem;">From Status</div>
                  <div style="min-width:5rem;">To Status</div>
                  <div style="min-width:15rem;">Status Changed on</div>
              </div>

              <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
              <div class="license_modify_pd_main_divq" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              padding: 10px 0;">
                  <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                  <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                  <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                  <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
              </div>
              </div>
          </div> 
      </div>
      <!-- Calibration -->
      <div *ngIf="type.calibiration" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Instrument Name</label>
                  <!-- <input type="text" name="name" [(ngModel)]="calibirationName"> -->
                  <ng-select [items]="instruments"
                      bindLabel="equipment_name"
                      bindValue="equipment_name"
                      [typeahead]="equipmentNameInput$"
                      [(ngModel)]="calibirationName"
                      (ngModelChange)="onEquipmentNameChange($event)"
                      placeholder="Select or search an instrument name">
               </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Equipment ID</label>
                  <!-- <input type="email" name="id" [(ngModel)]="calibirationId"> -->
                  <ng-select [items]="instruments"
                      bindLabel="instrument_id"
                      bindValue="instrument_id"
                      [typeahead]="equipmentIdInput$"
                      [(ngModel)]="calibirationId"
                      (ngModelChange)="onEquipmentIdChange($event)"
                      placeholder="Select or search an Equipment ID">
                  </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Calibration Date</label>
                  <input type="date" name="date" [(ngModel)]="calibirationDate">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="state">Status</label>
                  <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                      [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                      placeholder="Select Document Status" 
                      [(ngModel)]="selectedStatus">

                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                      </ng-template>
                  </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="upload">Upload Documents</label>
                  <div class="license_upload_sub_div">
                      <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                          #fileInput_image hidden id="file_inp_doc" />
                      <div (click)="fileInput_image.click()">
                          Choose file
                      </div>
                      <div>
                          <span *ngIf="fileName == ''">No file chosen</span>
                          <span *ngIf="fileName != ''">{{fileName}}</span>
                      </div>
                  </div>
              </div>
              <!-- <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div> -->
              <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

              </div>
          </div>
          <div style="width:100%;padding:2rem;padding-top: 0" *ngIf="!data.addNew">
              <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                  <label for="state">Recent Status Changes</label>
                  <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
              </div>
              
              <div class="license_modify_pd_main_divq headerr" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              border-bottom: 2px solid #ddd;
              padding: 10px 0;">
                  <div style="min-width:5rem;">User Name</div>
                  <div style="min-width:5rem;">From Status</div>
                  <div style="min-width:5rem;">To Status</div>
                  <div style="min-width:15rem;">Status Changed on</div>
              </div>

              <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
              <div class="license_modify_pd_main_divq" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              padding: 10px 0;">
                  <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                  <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                  <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                  <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
              </div>
              </div>
          </div> 
      </div>
      <div style="padding-top: 70px;" *ngIf="type.training || type.health || type.testing || type.calibiration">
        <img  (click)="openAddMasterDialogue()" style="width: 40px;" src="../../../assets//img//serviceReq.svg"/>
      </div>
    </div>
      <!-- Others -->
      <div *ngIf="type.other" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Document Name</label>
                  <input type="text" name="name" [(ngModel)]="othersName">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Description</label>
                  <input type="email" name="id"  [(ngModel)]="othersDes">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Expiration Date</label>
                  <input type="date" name="date" [(ngModel)]="othersDate">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="state">Status</label>
                  <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                      [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                      placeholder="Select Document Status" 
                      [(ngModel)]="selectedStatus">

                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                      </ng-template>
                  </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="upload">Upload Documents</label>
                  <div class="license_upload_sub_div">
                      <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                          #fileInput_image hidden id="file_inp_doc" />
                      <div (click)="fileInput_image.click()">
                          Choose file
                      </div>
                      <div>
                          <span *ngIf="fileName == ''">No file chosen</span>
                          <span *ngIf="fileName != ''">{{fileName}}</span>
                      </div>
                  </div>
              </div>
              <!-- <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div> -->
              <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->
              </div>
          </div>
          <div style="width:100%;padding:2rem;padding-top: 0;padding-bottom: 1rem;" *ngIf="!data.addNew">
              <div class="d-flex justify-content-between"  style="padding-bottom: 1rem;">
                  <label for="state">Recent Status Changes</label>
                  <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
              </div>
              
              <div class="license_modify_pd_main_divq headerr" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              border-bottom: 2px solid #ddd;
              padding: 10px 0;">
                  <div style="min-width:5rem;">User Name</div>
                  <div style="min-width:5rem;">From Status</div>
                  <div style="min-width:5rem;">To Status</div>
                  <div style="min-width:15rem;">Status Changed on</div>
              </div>

              <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
              <div class="license_modify_pd_main_divq" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              padding: 10px 0;">
                  <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                  <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                  <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                  <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
              </div>
              </div>
          </div> 
      </div>
      <div *ngIf="type.audits" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Audit Name</label>
                  <input type="text" name="name" [(ngModel)]="auditsName">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Audit ID</label>
                  <input type="email" name="description" [(ngModel)]="auditsDes">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Audit Date</label>
                  <input type="date" name="date" [(ngModel)]="auditsDate">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="state">Status</label>
                  <ng-select class="multi-select" [ngClass]="role!=3&&role!=5 ? '':'blockedd'"  [items]="allStatus" [disabled]="role!= 3 && role!=5 ? false : true" bindLabel="name" bindValue="value"
                      [multiple]="false" groupBy="selectedAllGroup" disabled="" [selectableGroup]="false" name="status"
                      placeholder="Select Document Status" 
                      [(ngModel)]="selectedStatus">

                      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">

                      </ng-template>
                  </ng-select>
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Score</label>
                  <input type="number" min="0" max="1000"  name="description" [(ngModel)]="score">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="upload">Upload Documents</label>
                  <div class="license_upload_sub_div">
                      <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                          #fileInput_image hidden id="file_inp_doc" />
                      <div (click)="fileInput_image.click()">
                          Choose file
                      </div>
                      <div>
                          <span *ngIf="fileName == ''">No file chosen</span>
                          <span *ngIf="fileName != ''">{{fileName}}</span>
                      </div>
                  </div>
              </div>
              <div class="license_modify_pd_inp_sub_div" style="visibility: hidden;height:1rem">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div>
              <div style="margin-bottom: 1rem; height:1rem" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

              </div>
               
          </div>
          <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
              <div class="d-flex justify-content-between" style="padding-bottom: 1rem;">
                  <label for="state">Recent Status Changes</label>
                  <label for="state"  style="color: #6386fa;cursor: pointer;"  (click)="getAudit()">See More</label>
              </div>
              
              <div class="license_modify_pd_main_divq headerr" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              border-bottom: 2px solid #ddd;
              padding: 10px 0;">
                  <div style="min-width:5rem;">User Name</div>
                  <div style="min-width:5rem;">From Status</div>
                  <div style="min-width:5rem;">To Status</div>
                  <div style="min-width:15rem;">Status Changed on</div>
              </div>

              <div *ngFor="let data of recentRepo.slice(0, 3);let i = index" style="border-bottom: 1px solid #ddd;">
              <div class="license_modify_pd_main_divq" style="
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr ;
              gap: 20px;
              padding: 10px 0;">
                  <div style="min-width:5rem;">{{data.name || 'N/A'}}</div>
                  <div style="min-width:5rem;">{{i+1 < recentRepo.length  ? recentRepo[i+1].status : 'N/A'  }}</div>
                  <div style="min-width:5rem;">{{data.status.replace('_',' ')}}</div>
                  <div style="min-width:15rem;">{{getDate(data.datetime)}}</div>
              </div>
              </div>
          </div> 
      </div>
         <!-- EMPOYEEE -->
      <div *ngIf="type.employee" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Employee Name </label>
                 <!--  <ng-select [items]="employees"
                  bindLabel="name"
                  bindValue="name"
                  [typeahead]="employeeNameInput$"
                  [(ngModel)]="employeeName"
                  (ngModelChange)="onEmployeeNameChange($event)"  
                  placeholder="Select or search an employee">
       </ng-select> -->
                  <input type="text" name="name" [(ngModel)]="employeeName">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Employee ID</label>
                  <!-- <ng-select [items]="employees"
                     bindLabel="id"
                     bindValue="id"
                     [typeahead]="employeeIdInput$"
                     [(ngModel)]="employeeId"
                     (ngModelChange)="onEmployeeIdChange($event)"
                     placeholder="Select or search an employee ID">
          </ng-select> -->
                  <input type="email" name="description" [(ngModel)]="employeeId">
              </div>

              <div class="license_modify_pd_inp_sub_div">
                  <label for="upload">Employee Document Upload</label>
                  <div class="license_upload_sub_div">
                      <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                          #fileInput_image hidden id="file_inp_doc" />
                      <div (click)="fileInput_image.click()">
                          Choose file
                      </div>
                      <div>
                          <span *ngIf="fileName == ''">No file chosen</span>
                          <span *ngIf="fileName != ''">{{fileName}}</span>
                      </div>
                  </div>
              </div>
              <!-- <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div> -->
              <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

              </div>
               
          </div>
      </div>
      <!-- PRODUCT -->
      <div *ngIf="type.product" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Product Name</label>
                  <input type="text" name="name" [(ngModel)]="productName">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Product ID</label>
                  <input type="email" name="description" [(ngModel)]="productId">
              </div>

              <div class="license_modify_pd_inp_sub_div">
                  <label for="upload">Product Document Upload</label>
                  <div class="license_upload_sub_div">
                      <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                          #fileInput_image hidden id="file_inp_doc" />
                      <div (click)="fileInput_image.click()">
                          Choose file
                      </div>
                      <div>
                          <span *ngIf="fileName == ''">No file chosen</span>
                          <span *ngIf="fileName != ''">{{fileName}}</span>
                      </div>
                  </div>
              </div>
              <!-- <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div> -->
              <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

              </div>
               
          </div>
      </div>
       <!-- EQIPMENT -->
       <div *ngIf="type.equipment" style="overflow: auto;">
          <div class="license_modify_pd_main_div" style="display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;padding:2rem;padding-bottom: 1rem;">
              <div class="license_modify_pd_inp_sub_div">
                  <label for="name">Instrument Name</label>
                  <input type="text" name="name" [(ngModel)]="eqipmentsName">
              </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Equipment ID</label>
                  <input type="email" name="description" [(ngModel)]="eqipmentsId">
              </div>
              <div>
                <img *ngIf="type.training || type.health || type.testing || type.calibiration" (click)="openAddMasterDialogue()" style="width: 40px;" src="../../../assets//img//serviceReq.svg"/>
            </div>
              <div class="license_modify_pd_inp_sub_div">
                  <label for="upload">Equipment Document Upload</label>
                  <div class="license_upload_sub_div">
                      <input type="file" accept="image/png,image/jpg,.pdf" (change)="onImageChange($event)"
                          #fileInput_image hidden id="file_inp_doc" />
                      <div (click)="fileInput_image.click()">
                          Choose file
                      </div>
                      <div>
                          <span *ngIf="fileName == ''">No file chosen</span>
                          <span *ngIf="fileName != ''">{{fileName}}</span>
                      </div>
                  </div>
              </div>
              <!-- <div class="license_modify_pd_inp_sub_div">
                  <label for="email">Remarks</label>
                  <textarea style="width:100%" class="blockedd" disabled>{{remarks}}</textarea>
              </div> -->
              <div style="margin: 3rem 0;" *ngIf="imageUrl.length>0">
                  <span class="view_license_span" (click)="viewLicence()">View Document</span>
                  <!-- <div styele="margin-left:10px">{{imageUrl.length>0 ? imageUrl.split('/').at(-1): ''}}</div> -->

              </div>
               
          </div>
      </div>
      <div style="flex: 1;">&nbsp;</div>
      <div class="license_modify_btn_main_div"
          style="padding: 0 0rem 10px 0rem;gap: 10px;display: flex;flex-direction: column;;padding:2rem">
          <!-- <div>
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="tcFlag">
              <label class="form-check-label license_term_condition" for="">
                  I agree to the <span (click)="openTerms()">terms of services</span>
              </label>
          </div> -->
          <div class="ces_pd_btn_div" *ngIf="addNew">
              <div (click)="close()">CANCEL</div>
              <div (click)="addNewDtls()">CREATE</div>
          </div>
          <div class="ces_pd_btn_div"  *ngIf="!addNew">
              <div (click)="close()">CANCEL</div>
              <div (click)="updateDtls()">UPDATE</div>
          </div>
      </div>
  </div>
</div>

<style>
  .modify_doc_main_div {
      position: relative;
      min-width: 75vw;
      min-height: 75vh;
      border-radius: 16px;
      background: #F2F2F2;
      box-shadow: 0px 2px 10px 2px rgba(236, 236, 236, 0.25);
      padding-top: 0;
      display: flex;
      flex-direction: column;
      max-height: 95vh;
      overflow-y: hidden;
      /* padding: 2rem; */
  }

  .modify_doc_header_div {
      color: #1D1E1C;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
  }
  .header_main{
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      padding: 1.5rem 2rem;
      margin-bottom: -1rem;
      align-items: center;
      background: #E3EBD8;
      z-index: 10;
  }
</style>