<div class="u2_main_container">
  <div class="u2_user_management">
    <label>USER MANAGEMENT</label>
    <div class="u2_management_outer_container">
      <div class="u2_management_inner_container">
        <div
          class="u2_enterprose_user"
          style="cursor: pointer"
          (click)="moveTab('webUser')"
        >
          <img src="../../../assets/img/beu-icon.svg" />
          <label>Enterprise User</label>
        </div>
        <div class="u2_entriprise_stats">
          <div class="u2_total_entriprise">
            <label>Total Enterprise Users</label>
            <p>{{totalUsersStatus.total_enterprise_user}}</p>
          </div>
          <div class="u2_total_entriprise">
            <img src="../../../assets/img/pajamas_status-green.svg" />
            <label>Active Users</label>
            <p>{{totalUsersStatus.total_active_enterprise_user}}</p>
          </div>
          <div class="u2_total_entriprise">
            <img src="../../../assets/img/pajamas_status-red.svg" />
            <label>In Active Users</label>
            <p>{{totalUsersStatus.total_inactive_enterprise_user}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dash_user_end_div">&nbsp;</div>
    <!-- ------------------------------License Requests ---------------------------- -->
    <div class="u2_license_detail_container">
      <label class="license_header"> LICENSE DETAILS </label>
      <div
        class="dash_user_count_main_div"
        style="grid-template-columns: 1fr 1fr 1fr"
      >
        <div class="dash_user_count_sub_div u3_license_count">
          <div>
            <img src="../../../assets/img/total_licenses-icon.svg" alt="" />
          </div>
          <div
            class="dash_user_count_div"
            (click)="moveTab('license','','All')"
          >
            <div>Total Licenses</div>
            <div>{{totalLicenseStatus.total_licenses}}</div>
          </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count">
          <div>
            <img src="../../../assets/img/actice_license.svg" alt="" />
          </div>
          <div
            class="dash_user_count_div"
            (click)="moveTab('license','','Active')"
          >
            <div>Active Licenses</div>
            <div>{{totalLicenseStatus.active_licenses}}</div>
          </div>
        </div>
        <!-- <div class="dash_user_count_sub_div u3_license_count">
                    <div>
                        <img src="../../../assets/img/expired_license.svg" alt="">
                    </div>
                    <div class="dash_user_count_div" (click)="moveTab('license','','Expired')">
                        <div>Expired Licenses</div>
                        <div>{{totalLicenseStatus.expired_licenses}}</div>
                    </div>
                </div> -->
        <div class="dash_user_count_sub_div u3_license_count">
          <div>
            <img src="../../../assets/img/requested_license.svg" alt="" />
          </div>
          <div
            class="dash_user_count_div"
            (click)="moveTab('license','','Requested')"
          >
            <div>Requested Licenses</div>
            <div>{{totalLicenseStatus.requested_licenses}}</div>
          </div>
        </div>
      </div>
      <div
        class="dash_user_count_main_div"
        style="grid-template-columns: 1fr 1fr 1fr 1fr; margin-top: 1rem"
      >
        <div class="dash_user_count_sub_div u3_license_count">
          <div>
            <img src="../../../assets/img/expired_license.svg" alt="" />
          </div>
          <div class="dash_user_count_div" (click)="moveTab('license','','90')">
            <div>Expiring in 90 Days</div>
            <div>
              {{this.totalExpiringLicensesStatus.expiring_in_less_than_90_days}}
            </div>
          </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count">
          <div>
            <img src="../../../assets/img/expired_license.svg" alt="" />
          </div>
          <div class="dash_user_count_div" (click)="moveTab('license','','60')">
            <div>Expiring in 60 Days</div>
            <div>
              {{this.totalExpiringLicensesStatus.expiring_in_less_than_60_days}}
            </div>
          </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count">
          <div>
            <img src="../../../assets/img/expired_license.svg" alt="" />
          </div>
          <div class="dash_user_count_div" (click)="moveTab('license','','30')">
            <div>Expiring in 30 Days</div>
            <div>
              {{this.totalExpiringLicensesStatus.expiring_in_less_than_30_days}}
            </div>
          </div>
        </div>
        <div class="dash_user_count_sub_div u3_license_count">
          <div>
            <img src="../../../assets/img/expired_license.svg" alt="" />
          </div>
          <div
            class="dash_user_count_div"
            (click)="moveTab('license','','Expired')"
          >
            <div>Expired Licenses</div>
            <div>{{totalLicenseStatus.expired_licenses}}</div>
          </div>
        </div>
      </div>

      <div class="dash_pie_sub_div" style="padding-bottom: 5rem">
        <div class="dash_pie_header">Total Licenses</div>
        <div style="display: grid; grid-template-columns: 1fr 1fr">
          <div
            class="dash_pie_wraper_div"
            style="display: flex; justify-content: center"
          >
            <div>
              <apx-chart
                #chart
                [series]="totalLicenseData"
                [chart]="pieChats"
                [labels]="lableLicense"
                [dataLabels]="dataLabels"
                [legend]="pieLegend"
                [colors]="colorsLicense"
              ></apx-chart>
            </div>
            <div
              style="
                width: 184px;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div
                class="pie_chart_lengend_label pie_chart_lengend_label_total"
              >
                <div class="" style="padding-left: 0">Total Licenses</div>
                <div class="">{{totalLicenseStatus.total_licenses}}</div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div>
                  <div
                    class="active_legend_circle"
                    style="background-color: #7cc3b5"
                  ></div>
                  Active Licenses
                </div>
                <div>{{totalLicenseStatus.active_licenses}}</div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #e0473e"
                  ></div>
                  Expired Licenses
                </div>
                <div>{{totalLicenseStatus.expired_licenses}}</div>
              </div>
            </div>
          </div>
          <div
            class="dash_pie_wraper_div"
            style="display: flex; justify-content: center"
          >
            <div>
              <apx-chart
                #chart
                [series]="totalExpiringLicenseData"
                [chart]="pieChats"
                [labels]="lableExpiringLicense"
                [dataLabels]="dataLabels"
                [legend]="pieLegend"
                [colors]="colorsExpiringLicense"
              ></apx-chart>
            </div>
            <div
              style="
                width: 184px;
                display: flex;
                flex-direction: column;
                justify-content: center;
              "
            >
              <div
                class="pie_chart_lengend_label pie_chart_lengend_label_total"
              >
                <div class="" style="padding-left: 0">Expiring Licenses</div>
                <div class="">&nbsp;</div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div>
                  <div
                    class="active_legend_circle"
                    style="background-color: #e0473e"
                  ></div>
                  Expiring in less than 30 days
                </div>
                <div>
                  {{this.totalExpiringLicensesStatus.expiring_in_less_than_30_days}}
                </div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #f59e5f"
                  ></div>
                  Expiring in 60 days
                </div>
                <div>
                  {{this.totalExpiringLicensesStatus.expiring_in_less_than_60_days}}
                </div>
              </div>
              <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #f5dd5f"
                  ></div>
                  Expiring in 90 days
                </div>
                <div>
                  {{this.totalExpiringLicensesStatus.expiring_in_less_than_90_days}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: center; margin-top: 8rem">
          <div class="chart_y_axis_header">No. Licenses</div>
          <div class="dash_chart_main_div">
            <div><img src="../../../assets/img/line_verical.svg" alt="" /></div>
            <div id="chart_div" class="dash_chart_sub_div">
              <div id="chart_su_div">
                <apx-chart
                  #chart
                  [series]="licenseRequestData"
                  [chart]="chats"
                  [xaxis]="serviceXaxis"
                  [yaxis]="yaxis"
                  [dataLabels]="dataLabels"
                  [plotOptions]="plotOptions"
                  [legend]="legend"
                ></apx-chart>
              </div>

              <img
                src="../../../assets/img/line_horizontal.svg"
                alt=""
                class="horizontal_line"
              />
              <div class="chart_x_axis_header">Weeks</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dash_user_end_div">&nbsp;</div>
  <!-- ----------------------------------- Service Requests --------------------------- -->
  <div class="dash_sub_box_main_div" style="margin-top: 1rem">
    <div class="dash_header_main_div">
      <div
        class="box_main_heading"
        style="cursor: pointer"
        (click)="moveTab('service')"
      >
        Total Service Requests
      </div>
      <div class="dateFilter">
        <div class="dash_filter_main_div">
          <div>Month</div>
          <div>
            <select
              name="monthName"
              id=""
              [(ngModel)]="selectedServiceMonth"
              (change)="serviceChatMonthHander(selectedServiceMonth)"
            >
              <option value="1" selected>Jan</option>
              <option value="2">Feb</option>
              <option value="3">Mar</option>
              <option value="4">Apr</option>
              <option value="5">May</option>
              <option value="6">Jun</option>
              <option value="7">July</option>
              <option value="8">Aug</option>
              <option value="9">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </select>
          </div>
        </div>
        <div class="dash_filter_main_div">
          <div>Year</div>
          <div>
            <select
              name="monthName"
              id=""
              [(ngModel)]="currentYear"
              (change)="serviceChatMonthHander(selectedServiceMonth)"
            >
              <option *ngFor="let year of yearDropDown" [value]="year">
                {{year}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; justify-content: center">
      <div class="chart_y_axis_header">No. Services</div>
      <div class="dash_chart_main_div">
        <div><img src="../../../assets/img/line_verical.svg" alt="" /></div>
        <div id="chart_div" class="dash_chart_sub_div">
          <div id="chart_su_div">
            <apx-chart
              #chart
              [series]="serviceRequestData"
              [chart]="chats"
              [xaxis]="serviceXaxis"
              [dataLabels]="dataLabels"
              [plotOptions]="plotOptions"
              [legend]="legend"
            ></apx-chart>
          </div>

          <img
            src="../../../assets/img/line_horizontal.svg"
            alt=""
            class="horizontal_line"
          />
          <div class="chart_x_axis_header">Weeks</div>
        </div>
      </div>
    </div>
    <div
      class="box_main_heading"
      style="margin-top: 4rem; cursor: pointer"
      (click)="moveTab('service')"
    >
      Service Requests
    </div>
    <div>
      <div class="dash_pie_sub_div">
        <div
          class="dash_pie_wraper_div"
          style="display: flex; justify-content: center"
        >
          <div>
            <apx-chart
              #chart
              [series]="totalServiceRequestData"
              [chart]="pieChats"
              [labels]="lableService"
              [dataLabels]="dataLabels"
              [legend]="pieLegend"
              [colors]="colorsService"
            ></apx-chart>
          </div>
          <div
            style="
              width: 700px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              max-height: 200px;
              flex-flow: wrap;
              gap: 8rem;
            "
          >
            <div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_total"
              >
                <div class="" style="padding-left: 0; text-align: left">
                  Total Service Requests
                </div>
                <div class="">{{serviceRequest.total_service_requests}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="active_legend_circle"
                    style="background-color: #7cc3b5"
                  ></div>
                  Renew License
                </div>
                <div>{{serviceRequest.renew_license}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #9fc4fb"
                  ></div>
                  Modify License
                </div>
                <div>{{serviceRequest.modify_license}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #f66d44"
                  ></div>
                  File Annual Returns
                </div>
                <div>{{serviceRequest.file_annual_returns}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #feae65"
                  ></div>
                  Health check
                </div>
                <div>{{serviceRequest.health_check}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #e6f69d"
                  ></div>
                  Upload Test Reports
                </div>
                <div>{{serviceRequest.upload_test_reports}}</div>
              </div>
            </div>
            <div style="padding-top: 24px">
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #aadea7"
                  ></div>
                  Product Testing
                </div>
                <div>{{serviceRequest.product_testing}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #64c2a6"
                  ></div>
                  Water Testing
                </div>
                <div>{{serviceRequest.water_testing}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #2d87bb"
                  ></div>
                  Pest Control
                </div>
                <div>{{serviceRequest.pest_control}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #6aad41"
                  ></div>
                  Equipment Callibration
                </div>
                <div>{{serviceRequest.equ_callibration}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #6aad41"
                  ></div>
                  Training
                </div>
                <div>{{serviceRequest.training}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #6aad41"
                  ></div>
                  Internal Audit
                </div>
                <div>{{serviceRequest.audit}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #6aad41"
                  ></div>
                  Third-party Audits
                </div>
                <div>{{serviceRequest.third}}</div>
              </div>
              <div
                style="max-width: 287px; gap: 60px"
                class="pie_chart_lengend_label pie_chart_lengend_label_data"
              >
                <div>
                  <div
                    class="inactive_legend_circle"
                    style="background-color: #6aad41"
                  ></div>
                  New license
                </div>
                <div>{{serviceRequest.new}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
