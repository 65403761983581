import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';
import { NotificationsComponent } from '../common_dialog/notifications/notifications.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AES, enc } from 'crypto-js';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  constructor(
    private dataShare: DataService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dialog: MatDialog,
    private router: Router,
  ) {}
  passwordToggleHide: any = true;
  conpasswordToggleHide: any = true;
  newpasswordToggleHide: any = true;
  notifications: any = [];
  isNewNotifications: boolean = false;
  role: any;
  ngOnInit(): void {
    this.dataShare.allStatesOfIndia.forEach((ele: any, index: any) => {
      this.allState.push({ id: index + 1, name: ele });
    });
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      const roleId = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
      this.role = roleId;
      console.log(this.role, roleId);
    }
    this.getProfileDtls();
    setTimeout(async () => {
      this.photo = await localStorage.getItem('photo');
      this.username = await localStorage.getItem('username');
      console.log(this.photo);
    }, 0);
  }
  username: any = 'User';
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const inputElement = document.querySelector('ng-select input');
    if (inputElement) {
      inputElement.setAttribute('autocomplete', 'off');
    }
  }
  allState: any[] = [];
  selectedState: any = null;
  onMaterialGroupChange(event: any) {
    console.log(this.selectedState);
  }
  photo: any = '../../../assets/img/default_dp.png';
  tPhoto: any = '../../../assets/img/default_dp.png';
  navigate(route: string) {
    if (this.router.url == route) return;
    else this.router.navigate([route]);
    setTimeout(async () => {
      this.photo = await localStorage.getItem('photo');
      console.log(this.photo);
    }, 1);
  }
  showNotif: boolean = false;
  toggleNotif() {
    this.dialog
      .open(NotificationsComponent, {
        data: this.notifications,
        // position: {
        //   top: `5rem`,
        //   right: `25rem`,
        // },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getNotifications();
        }
      });
  }
  @Output() messageEmitter = new EventEmitter<any>();
  moveTab(tab: any, to: any = 'Enterprise') {
    this.messageEmitter.emit(tab);
    this.dataShare.prefilter = to;
  }
  readNotifications(element: any) {
    this.moveTab('service', element.type == 'eu' ? 'Enterprise' : 'Retail');
    this.dataShare.dialogRef(element.service_request_id, element.type);
    let data = {
      id: element.id,
      is_read: 1,
    };
    this.httpSv.readNotification(data).subscribe(
      (res: any) => {
        if (res['status']) {
          this.getNotifications();
        } else {
          this.toster.error(res['message'], '', { timeOut: 2000 });
        }
      },
      (err: any) => {
        this.toster.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  getNotifications() {
    this.httpSv.getNotifications(37).subscribe(
      (res: any) => {
        if (res['status']) {
          this.notifications = res['result'];
          const newNotf = this.notifications.filter(
            (data: any) => data.is_read === false,
          );
          console.log(this.notifications, newNotf);
          if (newNotf.length > 0) this.isNewNotifications = true;
          else this.isNewNotifications = false;
        } else {
          this.notifications = [];
          this.isNewNotifications = false;
        }
      },
      (err: any) => {
        this.toster.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  companyName: string = '';
  mobileNo: string = '';
  email: string = '';
  name: string = '';
  city: string = '';
  src: string = '../../../assets/img/default_dp.png';
  address: string = '';
  getProfileDtls() {
    this.httpSv.getProfileDtls().subscribe(
      (res: any) => {
        if (res['status']) {
          const temp = res['results'];
          this.dataShare.profileDtls = temp;
          this.companyName = temp.company_name;
          this.mobileNo = temp.mobile;
          this.email = temp.email;
          this.name = temp.name;
          this.src = temp.photo;
          this.city = temp.city;
          this.address = temp.address;
          const index = this.allState.findIndex(
            (ele: any) => ele.name == temp.state,
          );
          this.selectedState = temp.state;
          console.log(index);
        } else {
          this.toster.error('Opps! Some tecnical issue', '', { timeOut: 2000 });
        }
      },
      (err: any) => {
        this.toster.error('Opps! Some tecnical issue', '', { timeOut: 2000 });
      },
    );
  }
  updateProfileDtls() {
    const data = new FormData();
    if (this.companyName != '' && this.companyName) {
      if (this.name != '' && this.name) {
        if (this.selectedState) {
          if (this.address != '' && this.address) {
            data.append('company_name', this.companyName);
            data.append('name', this.name);
            // data.append('state', this.allState[this.selectedState - 1].name)
            data.append('state', this.selectedState);
            data.append('address', this.address);
            data.append('city', this.city);
            if (this.fileData != '' && this.fileData)
              data.append('photo', this.fileData);
            console.log(this.fileData);
            this.httpSv.updateProfileDtls(data).subscribe((res: any) => {
              if (res['status']) {
                this.toster.success(
                  'Profile details have been successfully updated.',
                  '',
                  { timeOut: 2000 },
                );
                this.ngOnInit();
              } else {
                this.toster.error('Oops! Something went wrong', '', {
                  timeOut: 2000,
                });
              }
            });
          } else {
            this.toster.error('Please enter the address', '', {
              timeOut: 2000,
            });
          }
        } else {
        }
      } else {
        this.toster.error('Please enter your name', '', { timeOut: 2000 });
      }
    } else {
      this.toster.error('Please enter company name', '', { timeOut: 2000 });
    }
  }

  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  fileData: any = '';
  onImageChange(event: any) {
    let file: File = event.target.files[0];
    this.fileData = file;
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      this.src = e.target.result;
    };
    const fileDom = document.getElementById('file_inp') as HTMLInputElement;
    if (fileDom) {
      fileDom.value = '';
    }
  }
  updatePassword() {
    if (this.oldPassword != '' && this.oldPassword) {
      if (this.newPassword != '' && this.newPassword) {
        if (this.confirmPassword != '' && this.confirmPassword) {
          if (this.confirmPassword == this.newPassword) {
            const data = new FormData();
            data.append('old_password', this.oldPassword);
            data.append('new_password', this.newPassword);
            data.append('confirm_password', this.confirmPassword);

            this.httpSv.updatePassword(data).subscribe(
              (res: any) => {
                if (res['status']) {
                  this.toster.success('Password updated sucessfully', '', {
                    timeOut: 2000,
                  });
                  this.ngOnInit();
                } else {
                  this.toster.error('Oops! something went wrong', '', {
                    timeOut: 2000,
                  });
                }
              },
              (err: any) => {
                this.toster.error(err.error.message, '', { timeOut: 2000 });
              },
            );
          } else {
            this.toster.error("Password does't match", '', { timeOut: 2000 });
          }
        } else {
          this.toster.error('Please enter cofirm password', '', {
            timeOut: 2000,
          });
        }
      } else {
        this.toster.error('Please enter new password', '', { timeOut: 2000 });
      }
    } else {
      this.toster.error('Please enter old password', '', { timeOut: 2000 });
    }
  }
  async removeImage() {
    this.src = '../../../assets/img/default_dp.png';
    const response = await fetch(this.src);
    const blob = await response.blob();
    this.fileData = new File([blob], 'profile.png', { type: blob.type });
  }
}
