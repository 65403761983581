<div class="side_bar_common_css">
  <app-sidenav-bar></app-sidenav-bar>
  <div class="license_main_div">
    <div class="notificationBar">
      <img
        *ngIf="roleId == 1"
        src="../../../assets/img/setting.png"
        (click)="navigate('/settings')"
        style="width: 35px; border-radius: 25px; cursor: pointer"
        alt=""
        srcset=""
        class="side_nav_bottom_dp"
      />
      <img
        *ngIf="!isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/NotifiationBell.svg"
      />
      <img
        *ngIf="isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/notificationIcon.svg"
      />
      <div class="shwoNotififications" *ngIf="showNotif">
        <div class="d-flex justify-content-end">
          <img
            style="width: 20px"
            (click)="toggleNotif()"
            src="../../../assets/img/carbon_close-filled.svg"
          />
        </div>
        <div *ngIf="notifications.length == 0">
          <div class="notificationHeader">No Notifications Found</div>
        </div>
        <div
          *ngFor="let notif of notifications"
          class="notif"
          (click)="readNotifications(notif)"
        >
          <div class="notificationHeader">
            {{ notif.type.toUpperCase().split("_").join(" ") }}
          </div>
          <div class="notificationContent">
            {{ notif.remarks }}
          </div>
        </div>
      </div>
      <button mat-button [matMenuTriggerFor]="menu" style="padding: 0">
        <img
          [src]="photo"
          style="width: 35px; border-radius: 25px"
          alt=""
          srcset=""
          class="side_nav_bottom_dp"
        />
      </button>
      <mat-menu #menu="matMenu">
        <div
          style="
            color: black;
            padding: 10px;
            font-weight: 700;
            border-bottom: 1px solid #a3c17e;
            width: 100%;
            max-width: 10rem;
            margin-right: 1rem;
          "
        >
          {{ username.toUpperCase() }}
        </div>
        <button mat-menu-item (click)="navigate('/profile')">
          <img style="width: 25px; margin-right: 10px" [src]="tPhoto" alt="" />
          Profile
        </button>
        <button mat-menu-item (click)="navigate('/login')">
          <img
            style="width: 25px; margin-right: 10px"
            src="../../../assets/img/logout.svg"
            alt=""
          />
          Logout
        </button>
      </mat-menu>
    </div>
    <div class="header">REPORTS</div>
    <div class="tabs">
      <div class="leftTab">
        <div
          class="selectorTab"
          (click)="selectorTab('sr')"
          [ngClass]="selectorTabData == 'sr' ? 'borderBottom' : ''"
        >
          SERVICE REQUEST
        </div>
        <div
          class="selectorTab"
          (click)="selectorTab('license')"
          [ngClass]="selectorTabData == 'license' ? 'borderBottom' : ''"
        >
          LICENSE
        </div>
        <div
          class="selectorTab"
          (click)="selectorTab('user')"
          [ngClass]="selectorTabData == 'user' ? 'borderBottom' : ''"
          *ngIf="roleId != 5"
        >
          USER
        </div>
      </div>
      <div class="rightTab">
        <div class="dropdown">
          <button
            class="btn"
            style="padding: 7px 15px"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Show : <span>{{ filterr.toUpperCase() }}</span>
            <img src="../../../assets/img/left.svg" />
          </button>
          <ul class="dropdown-menu">
            <li
              class="dropdown-item"
              style="color: #5f6370"
              [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
              (click)="filter('all', 'user')"
            >
              All
            </li>
            <li
              class="dropdown-item"
              style="color: #12c058"
              [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
              (click)="filter('enterprise', 'user')"
            >
              Enterprise
            </li>
            <li
              class="dropdown-item"
              style="color: #d43738"
              [ngClass]="selectedFilter.expired ? 'filterActiveCss' : ''"
              (click)="filter('retail', 'user')"
              *ngIf="roleId != 5"
            >
              Retail
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="license_content_main_div">
      <div
        style="overflow: auto; min-height: 12rem"
        *ngIf="selectorTabData == 'user'"
      >
        <div class="userCounts">
          <div class="userRetail" *ngIf="roleId == '1' || roleId == '2'">
            <div class="header" style="margin-bottom: 0">Retail User</div>
            <div class="labels">
              <label class="list">Number of Users</label>
              <label class="data">{{
                userDataCount.retail_user.ru_users
                  ? userDataCount.retail_user.ru_users
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Total Storage Used</label>
              <label class="data">{{
                userDataCount.retail_user.ru_users_storage
                  ? userDataCount.retail_user.ru_users_storage
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Total Document Collected</label>
              <label class="data">{{
                userDataCount.retail_user.ru_total_doc
                  ? userDataCount.retail_user.ru_total_doc
                  : 0
              }}</label>
            </div>
          </div>
          <div
            class="userEnterprise"
            [ngClass]="roleId == '3' || roleId == '5' ? 'fullWidth' : ''"
          >
            <div class="header" style="margin-bottom: 0">Enterprise User</div>
            <div class="labels">
              <label class="list">Number of Users</label>
              <label class="data">{{
                userDataCount.enterprise_user.eu_users
                  ? userDataCount.enterprise_user.eu_users
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Total Storage Used</label>
              <label class="data">{{
                userDataCount.enterprise_user.eu_users_storage
                  ? userDataCount.enterprise_user.eu_users_storage
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Total Document Collected</label>
              <label class="data">{{
                userDataCount.enterprise_user.eu_users
                  ? userDataCount.enterprise_user.eu_users
                  : 0
              }}</label>
            </div>
          </div>
        </div>
        <div class="datePicker">
          <div class="d-flex">
            <mat-form-field style="margin: 0rem 1rem">
              <mat-label>Start Date</mat-label>
              <input
                matInput
                (dateChange)="setDate('start', $event)"
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Date</mat-label>
              <input
                matInput
                (dateChange)="setDate('end', $event)"
                [matDatepicker]="picker1"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="dropdown">
            <button
              class="btn export"
              style="padding: 7px 15px"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Export <img src="../../../assets/img/Vector.svg" />
            </button>
            <ul class="dropdown-menu">
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                (click)="export('pdf')"
              >
                Export as PDF
              </li>
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
                (click)="export('csv')"
              >
                Export as CSV
              </li>
            </ul>
          </div>
        </div>
        <div class="license_table_main_div" id="contentToConvert">
          <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
            matPaginator
            matSort
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{ i + 1 }}
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                {{ selectorTabData == "user" ? "User Name" : "Client Name" }}
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="user_type">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    width: 12rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>User Type</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userNameFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userNameFilter ? userNameFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userNameFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'name', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userNameDropdown"
                        (click)="userApplyFilter(status, 'name', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{
                  element.user_type == "retail_user" ? "Retail" : "Enterprise"
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="city">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>City</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userCityFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userCityFilter ? userCityFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userCityFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'city', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userCityDropdown"
                        (click)="userApplyFilter(status, 'city', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.city ? element.city : element.address }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>State</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userStateFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userStateFilter ? userStateFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userStateFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'state', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userStateDropdown"
                        (click)="userApplyFilter(status, 'state', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.state }}
              </td>
            </ng-container>

            <ng-container matColumnDef="no_licenses">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <p>
                  Number of <br />
                  Licenses
                </p>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.no_of_licenses }}
              </td>
            </ng-container>

            <ng-container matColumnDef="am">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                    width: 11rem;
                  "
                >
                  <p>Account Manager</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userAmFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userAmFilter ? userAmFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userAmFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'am', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userAmDropdown"
                        (click)="userApplyFilter(status, 'am', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.account_manager ? element.account_manager : "NA" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="no_docs">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <p>
                  Number of <br />
                  Documents
                </p>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span
                  [ngClass]="
                    element.certificate_validity == 'Active'
                      ? 'colorGreen'
                      : element.certificate_validity == 'Inactive'
                        ? 'colorRed'
                        : 'colorBlue'
                  "
                >
                  {{ element.no_of_documents }}</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="user_since">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <p>User Since</p>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span
                  [ngClass]="
                    element.date_joined == 'Active'
                      ? 'colorGreen'
                      : element.certificate_validity == 'Inactive'
                        ? 'colorRed'
                        : 'colorBlue'
                  "
                >
                  {{ element.date_joined }}</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="storage">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Storage
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.storage_used }}
              </td>
            </ng-container>

            <ng-container matColumnDef="paid">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Paid
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.paid_status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Status</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userStatusFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userStatusFilter ? userStatusFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userStatusFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'status', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userStatusDropdown"
                        (click)="userApplyFilter(status, 'status', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator
            #paginator
            class="demo-paginator"
            [length]="lengthUser"
            (page)="handlePageEvent($event, 'user')"
            [pageSize]="pageSizeUser"
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
            [hidePageSize]="hidePageSize"
            [pageIndex]="pageIndexUser"
            aria-label="Select page"
          >
          </mat-paginator>
        </div>
      </div>

      <div
        style="overflow: auto; min-height: 12rem"
        *ngIf="selectorTabData == 'license'"
      >
        <div class="userCounts">
          <div class="userRetail" *ngIf="roleId == '1' || roleId == '2'">
            <div class="header" style="margin-bottom: 0">Retail User</div>
            <div class="labels">
              <label class="list"
                >Number of New License Requests Received</label
              >
              <label class="data">{{
                licenseDataCount.retail_user.ru_new_license_requested
                  ? licenseDataCount.retail_user.ru_new_license_requested
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Number of Requests Resolved</label>
              <label class="data">{{
                licenseDataCount.retail_user.ru_solved_license_requested
                  ? licenseDataCount.retail_user.ru_solved_license_requested
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list"
                >Average Number of Days for resolved requests</label
              >
              <label class="data">{{
                licenseDataCount.retail_user.ru_avg_days_took
                  ? licenseDataCount.retail_user.ru_avg_days_took
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Number of Licences Uploaded</label>
              <label class="data">{{
                licenseDataCount.retail_user.ru_upload_license_requested
                  ? licenseDataCount.retail_user.ru_upload_license_requested
                  : 0
              }}</label>
            </div>
          </div>
          <div
            class="userEnterprise"
            [ngClass]="roleId == '3' || roleId == '5' ? 'fullWidth' : ''"
          >
            <div class="header" style="margin-bottom: 0">Enterprise User</div>
            <div class="labels">
              <label class="list"
                >Number of New License Requests Received</label
              >
              <label class="data">{{
                licenseDataCount.enterprise_user.eu_new_license_requested
                  ? licenseDataCount.enterprise_user.eu_new_license_requested
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Number of Requests Resolved</label>
              <label class="data">{{
                licenseDataCount.enterprise_user.eu_solved_license_requested
                  ? licenseDataCount.enterprise_user.eu_solved_license_requested
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list"
                >Average Number of Days for resolved requests</label
              >
              <label class="data">{{
                licenseDataCount.enterprise_user.eu_avg_days_took
                  ? licenseDataCount.enterprise_user.eu_avg_days_took
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Number of Licences Uploaded</label>
              <label class="data">{{
                licenseDataCount.enterprise_user.eu_upload_license_requested
                  ? licenseDataCount.enterprise_user.eu_upload_license_requested
                  : 0
              }}</label>
            </div>
          </div>
        </div>
        <div class="datePicker">
          <div class="d-flex">
            <mat-form-field style="margin: 0rem 1rem">
              <mat-label>Start Date</mat-label>
              <input
                matInput
                (dateChange)="setDate('start', $event)"
                [matDatepicker]="picke4"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picke4"
              ></mat-datepicker-toggle>
              <mat-datepicker #picke4></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Date</mat-label>
              <input
                matInput
                (dateChange)="setDate('end', $event)"
                [matDatepicker]="picker3"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker3"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker3></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="dropdown">
            <button
              class="btn export"
              style="padding: 7px 15px"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Export <img src="../../../assets/img/Vector.svg" />
            </button>
            <ul class="dropdown-menu">
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                (click)="export('pdf')"
              >
                Export as PDF
              </li>
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
                (click)="export('csv')"
              >
                Export as CSV
              </li>
            </ul>
          </div>
        </div>
        <div class="license_table_main_div" id="contentToConvert">
          <table
            mat-table
            [dataSource]="dataSourceLicense"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
            matSort
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{ i + 1 }}
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Client Name</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseUserFiler"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseUserFiler ? licenseUserFiler : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseUserFiler"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'name', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseUserDropdown"
                        (click)="licenseApplyFilter(status, 'name', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.client_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="license">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                License
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.registration_no }}
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Type of License</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseTypeFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseTypeFilter ? licenseTypeFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseTypeFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'type', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseTypeDropdown"
                        (click)="licenseApplyFilter(status, 'type', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.license_type }}
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Date</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseDateFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseDateFilter ? licenseDateFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseDateFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'date', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseDateDropdown"
                        (click)="licenseApplyFilter(status, 'date', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.created_at }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Status</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseStatusFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseStatusFilter ? licenseStatusFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseStatusFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'status', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseStatusDropdown"
                        (click)="licenseApplyFilter(status, 'status', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="am">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                    width: 10rem;
                  "
                >
                  <p>Account Manager</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseAmFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseAmFilter ? licenseAmFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseAmFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'am', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseAmDropdown"
                        (click)="licenseApplyFilter(status, 'am', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.account_manager ? element.account_manager : "NA" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>City</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseCityFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseCityFilter ? licenseCityFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseCityFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'city', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseCityDropdown"
                        (click)="licenseApplyFilter(status, 'city', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.city ? element.city : element.address }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>State</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseStateFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseStateFilter ? licenseStateFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseStateFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'state', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseStateDropdown"
                        (click)="licenseApplyFilter(status, 'state', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.state }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsLicense"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsLicense"
            ></tr>
          </table>
          <mat-paginator
            #paginator
            class="demo-paginator"
            (page)="handlePageEvent($event, 'license')"
            [length]="lengthLicense"
            [pageSize]="pageSizeLicense"
            [disabled]="disabled"
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
            [hidePageSize]="hidePageSize"
            [pageIndex]="pageIndexLicense"
            aria-label="Select page"
          >
          </mat-paginator>
        </div>
      </div>

      <div
        style="overflow: auto; min-height: 12rem"
        *ngIf="selectorTabData == 'sr'"
      >
        <div class="userCounts">
          <div class="userRetail" *ngIf="roleId == '1' || roleId == '2'">
            <div class="header" style="margin-bottom: 0">Retail User</div>
            <div class="labels">
              <label class="list">Number of Requests Received</label>
              <label class="data">{{
                serviceDataCount.retail_user.ru_service_requests
                  ? serviceDataCount.retail_user.ru_service_requests
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Number of Requests Resolved</label>
              <label class="data">{{
                serviceDataCount.retail_user.ru_completed_service_requests
                  ? serviceDataCount.retail_user.ru_completed_service_requests
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list"
                >Average Number of Days for resolved requests</label
              >
              <label class="data">{{
                serviceDataCount.retail_user.ru_days_took
                  ? serviceDataCount.retail_user.ru_days_took
                  : 0
              }}</label>
            </div>
          </div>
          <div
            class="userEnterprise"
            [ngClass]="roleId == '3' || roleId == '5' ? 'fullWidth' : ''"
          >
            <div class="header" style="margin-bottom: 0">Enterprise User</div>
            <div class="labels">
              <label class="list">Number of Requests Received</label>
              <label class="data">{{
                serviceDataCount.enterprise_user.eu_service_requests
                  ? serviceDataCount.enterprise_user.eu_service_requests
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list">Number of Requests Resolved</label>
              <label class="data">{{
                serviceDataCount.enterprise_user.eu_completed_service_requests
                  ? serviceDataCount.enterprise_user
                      .eu_completed_service_requests
                  : 0
              }}</label>
            </div>
            <div class="labels">
              <label class="list"
                >Average Number of Days for resolved requests</label
              >
              <label class="data">{{
                serviceDataCount.enterprise_user.eu_days_took
                  ? serviceDataCount.enterprise_user.eu_days_took
                  : 0
              }}</label>
            </div>
          </div>
        </div>
        <div class="datePicker">
          <div class="d-flex">
            <mat-form-field style="margin: 0rem 1rem">
              <mat-label>Start Date</mat-label>
              <input
                matInput
                (dateChange)="setDate('start', $event)"
                [matDatepicker]="picker5"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker5"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker5></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Date</mat-label>
              <input
                matInput
                (dateChange)="setDate('end', $event)"
                [matDatepicker]="picker6"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker6"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker6></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="dropdown">
            <button
              class="btn export"
              style="padding: 7px 15px"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Export <img src="../../../assets/img/Vector.svg" />
            </button>
            <ul class="dropdown-menu">
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                (click)="export('pdf')"
              >
                Export as PDF
              </li>
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
                (click)="export('csv')"
              >
                Export as CSV
              </li>
            </ul>
          </div>
        </div>
        <div class="license_table_main_div" id="contentToConvert">
          <table
            mat-table
            [dataSource]="dataSourceService"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
            matSort
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{ i + 1 }}
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th class="" mat-header-cell *matHeaderCellDef>Id</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{ element.id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Client Name</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceUserFiler"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceUserFiler ? serviceUserFiler : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceUserFiler"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'name', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceUserDropdown"
                        (click)="ServiceApplyFilter(status, 'name', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.username }}
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                    width: 10rem;
                  "
                >
                  <p>Type of Request</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceTypeFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceTypeFilter ? serviceTypeFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceTypeFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'type', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceTypeDropdown"
                        (click)="ServiceApplyFilter(status, 'type', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.services }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Current Status</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceStatusFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceStatusFilter ? serviceStatusFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceStatusFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'status', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceStatusDropdown"
                        (click)="ServiceApplyFilter(status, 'status', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.date }}
              </td>
            </ng-container>

            <ng-container matColumnDef="time">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Days Lapsed
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.days_lapsed }}
              </td>
            </ng-container>

            <ng-container matColumnDef="am">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                    width: 10rem;
                  "
                >
                  <p>Account Manager</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceAmFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceAmFilter ? serviceAmFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceAmFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'am', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceAmDropdown"
                        (click)="ServiceApplyFilter(status, 'am', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.account_manager ? element.account_manager : "NA" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>City</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceCityFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceCityFilter ? serviceCityFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceCityFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'city', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceCityDropdown"
                        (click)="ServiceApplyFilter(status, 'city', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.city ? element.city : element.address }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>State</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceStateFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceStateFilter ? serviceStateFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceStateFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'state', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceStateDropdown"
                        (click)="ServiceApplyFilter(status, 'state', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.state }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsService"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsService"
            ></tr>
          </table>
          <mat-paginator
            #paginator
            class="demo-paginator"
            (page)="handlePageEvent($event, 'sr')"
            [length]="lengthSr"
            [pageSize]="pageSizeSr"
            [disabled]="disabled"
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
            [hidePageSize]="hidePageSize"
            [pageIndex]="pageIndexSr"
            aria-label="Select page"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
