<div class="app_modify_main_div">
  <div class="app_modify_header">Update Business End User Details</div>
  <div class="app_modify_content_main_div">
    <div class="app_modify_sub_header">PERSONAL DETAILS</div>
    <div class="app_modify_inp_main_div">
      <!-- ------------------------------------------ PERSONAL DETAILS --------------------------------------------------- -->

      <div class="app_modify_pd_main_div">
        <div class="app_modify_pd_inp_main_div">
          <div class="app_modify_pd_inp_sub_div">
            <label for="name">Business End User Name</label>
            <input type="text" />
          </div>
          <div class="app_modify_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" />
          </div>
          <div class="app_modify_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" />
          </div>
          <div class="app_modify_pd_inp_sub_div">
            <label for="comp_name">Company Name</label>
            <input type="text" />
          </div>
          <div class="app_modify_pd_inp_sub_div">
            <label for="address">Address</label>
            <input type="text" />
          </div>
          <div class="app_modify_pd_inp_sub_div">
            <label for="pin_code">PIN Code</label>
            <input type="number" />
          </div>
          <div class="app_modify_pd_inp_sub_div">
            <label for="state">State</label>
            <input type="text" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="flex: 1">&nbsp;</div>
  <div class="app_modify_btn_main_div">
    <div class="app_modify_pd_btn_div">
      <div (click)="close()">close</div>
      <div>UPDATE</div>
    </div>
  </div>
</div>
