import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';
import { DialogDeleteCofirm } from '../common_dialog/delete-confirm/delete_confirm_dialog';
import { AES, enc } from 'crypto-js';
import { DialogDeleteDonePopup } from '../common_dialog/delete-sucess-info/delete_done_dialog';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import {
  DialogAppLicenseModify,
  DialogAppLicenseView,
} from '../licenses/licenses.component';
import { NotificationsComponent } from '../common_dialog/notifications/notifications.component';
import { Router } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged, map } from 'rxjs';

const ELEMENT_DATA: any[] = [
  {
    position: 1,
    name: 'Hydrogen',
    weight: 1.0079,
    symbol: 'H',
    status: 'Submitted',
  },
  {
    position: 2,
    name: 'Helium',
    weight: 4.0026,
    symbol: 'He',
    status: 'Active',
  },
  {
    position: 3,
    name: 'Lithium',
    weight: 6.941,
    symbol: 'Li',
    status: 'Active',
  },
  {
    position: 4,
    name: 'Beryllium',
    weight: 9.0122,
    symbol: 'Be',
    status: 'Active',
  },
  {
    position: 5,
    name: 'Boron',
    weight: 10.811,
    symbol: 'B',
    status: 'Active',
  },
  {
    position: 6,
    name: 'Carbon',
    weight: 12.0107,
    symbol: 'C',
    status: 'Expired',
  },
  {
    position: 7,
    name: 'Nitrogen',
    weight: 14.0067,
    symbol: 'N',
    status: 'Expired',
  },
  {
    position: 8,
    name: 'Oxygen',
    weight: 15.9994,
    symbol: 'O',
    status: 'Active',
  },
  {
    position: 9,
    name: 'Fluorine',
    weight: 18.9984,
    symbol: 'F',
    status: 'Expired',
  },
  {
    position: 10,
    name: 'Neon',
    weight: 20.1797,
    symbol: 'Ne',
    status: 'Active',
  },
  {
    position: 1,
    name: 'Hydrogen',
    weight: 1.0079,
    symbol: 'H',
    status: 'Expired',
  },
  {
    position: 2,
    name: 'Helium',
    weight: 4.0026,
    symbol: 'He',
    status: 'Active',
  },
  {
    position: 3,
    name: 'Lithium',
    weight: 6.941,
    symbol: 'Li',
    status: 'Active',
  },
  {
    position: 4,
    name: 'Beryllium',
    weight: 9.0122,
    symbol: 'Be',
    status: 'Expired',
  },
  {
    position: 5,
    name: 'Boron',
    weight: 10.811,
    symbol: 'B',
    status: 'Active',
  },
  {
    position: 6,
    name: 'Carbon',
    weight: 12.0107,
    symbol: 'C',
    status: 'Expired',
  },
];
const emailRegex = new RegExp(
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  'gm',
);

@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.css'],
})
export class ServiceRequestComponent implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    public dialog: MatDialog,
    private httpSv: HttpService,
    private dataService: DataService,
    private toster: ToastrService,
    private datePipe: DatePipe,
    private router: Router,
  ) {}
  notifications: any = [];
  isNewNotifications: boolean = false;
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      this.roleId = AES.decrypt(
        roleIdEncoded,
        this.dataService.secretKey,
      ).toString(enc.Utf8);
      this.dataService.roleId = this.roleId;
    } else {
      // this.toster.error('Opps! something went wrong', '', { timeOut: 2000 })
    }
    // console.log('aaaaaa', this.roleId);
    if (this.roleId == 5) this.getServiceReq();
    this.getNotifications();

    setTimeout(async () => {
      this.photo = await localStorage.getItem('photo');
      this.username = await localStorage.getItem('username');

      console.log(this.photo);
    }, 0);
  }
  username: any = 'User';
  photo: any = '../../../assets/img/default_dp.png';
  tPhoto: any = '../../../assets/img/default_dp.png';
  navigate(route: string) {
    if (this.router.url == route) return;
    else this.router.navigate([route]);
  }
  getNotifications() {
    this.httpSv.getNotifications(37).subscribe(
      (res: any) => {
        if (res['status']) {
          this.notifications = res['result'];
          const newNotf = this.notifications.filter(
            (data: any) => data.is_read === false,
          );
          console.log(this.notifications, newNotf);
          if (newNotf.length > 0) this.isNewNotifications = true;
          else this.isNewNotifications = false;
        } else {
          this.notifications = [];
          this.isNewNotifications = false;
        }
      },
      (err: any) => {
        this.toster.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  @Output() messageEmitter = new EventEmitter<any>();
  moveTab(tab: any, to: any = 'Enterprise') {
    this.messageEmitter.emit(tab);
    this.dataService.prefilter = to;
  }
  readNotifications(element: any) {
    this.moveTab('service', element.type == 'eu' ? 'Enterprise' : 'Retail');
    this.dataService.dialogRef(element.service_request_id, element.type);
    let data = {
      id: element.id,
      is_read: 1,
    };
    this.httpSv.readNotification(data).subscribe(
      (res: any) => {
        if (res['status']) {
          this.getNotifications();
        } else {
          this.toster.error(res['message'], '', { timeOut: 2000 });
        }
      },
      (err: any) => {
        this.toster.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  showNotif: boolean = false;
  toggleNotif() {
    this.dialog
      .open(NotificationsComponent, {
        data: this.notifications,
        // position: {
        //   top: `5rem`,
        //   right: `25rem`,
        // },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getNotifications();
        }
      });
  }
  displayedColumns: string[] = [
    'sl_no',
    'services',
    'fssai_regd_no',
    'business_name',
    'am',
    'state',
    'date',
    'status',
    // 'modify',
  ];
  data: any[] = [];
  apiData: any;
  roleId: any;
  dataSource: any = new MatTableDataSource();
  cesActiveFlag: boolean = true;
  selectedFilter: any = {
    all: true,
    Submitted: false,
    Approved: false,
    Accepted: false,
    Pending_Payment: false,
    In_Progress: false,
    Pending_Documentation: false,
    Completed: false,
    Rejected: false,
    Cancelled: false,
  };
  filterData: any;
  EUEmailDropdown: any[] = [];
  EUNameDropdown: any[] = [];
  EUCityDropdown: any[] = [];
  EULicenseDropdown: any[] = [];
  EUStatusDropdown: any[] = [];
  EUAMDropdown: any[] = [];
  EUCompanyDropdown: any[] = [];
  EUAddressDropdown: any[] = [];
  EUamDropdown: any[] = [];
  EUDateDropdown: any[] = [];
  EUNameFilter: any = '';
  EUamFilter: any = '';
  EUAddressFilter: any = '';
  EULicenseFilter: any = '';
  EUDateFilter: any = '';
  EUAMFilter: any = '';
  EUCityFilter: any = '';
  EUEmailFilter: any = '';
  EUStatusFilter: any = '';
  EUCompanyFilter: any = '';
  RUEmailDropdown: any[] = [];
  RUNameDropdown: any[] = [];
  RUCityDropdown: any[] = [];
  RULicenseDropdown: any[] = [];
  RUStatusDropdown: any[] = [];
  RUAMDropdown: any[] = [];
  RUCompanyDropdown: any[] = [];
  RUAddressDropdown: any[] = [];
  RUDateDropdown: any[] = [];
  RUNameFilter: any = '';
  RUAddressFilter: any = '';
  RULicenseFilter: any = '';
  RUDateFilter: any = '';
  RUAMFilter: any = '';
  RUCityFilter: any = '';
  RUEmailFilter: any = '';
  RUStatusFilter: any = '';
  RUCompanyFilter: any = '';
  selectedFilterDropdown: any = 'All';

  filterByStatus(order: any) {
    switch (order) {
      case 'All':
        this.selectedFilter = {
          all: true,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Submitted':
        this.selectedFilter = {
          all: false,
          Submitted: true,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Approved':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: true,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Accepted':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: true,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Pending_Payment':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: true,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'In_Progress':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: true,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Pending_Documentation':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: true,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Completed':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: true,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Rejected':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: true,
          Cancelled: false,
        };
        break;
      case 'Cancelled':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: true,
        };
        break;
    }
    this.filterByStatusDataHandel(order);
  }
  handelDeleteUser(id: any) {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Enterprise user?',
      },
    });
  }
  filterByStatusDataHandel(order: any) {
    console.log(order, this.apiData);
    if (order == 'All') this.dataSource.data = this.apiData;
    else {
      const tempData = this.apiData.filter((ele: any) => ele.status == order);
      this.dataSource.data = tempData;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  handelDeleteRetail(id: any) {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Service Request?',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.httpSv.deleteServiceRequest(id).subscribe((res: any) => {
          if (res['status']) {
            const dialogRef = this.dialog.open(DialogDeleteDonePopup, {
              hasBackdrop: true,
              disableClose: true,
              data: {
                heading: 'Service Request Deleted',
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.ngOnInit();
            });
          }
        });
      }
    });
  }
  modifyAppUser(id: any) {
    const dialogRef = this.dialog.open(DialogServiceRequestModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        id,
        type: 'eu',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getServiceReq();
      }
    });
  }
  getServiceReq() {
    this.httpSv.getAllServicesRequest(this.roleId)?.subscribe(
      (res: any) => {
        if (res['status']) {
          res['results'].map((data: any) => {
            if (data.date.indexOf('-') != -1) {
              data.date =
                this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
            }
          });
          this.apiData = res['results'];
          this.dataSource.data = this.apiData;
          this.dataSource.sort = this.sort;
          this.filterData = res['results'];
          this.apiData = res['results'];
          this.createEUDropDown(res['results']);
        } else {
          this.toster.error('Oops! Something went wrong', '', {
            timeOut: 2000,
          });
        }
      },
      (err: any) => {
        if (err.error.message == 'no data found') this.dataSource.data = [];
        if (err.error.message != 'no data found')
          this.toster.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  createEUDropDown(tableData: any) {
    tableData.forEach((data: any) => {
      if (
        data.services &&
        !this.EUNameDropdown.includes(data.services.toLowerCase())
      )
        this.EUNameDropdown.push(data.services.toLowerCase());
      if (data.state && !this.EUAddressDropdown.includes(data.state))
        this.EUAddressDropdown.push(data.state);
      if (data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status);
      if (
        data.fssai_regd_no &&
        !this.EULicenseDropdown.includes(data.fssai_regd_no)
      )
        this.EULicenseDropdown.push(data.fssai_regd_no);
      if (
        data.business_name &&
        !this.EUCompanyDropdown.includes(data.business_name)
      )
        this.EUCompanyDropdown.push(data.business_name);
      if (data.date && !this.EUDateDropdown.includes(data.date))
        this.EUDateDropdown.push(data.date);
      if (
        data.account_manager &&
        !this.EUamDropdown.includes(data.account_manager)
      )
        this.EUamDropdown.push(data.account_manager);
    });
  }
  EuApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.EUNameFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.EUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.services &&
            apiData.services.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'address') {
      this.EUAddressFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.state &&
            apiData.state.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'license') {
      this.EULicenseFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.fssai_regd_no &&
            apiData.fssai_regd_no.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'status') {
      this.EUStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'company') {
      this.EUCompanyFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.business_name &&
            apiData.business_name.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'date') {
      this.EUDateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.date && apiData.date == data) result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'am') {
      this.EUamFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.date &&
              data.date.toLowerCase() == this.EUDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.account_manager && apiData.account_manager == data)
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
  }
  openLicense(licensesNo: any, type: any) {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        licensesNo,
        type,
        sr: true,
      },
    });
  }
}

// --------------------------------- BUSSINESS MANAGER -------------------------------------

@Component({
  selector: 'app-u2-service-request',
  templateUrl: './u2_service_request.html',
  styleUrls: ['./service-request.component.css'],
})
export class ServiceRequestU2Component implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    public dialog: MatDialog,
    private httpSv: HttpService,
    private dataService: DataService,
    private toster: ToastrService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      this.roleId = AES.decrypt(
        roleIdEncoded,
        this.dataService.secretKey,
      ).toString(enc.Utf8);
      this.dataService.roleId = this.roleId;
      this.getServiceReq();
    } else {
      this.toster.error('Opps! something went wrong', '', { timeOut: 2000 });
    }
  }
  displayedColumns: string[] = [
    'sl_no',
    'services',
    'fssai_regd_no',
    'business_name',
    'am',
    'state',
    'date',
    'status',
    // 'modify',
  ];
  data: any[] = [];
  roleId: any;
  dataSource: any = new MatTableDataSource();
  cesActiveFlag: boolean = true;
  ApiData: any;
  selectedFilter: any = {
    all: true,
    Submitted: false,
    Approved: false,
    Accepted: false,
    Pending_Payment: false,
    In_Progress: false,
    Pending_Documentation: false,
    Completed: false,
    Rejected: false,
    Cancelled: false,
  };
  filterData: any;
  EUEmailDropdown: any[] = [];
  EUNameDropdown: any[] = [];
  EUCityDropdown: any[] = [];
  EUamDropdown: any[] = [];
  EULicenseDropdown: any[] = [];
  EUStatusDropdown: any[] = [];
  EUAMDropdown: any[] = [];
  EUCompanyDropdown: any[] = [];
  EUAddressDropdown: any[] = [];
  EUDateDropdown: any[] = [];
  EUNameFilter: any = '';
  EUamFilter: any = '';
  EUAddressFilter: any = '';
  EULicenseFilter: any = '';
  EUDateFilter: any = '';
  EUAMFilter: any = '';
  EUCityFilter: any = '';
  EUEmailFilter: any = '';
  EUStatusFilter: any = '';
  EUCompanyFilter: any = '';
  RUEmailDropdown: any[] = [];
  RUNameDropdown: any[] = [];
  RUCityDropdown: any[] = [];
  RULicenseDropdown: any[] = [];
  RUStatusDropdown: any[] = [];
  RUAMDropdown: any[] = [];
  RUCompanyDropdown: any[] = [];
  RUAddressDropdown: any[] = [];
  RUDateDropdown: any[] = [];
  RUNameFilter: any = '';
  RUAddressFilter: any = '';
  RULicenseFilter: any = '';
  RUDateFilter: any = '';
  RUAMFilter: any = '';
  RUCityFilter: any = '';
  RUEmailFilter: any = '';
  RUStatusFilter: any = '';
  RUCompanyFilter: any = '';
  selectedFilterDropdown: any = 'All';
  apiData: any;

  filterByStatus(order: any) {
    this.selectedFilterDropdown = order;
    switch (order) {
      case 'All':
        this.selectedFilter = {
          all: true,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Submitted':
        this.selectedFilter = {
          all: false,
          Submitted: true,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Approved':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: true,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Accepted':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: true,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Pending Payment':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: true,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'In  Progress':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: true,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Pending_Documentation':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: true,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Completed':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: true,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Rejected':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: true,
          Cancelled: false,
        };
        break;
      case 'Cancelled':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: true,
        };
        break;
    }
    this.filterByStatusDataHandel(order);
  }
  handleAcceptRejectStatus(status: any, id: any) {
    let data = {
      status: status,
    };
    this.httpSv.handleAcceptRejectStatus(data, id).subscribe(
      (res: any) => {
        if (res['status']) {
          this.getServiceReq();
        } else {
          this.toster.error('Oops! Something went wrong', '', {
            timeOut: 2000,
          });
        }
      },
      (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  filterByStatusDataHandel(order: any) {
    if (order == 'All') this.dataSource = new MatTableDataSource(this.ApiData);
    else {
      console.log(this.ApiData);
      const tempData = this.ApiData.filter((ele: any) => ele.status == order);
      this.dataSource = new MatTableDataSource(tempData);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  handelDeleteUser(id: any) {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Service Request?',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.httpSv.deleteServiceRequest(id).subscribe((res: any) => {
          if (res['status']) {
            const dialogRef = this.dialog.open(DialogDeleteDonePopup, {
              hasBackdrop: true,
              disableClose: true,
              data: {
                heading: 'Service Request Deleted',
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.ngOnInit();
            });
          }
        });
      }
    });
  }
  modifyAppUser(id: any) {
    console.log('aa', id);
    const dialogRef = this.dialog.open(DialogServiceRequestModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        id,
        type: 'eu',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getServiceReq();
      }
    });
  }
  getServiceReq() {
    this.httpSv.getAllServicesRequest(this.roleId)?.subscribe(
      (res: any) => {
        if (res['status']) {
          res['results'].map((data: any) => {
            if (data.date.indexOf('-') != -1) {
              data.date =
                this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
            }
          });
          this.dataSource = new MatTableDataSource(res['results']);
          this.dataSource.sort = this.sort;
          this.ApiData = res['results'];
          this.apiData = res['results'];
          this.createEUDropDown(this.apiData);
        } else {
          this.toster.error('Oops! Something went wrong', '', {
            timeOut: 2000,
          });
        }
      },
      (err: any) => {
        // if (err.error.message == 'no data found')
        // this.dataSource =new MatTableDataSource()
        if (err.error.message != 'no data found')
          this.toster.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  createEUDropDown(tableData: any) {
    tableData.forEach((data: any) => {
      if (
        data.services &&
        !this.EUNameDropdown.includes(data.services.toLowerCase())
      )
        this.EUNameDropdown.push(data.services.toLowerCase());
      if (data.state && !this.EUAddressDropdown.includes(data.state))
        this.EUAddressDropdown.push(data.state);
      if (data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status);
      if (
        data.fssai_regd_no &&
        !this.EULicenseDropdown.includes(data.fssai_regd_no)
      )
        this.EULicenseDropdown.push(data.fssai_regd_no);
      if (
        data.business_name &&
        !this.EUCompanyDropdown.includes(data.business_name)
      )
        this.EUCompanyDropdown.push(data.business_name);
      if (data.date && !this.EUDateDropdown.includes(data.date))
        this.EUDateDropdown.push(data.date);
      if (
        data.account_manager &&
        !this.EUamDropdown.includes(data.account_manager)
      )
        this.EUamDropdown.push(data.account_manager);
    });
  }
  EuApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.EUNameFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.EUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.services &&
            apiData.services.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'address') {
      this.EUAddressFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.state &&
            apiData.state.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'license') {
      this.EULicenseFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.fssai_regd_no &&
            apiData.fssai_regd_no.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'status') {
      this.EUStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'company') {
      this.EUCompanyFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.business_name &&
            apiData.business_name.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'date') {
      this.EUDateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.date && apiData.date == data) result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'date') {
      this.EUDateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.account_manager && apiData.account_manager == data)
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'am') {
      this.EUamFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.date &&
              data.date.toLowerCase() == this.EUDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.account_manager && apiData.account_manager == data)
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
  }
  openLicense(licensesNo: any, id: any, element: any) {
    this.httpSv.getBMLicenseDetails(licensesNo, id).subscribe((res: any) => {
      if (res['status']) {
        // licensesNo: this.tempLicenseNo,
        // data:'',
        // userId: this.data.id?this.data.id:this.userId,
        // userType: this.data.type,
        // type:state.toLowerCase(),
        var licenseDetails = res['results'][0];
        const dialogRef = this.dialog.open(DialogAppLicenseModify, {
          hasBackdrop: true,
          disableClose: true,
          data: {
            licensesNo,
            licenseDetails: licenseDetails,
            userType: id,
            role: 3,
            addNew: false,
            type: id,
            licId: licensesNo,
            sr: true,
          },
        });
      }
    });
  }
}

// --------------------------------- SUPER ADMIN -------------------------------------

@Component({
  selector: 'app-u0-service-request',
  templateUrl: './u0_service_request.html',
  styleUrls: ['./service-request.component.css'],
})
export class ServiceRequestU0Component implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    public dialog: MatDialog,
    private httpSv: HttpService,
    private dataService: DataService,
    private toster: ToastrService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      this.roleId = AES.decrypt(
        roleIdEncoded,
        this.dataService.secretKey,
      ).toString(enc.Utf8);
      this.dataService.roleId = this.roleId;
      // this.getServiceReq();
      if (this.dataService.prefilter == 'Enterprise') {
        this.getServiceReq();
        this.cesActiveFlag = true;
        this.dataService.prefilter = 'Retail';
      } else {
        this.getRUServiceReq();
        this.cesActiveFlag = false;
        this.dataService.prefilter = 'Retail';
      }
    } else {
      this.toster.error('Opps! something went wrong', '', { timeOut: 2000 });
    }
  }
  displayedColumns: string[] = [
    'sl_no',
    'id',
    'services',
    'fssai_regd_no',
    'business_name',
    'state',
    'am',
    'date',
    'status',
    // 'modify',
  ];
  data: any[] = [];
  roleId: any;
  dataSource: any = new MatTableDataSource();
  RUdataSource: any = new MatTableDataSource();
  cesActiveFlag: boolean = false;
  ApiData: any;
  switchuser: any = 'EntripriseUser';
  selectedFilter: any = {
    all: true,
    Submitted: false,
    Approved: false,
    Accepted: false,
    Pending_Payment: false,
    In_Progress: false,
    Pending_Documentation: false,
    Completed: false,
    Rejected: false,
    Cancelled: false,
  };
  filterData: any;
  EUEmailDropdown: any[] = [];
  EUamDropdown: any[] = [];
  EUNameDropdown: any[] = [];
  EUCityDropdown: any[] = [];
  EULicenseDropdown: any[] = [];
  EUStatusDropdown: any[] = [];
  EUAMDropdown: any[] = [];
  EUCompanyDropdown: any[] = [];
  EUAddressDropdown: any[] = [];
  EUDateDropdown: any[] = [];
  EUNameFilter: any = '';
  EUamFilter: any = '';
  EUAddressFilter: any = '';
  EULicenseFilter: any = '';
  EUDateFilter: any = '';
  EUAMFilter: any = '';
  EUCityFilter: any = '';
  EUEmailFilter: any = '';
  EUStatusFilter: any = '';
  EUCompanyFilter: any = '';
  RUEmailDropdown: any[] = [];
  RUamDropdown: any[] = [];
  RUNameDropdown: any[] = [];
  RUCityDropdown: any[] = [];
  RULicenseDropdown: any[] = [];
  RUStatusDropdown: any[] = [];
  RUAMDropdown: any[] = [];
  RUCompanyDropdown: any[] = [];
  RUAddressDropdown: any[] = [];
  RUDateDropdown: any[] = [];
  RUNameFilter: any = '';
  RUamFilter: any = '';
  RUAddressFilter: any = '';
  RULicenseFilter: any = '';
  RUDateFilter: any = '';
  RUAMFilter: any = '';
  RUCityFilter: any = '';
  RUEmailFilter: any = '';
  RUStatusFilter: any = '';
  RUCompanyFilter: any = '';
  selectedFilterDropdown: any = 'All';
  apiData: any;
  filterByStatus(order: any) {
    this.selectedFilterDropdown = order;
    switch (order) {
      case 'All':
        this.selectedFilter = {
          all: true,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Submitted':
        this.selectedFilter = {
          all: false,
          Submitted: true,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Approved':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: true,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Accepted':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: true,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Pending_Payment':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: true,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'In_Progress':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: true,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Pending_Documentation':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: true,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Completed':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: true,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Rejected':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: true,
          Cancelled: false,
        };
        break;
      case 'Cancelled':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: true,
        };
        break;
    }
    this.filterByStatusDataHandel(order);
  }
  handleSwicthWebUser(type: any) {
    if (type === 'ces') {
      if (!this.cesActiveFlag) this.cesActiveFlag = true;
    } else if (type === 'bm') {
      if (this.cesActiveFlag) this.cesActiveFlag = false;
    }
  }
  handleAcceptRejectStatus(status: any, id: any) {
    let data = {
      status: status,
    };
    this.httpSv.handleAcceptRejectStatus(data, id).subscribe(
      (res: any) => {
        if (res['status']) {
          this.getServiceReq();
        } else {
          this.toster.error('Oops! Something went wrong', '', {
            timeOut: 2000,
          });
        }
      },
      (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  filterByStatusDataHandel(order: any) {
    if (order == 'All') this.dataSource = new MatTableDataSource(this.ApiData);
    else {
      const tempData = this.ApiData.filter((ele: any) => ele.status == order);
      this.dataSource = new MatTableDataSource(tempData);
    }
  }
  openLicense(licensesNo: any, type: any) {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        licensesNo,
        type,
      },
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  handelDeleteUser(id: any) {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Service Request?',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.httpSv.deleteServiceRequest(id).subscribe((res: any) => {
          if (res['status']) {
            const dialogRef = this.dialog.open(DialogDeleteDonePopup, {
              hasBackdrop: true,
              disableClose: true,
              data: {
                heading: 'Service Request Deleted',
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.ngOnInit();
            });
          }
        });
      }
    });
  }
  modifyAppUser(id: any, type: string) {
    const dialogRef = this.dialog.open(DialogServiceRequestModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        id,
        type,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.cesActiveFlag) this.getServiceReq();
        else this.getRUServiceReq();
      }
    });
  }

  handleSwicthEnterpriseUser() {
    this.getServiceReq();
    this.cesActiveFlag = true;
  }
  handleSwicthRetailUser() {
    this.getRUServiceReq();
    this.cesActiveFlag = false;
  }

  handleSADelete() {}

  getServiceReq() {
    this.httpSv.getAllServicesRequest(this.roleId)?.subscribe(
      (res: any) => {
        if (res['status']) {
          res['results'].map((data: any) => {
            if (data.date.indexOf('-') != -1) {
              data.date =
                this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
            }
          });
          this.dataSource = new MatTableDataSource(res['results']);
          this.dataSource.sort = this.sort;
          this.ApiData = res['results'];
          this.filterData = res['results'];
          this.apiData = res['results'];
          this.createEUDropDown(this.apiData);
        } else {
          this.toster.error('Oops! Something went wrong', '', {
            timeOut: 2000,
          });
        }
      },
      (err: any) => {
        if (err.error.message == 'no data found') this.dataSource.data = [];
        if (err.error.message != 'no data found')
          this.toster.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  createEUDropDown(tableData: any) {
    tableData.forEach((data: any) => {
      if (
        data.services &&
        !this.EUNameDropdown.includes(data.services.toLowerCase())
      )
        this.EUNameDropdown.push(data.services.toLowerCase());
      if (data.state && !this.EUAddressDropdown.includes(data.state))
        this.EUAddressDropdown.push(data.state);
      if (data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status);
      if (
        data.fssai_regd_no &&
        !this.EULicenseDropdown.includes(data.fssai_regd_no)
      )
        this.EULicenseDropdown.push(data.fssai_regd_no);
      if (
        data.business_name &&
        !this.EUCompanyDropdown.includes(data.business_name)
      )
        this.EUCompanyDropdown.push(data.business_name);
      if (data.date && !this.EUDateDropdown.includes(data.date))
        this.EUDateDropdown.push(data.date);
      if (
        data.account_manager &&
        !this.EUamDropdown.includes(data.account_manager)
      )
        this.EUamDropdown.push(data.account_manager);
    });
  }
  EuApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.EUNameFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.EUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.services &&
            apiData.services.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'address') {
      this.EUAddressFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.state &&
            apiData.state.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'license') {
      this.EULicenseFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.fssai_regd_no &&
            apiData.fssai_regd_no.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'status') {
      this.EUStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'company') {
      this.EUCompanyFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.business_name &&
            apiData.business_name.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'date') {
      this.EUDateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.date && apiData.date == data) result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'am') {
      this.EUamFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.date &&
              data.date.toLowerCase() == this.EUDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.account_manager && apiData.account_manager == data)
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
  }
  getRUServiceReq() {
    this.httpSv.getAllRUServiceRequest(this.roleId)?.subscribe(
      (res: any) => {
        if (res['status']) {
          res['results'].map((data: any) => {
            if (data.date.indexOf('-') != -1) {
              data.date =
                this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
            }
          });
          this.dataSource = new MatTableDataSource(res['results']);
          this.dataSource.sort = this.sort;
          this.ApiData = res['results'];
          this.apiData = res['results'];
          this.filterData = res['results'];
          this.createRUDropDown(this.apiData);
        } else {
          this.toster.error('Oops! Something went wrong', '', {
            timeOut: 2000,
          });
        }
      },
      (err: any) => {
        if (err.error.message == 'no data found') this.dataSource.data = [];
        this.toster.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  createRUDropDown(tableData: any) {
    tableData.forEach((data: any) => {
      if (
        data.services &&
        !this.RUNameDropdown.includes(data.services.toLowerCase())
      )
        this.RUNameDropdown.push(data.services.toLowerCase());
      if (data.state && !this.RUAddressDropdown.includes(data.state))
        this.RUAddressDropdown.push(data.state);
      if (data.status && !this.RUStatusDropdown.includes(data.status))
        this.RUStatusDropdown.push(data.status);
      if (
        data.fssai_regd_no &&
        !this.RULicenseDropdown.includes(data.fssai_regd_no)
      )
        this.RULicenseDropdown.push(data.fssai_regd_no);
      if (
        data.business_name &&
        !this.RUCompanyDropdown.includes(data.business_name)
      )
        this.RUCompanyDropdown.push(data.business_name);
      if (data.date && !this.RUDateDropdown.includes(data.date))
        this.RUDateDropdown.push(data.date);
      if (
        data.account_manager &&
        !this.RUamDropdown.includes(data.account_manager)
      )
        this.RUamDropdown.push(data.account_manager);
    });
  }
  RuApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.RUNameFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.RUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.services &&
            apiData.services.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'address') {
      this.RUAddressFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.RUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.state &&
            apiData.state.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'license') {
      this.RULicenseFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.RUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.fssai_regd_no &&
            apiData.fssai_regd_no.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'status') {
      this.RUStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.RUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'company') {
      this.RUCompanyFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.RUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.business_name &&
            apiData.business_name.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'date') {
      this.RUDateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.date && apiData.date == data) result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'am') {
      this.RUamFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.date &&
              data.date.toLowerCase() == this.RUDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.account_manager && apiData.account_manager == data)
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
  }
}

@Component({
  selector: 'app-u1-service-request',
  templateUrl: './u1_service_request.html',
  styleUrls: ['./service-request.component.css'],
})
export class ServiceRequestU1Component implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    public dialog: MatDialog,
    private httpSv: HttpService,
    private dataService: DataService,
    private toster: ToastrService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      this.roleId = AES.decrypt(
        roleIdEncoded,
        this.dataService.secretKey,
      ).toString(enc.Utf8);
      this.dataService.roleId = this.roleId;
      this.getRUServiceReq();
    } else {
      this.toster.error('Opps! something went wrong', '', { timeOut: 2000 });
    }
    this.selectedTab = 'eu';
  }
  displayedColumns: string[] = [
    'sl_no',
    'id',
    'services',
    'fssai_regd_no',
    'business_name',
    'am',
    'state',
    'date',
    'status',
    // 'modify',
  ];
  data: any[] = [];
  roleId: any;
  dataSource: any = new MatTableDataSource();
  dataSourceru: any = new MatTableDataSource();
  cesActiveFlag: boolean = false;
  ApiData: any;
  ApiDataru: any;
  selectedTab: any;
  selectedFilter: any = {
    all: true,
    Submitted: false,
    Approved: false,
    Accepted: false,
    Pending_Payment: false,
    In_Progress: false,
    Pending_Documentation: false,
    Completed: false,
    Rejected: false,
    Cancelled: false,
  };
  filterData: any;
  EUEmailDropdown: any[] = [];
  EUNameDropdown: any[] = [];
  EUCityDropdown: any[] = [];
  EULicenseDropdown: any[] = [];
  EUStatusDropdown: any[] = [];
  EUAMDropdown: any[] = [];
  EUCompanyDropdown: any[] = [];
  EUAddressDropdown: any[] = [];
  EUDateDropdown: any[] = [];
  EUamDropdown: any[] = [];
  EUNameFilter: any = '';
  EUamFilter: any = '';
  EUAddressFilter: any = '';
  EULicenseFilter: any = '';
  EUDateFilter: any = '';
  EUAMFilter: any = '';
  EUCityFilter: any = '';
  EUEmailFilter: any = '';
  EUStatusFilter: any = '';
  EUCompanyFilter: any = '';
  RUEmailDropdown: any[] = [];
  RUNameDropdown: any[] = [];
  RUamDropdown: any[] = [];
  RUCityDropdown: any[] = [];
  RULicenseDropdown: any[] = [];
  RUStatusDropdown: any[] = [];
  RUAMDropdown: any[] = [];
  RUCompanyDropdown: any[] = [];
  RUAddressDropdown: any[] = [];
  RUDateDropdown: any[] = [];
  RUNameFilter: any = '';
  RUAddressFilter: any = '';
  RULicenseFilter: any = '';
  RUamFilter: any = '';
  RUDateFilter: any = '';
  RUAMFilter: any = '';
  RUCityFilter: any = '';
  RUEmailFilter: any = '';
  RUStatusFilter: any = '';
  RUCompanyFilter: any = '';
  selectedFilterDropdown: any = 'All';
  apiData: any;
  handleSwicthEnterpriseUser() {
    this.getServiceReq();
    this.cesActiveFlag = true;
    this.selectedTab = 'eu';
  }
  handleSwicthRetailUser() {
    this.getRUServiceReq();
    this.cesActiveFlag = false;
    this.selectedTab = 'ru';
  }
  filterByStatus(order: any, from: any) {
    this.selectedFilterDropdown = order;
    switch (order) {
      case 'All':
        this.selectedFilter = {
          all: true,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Submitted':
        this.selectedFilter = {
          all: false,
          Submitted: true,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Approved':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: true,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Accepted':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: true,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Pending_Payment':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: true,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'In_Progress':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: true,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Pending_Documentation':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: true,
          Completed: false,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Completed':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: true,
          Rejected: false,
          Cancelled: false,
        };
        break;
      case 'Rejected':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: true,
          Cancelled: false,
        };
        break;
      case 'Cancelled':
        this.selectedFilter = {
          all: false,
          Submitted: false,
          Approved: false,
          Accepted: false,
          Pending_Payment: false,
          In_Progress: false,
          Pending_Documentation: false,
          Completed: false,
          Rejected: false,
          Cancelled: true,
        };
        break;
    }
    this.filterByStatusDataHandel(order, this.selectedTab);
  }

  handleAcceptRejectStatus(status: any, id: any) {
    let data = {
      status: status,
    };
    this.httpSv.handleAcceptRejectStatus(data, id).subscribe(
      (res: any) => {
        if (res['status']) {
          this.getServiceReq();
        } else {
          this.toster.error('Oops! Something went wrong', '', {
            timeOut: 2000,
          });
        }
      },
      (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  filterByStatusDataHandel(order: any, from: any) {
    if (from == 'eu') {
      if (order == 'All')
        this.dataSource = new MatTableDataSource(this.ApiData);
      else {
        const tempData = this.ApiData.filter((ele: any) => ele.status == order);
        this.dataSource = new MatTableDataSource(tempData);
      }
    } else {
      if (order == 'All')
        this.dataSourceru = new MatTableDataSource(this.ApiDataru);
      else {
        const tempData = this.ApiDataru.filter(
          (ele: any) => ele.status == order,
        );
        this.dataSourceru = new MatTableDataSource(tempData);
      }
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.selectedTab == 'eu')
      this.dataSource.filter = filterValue.trim().toLowerCase();
    else this.dataSourceru.filter = filterValue.trim().toLowerCase();
  }
  openLicense(licensesNo: any, type: any) {
    const dialogRef = this.dialog.open(DialogAppLicenseModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        licensesNo,
        type,
      },
    });
  }
  handelDeleteUser(id: any) {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Service Request?',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.httpSv.deleteServiceRequest(id).subscribe((res: any) => {
          if (res['status']) {
            const dialogRef = this.dialog.open(DialogDeleteDonePopup, {
              hasBackdrop: true,
              disableClose: true,
              data: {
                heading: 'Service Request Deleted',
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.ngOnInit();
            });
          }
        });
      }
    });
  }
  modifyAppUser(id: any) {
    const dialogRef = this.dialog.open(DialogServiceRequestModify, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        id,
        type: this.selectedTab,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getServiceReq();
        this.getRUServiceReq();
      }
    });
  }
  getServiceReq() {
    this.httpSv.getAllServicesRequest(this.roleId)?.subscribe(
      (res: any) => {
        if (res['status']) {
          res['results'].map((data: any) => {
            if (data.date.indexOf('-') != -1) {
              data.date =
                this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
            }
          });
          this.dataSource = new MatTableDataSource(res['results']);
          this.dataSource.sort = this.sort;
          this.ApiData = res['results'];
          this.apiData = res['results'];
          this.filterData = res['results'];
          this.createEUDropDown(this.ApiData);
        } else {
          this.toster.error('Oops! Something went wrong', '', {
            timeOut: 2000,
          });
        }
      },
      (err: any) => {
        // if (err.error.message == 'no data found')
        this.dataSource = [];
        if (err.error.message != 'no data found')
          this.toster.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  createEUDropDown(tableData: any) {
    tableData.forEach((data: any) => {
      if (
        data.services &&
        !this.EUNameDropdown.includes(data.services.toLowerCase())
      )
        this.EUNameDropdown.push(data.services.toLowerCase());
      if (data.state && !this.EUAddressDropdown.includes(data.state))
        this.EUAddressDropdown.push(data.state);
      if (data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status);
      if (
        data.fssai_regd_no &&
        !this.EULicenseDropdown.includes(data.fssai_regd_no)
      )
        this.EULicenseDropdown.push(data.fssai_regd_no);
      if (
        data.business_name &&
        !this.EUCompanyDropdown.includes(data.business_name)
      )
        this.EUCompanyDropdown.push(data.business_name);
      if (data.date && !this.EUDateDropdown.includes(data.date))
        this.EUDateDropdown.push(data.date);
      if (
        data.account_manager &&
        !this.EUamDropdown.includes(data.account_manager)
      )
        this.EUamDropdown.push(data.account_manager);
    });
  }
  EuApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.EUNameFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.EUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.services &&
            apiData.services.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'address') {
      this.EUAddressFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.state &&
            apiData.state.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'license') {
      this.EULicenseFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.fssai_regd_no &&
            apiData.fssai_regd_no.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'status') {
      this.EUStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'company') {
      this.EUCompanyFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.EUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.business_name &&
            apiData.business_name.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'date') {
      this.EUDateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.EUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.date && apiData.date == data) result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'am') {
      this.EUamFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.date &&
              data.date.toLowerCase() == this.EUDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.EUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.EUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.EULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.account_manager && apiData.account_manager == data)
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
  }
  getRUServiceReq() {
    this.httpSv.getAllRUServiceRequest(this.roleId)?.subscribe(
      (res: any) => {
        if (res['status']) {
          res['results'].map((data: any) => {
            if (data.date.indexOf('-') != -1) {
              data.date =
                this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
            }
          });
          this.dataSourceru = new MatTableDataSource(res['results']);
          this.dataSourceru.sort = this.sort;
          this.ApiDataru = res['results'];
          this.filterData = res['results'];
          this.createRUDropDown(this.ApiDataru);
        } else {
          this.toster.error('Oops! Something went wrong', '', {
            timeOut: 2000,
          });
        }
      },
      (err: any) => {
        // if (err.error.message == 'no data found')
        this.dataSourceru.data = [];
        if (err.error.message != 'no data found')
          this.toster.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  createRUDropDown(tableData: any) {
    tableData.forEach((data: any) => {
      if (
        data.services &&
        !this.RUNameDropdown.includes(data.services.toLowerCase())
      )
        this.RUNameDropdown.push(data.services.toLowerCase());
      if (data.state && !this.RUAddressDropdown.includes(data.state))
        this.RUAddressDropdown.push(data.state);
      if (data.status && !this.RUStatusDropdown.includes(data.status))
        this.RUStatusDropdown.push(data.status);
      if (
        data.fssai_regd_no &&
        !this.RULicenseDropdown.includes(data.fssai_regd_no)
      )
        this.RULicenseDropdown.push(data.fssai_regd_no);
      if (
        data.business_name &&
        !this.RUCompanyDropdown.includes(data.business_name)
      )
        this.RUCompanyDropdown.push(data.business_name);
      if (data.date && !this.RUDateDropdown.includes(data.date))
        this.RUDateDropdown.push(data.date);
      if (
        data.account_manager &&
        !this.RUamDropdown.includes(data.account_manager)
      )
        this.RUamDropdown.push(data.account_manager);
    });
  }
  RuApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.RUNameFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.ApiDataru;
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.RUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.services &&
            apiData.services.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSourceru.data = this.filterData;
    }
    if (from == 'address') {
      this.RUAddressFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.ApiDataru;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.RUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.state &&
            apiData.state.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSourceru.data = this.filterData;
    }
    if (from == 'license') {
      this.RULicenseFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.ApiDataru;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.RUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          console.log(apiData.fssai_regd_no.toLowerCase(), data.toLowerCase());

          if (
            apiData.fssai_regd_no &&
            apiData.fssai_regd_no.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSourceru.data = this.filterData;
    }
    if (from == 'status') {
      this.RUStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.ApiDataru;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.RUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSourceru.data = this.filterData;
    }
    if (from == 'company') {
      this.RUCompanyFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.ApiDataru;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.date && data.date == this.RUDateFilter) result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.business_name &&
            apiData.business_name.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSourceru.data = this.filterData;
    }
    if (from == 'date') {
      this.RUDateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUamFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.RUamFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.date && apiData.date == data) result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSourceru.data = this.filterData;
    }
    if (from == 'am') {
      this.RUamFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.RUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.RUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.RUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.date &&
              data.date.toLowerCase() == this.RUDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUCompanyFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.business_name &&
              data.business_name.toLowerCase() ==
                this.RUCompanyFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RUAddressFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.RUAddressFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.RULicenseFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.fssai_regd_no &&
              data.fssai_regd_no.toLowerCase() == this.RULicenseFilter
            )
              result.push(data);
          });
          filterData = result;
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.account_manager && apiData.account_manager == data)
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSourceru.data = this.filterData;
    }
  }
}

//--------------------------------------------service_request_modify_dialog------------------------------------------------------
//--------------------------------------------service_request_modify_dialog------------------------------------------------------
//--------------------------------------------service_request_modify_dialog------------------------------------------------------
//--------------------------------------------service_request_modify_dialog------------------------------------------------------
//--------------------------------------------service_request_modify_dialog------------------------------------------------------
//--------------------------------------------service_request_modify_dialog------------------------------------------------------

@Component({
  selector: 'app-user-modify-dialog',
  templateUrl: './request_modify_dialog.html',
  styleUrls: ['./service-request.component.css'],
})
export class DialogServiceRequestModify implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogServiceRequestModify>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataService: DataService,
    public dialog: MatDialog,
  ) {}
  roleId: any;
  isBlock = false;
  ModifierName: any;
  ModifiedDate: any;
  userId: any;
  licensesUrl: any;
  licensesNo: any;
  notes: any;
  apiStatus: any;
  selectedTab: any = 'sr';
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = [
    'sl_no',
    'from',
    'doc_name',
    'view_doc',
    'status',
  ];
  ngOnInit(): void {
    console.log(this.data);
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      this.roleId = AES.decrypt(
        roleIdEncoded,
        this.dataService.secretKey,
      ).toString(enc.Utf8);
      this.dataService.roleId = this.roleId;
      this.getServiceDtls(this.data.id, this.roleId);
      this.getDocuments();
    } else {
      this.toster.error('Opps! something went wrong', '', { timeOut: 2000 });
    }
    // this.getServiceDtls(this.data,this.roleId)
    if (this.roleId == 3) {
      this.isBlock = true;
    }
    if (this.roleId == 1) {
      this.isBlock = false;
    }
    this.allState = this.dataService.allStatesOfIndia;
  }
  getDocuments() {
    this.httpSv.getServiceDocs(this.data.id).subscribe((res: any) => {
      if (res['status']) {
        let docData: any = [];
        res['results'][0].application_document_details.map((data: any) => {
          data.from = 'Company Details';
          data.fromData = 'application';
          docData.push(data);
        });
        res['results'][0].calibration_document_details.map((data: any) => {
          data.from = 'Calibration';
          data.fromData = 'calibration';
          docData.push(data);
        });
        res['results'][0].health_document_details.map((data: any) => {
          data.from = 'Health';
          data.fromData = 'health';
          docData.push(data);
        });
        res['results'][0].testing_document_details.map((data: any) => {
          data.from = 'Testing';
          data.fromData = 'testing';
          docData.push(data);
        });
        res['results'][0].training_document_details.map((data: any) => {
          data.from = 'Training';
          data.fromData = 'training';
          docData.push(data);
        });
        res['results'][0].others_document_details.map((data: any) => {
          data.from = 'Others';
          data.fromData = 'others';
          docData.push(data);
        });
        res['results'][0].audit_document_details.map((data: any) => {
          data.from = 'Audits';
          data.fromData = 'audits';
          docData.push(data);
        });
        this.dataSource.data = docData;
      } else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 });
      }
    });
  }
  addDocument() {
    this.data.licensesNo = this.selectedLicenceNumber;
    const dialogRef = this.dialog
      .open(DialogAddDocumentSR, {
        hasBackdrop: true,
        // disableClose:true
        data: {
          data: this.data,
          userId: this.userId,
        },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.getDocuments();
        }
      });
  }
  viewLicence(url: any = this.licensesUrl) {
    let fileType = url.split('.');
    fileType = fileType[fileType.length - 1];
    const dialogRef = this.dialog.open(DialogAppLicenseView, {
      hasBackdrop: true,
      // disableClose:true
      data: {
        type: fileType,
        src: url,
      },
    });
  }
  getAudit() {
    const dialogRef = this.dialog.open(DialogueAuditReport, {
      hasBackdrop: true,
      disableClose: true,
      data: this.data.id,
    });
  }
  updateDoc(type: any, data: any) {
    const dialogRef = this.dialog.open(DialogAddDocumentSR, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        data: data,
        type: this.data,
        userId: this.userId,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) this.getDocuments();
    });
  }
  disableFlag: boolean = true;
  onMaterialGroupChange(event: any) {
    // console.log(event);
  }
  handleTabChange(tabData: any) {
    this.selectedTab = tabData;
  }
  onSelectedStateChange(event: any) {
    this.state = event;
  }

  allKOB: any[] = [
    { id: 1, name: 'sdfsdfsdf' },
    { id: 2, name: 'sdfsdfsdf' },
    { id: 3, name: 'sdfsdfsdf' },
    { id: 4, name: 'sdfsdfsdf' },
    { id: 5, name: 'sdfsdfsdf' },
    { id: 6, name: 'sdfsdfsdf' },
    { id: 7, name: 'sdfsdfsdf' },
    { id: 8, name: 'sdfsdfsdf' },
    { id: 9, name: 'sdfsdfsdf' },
  ];
  selectedKOBBusiness: any[] = [];

  selectedFilter: any = {
    active: false,
    expired: false,
    requested: false,
  };
  txtStatus: string = '';
  filterByStatus(order: any) {
    switch (order) {
      case 'Active':
        this.selectedFilter = {
          active: true,
          expired: false,
          requested: false,
        };
        this.txtStatus = 'Active';
        break;
      case 'Expired':
        this.selectedFilter = {
          active: false,
          expired: true,
          requested: false,
        };
        this.txtStatus = 'Expired';
        break;
      case 'Requested':
        this.selectedFilter = {
          active: false,
          expired: false,
          requested: true,
        };
        this.txtStatus = 'Requested';
        break;
    }
  }

  allLicenceNumber: any[] = [];
  selectedLicenceNumber: any = [];
  recentRepo: any;
  serviceReqName: string = '';
  companyName: string = '';
  status: string = '';
  address: string = '';
  state: string = '';
  city: string = '';
  allState: any[] = [];
  selectedState: any;
  handleBmDelete() {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this Enterprise user?',
      },
    });
  }

  handleStatusChange(status: any) {
    this.status = status;
  }

  handleSAServiceRequestDelete() {
    const dialogRef = this.dialog.open(DialogDeleteCofirm, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        heading: 'Are you sure you want to remove this user request?',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.roleId == 1 && this.data.type == 'eu') {
          this.httpSv
            .deleteEnterpriseServcieRequest(this.data.id, 'delete')
            .subscribe((res: any) => {
              if (res['status']) {
                this.dialogRef.close(true);
              } else {
                this.toster.error('Oops! Something went wrong', '', {
                  timeOut: 2000,
                });
              }
            });
        } else if (this.roleId == 1 && this.data.type == 'ru') {
          this.httpSv
            .deleteRetailServcieRequest(this.data.id, 'delete')
            .subscribe((res: any) => {
              if (res['status']) {
                this.dialogRef.close(true);
              } else {
                this.toster.error('Oops! Something went wrong', '', {
                  timeOut: 2000,
                });
              }
            });
        } else if (this.roleId == 2) {
          this.httpSv
            .deleteAMServiceRequest(this.data.id, this.data.type)
            ?.subscribe((res: any) => {
              if (res['status']) {
                this.dialogRef.close(true);
              } else {
                this.toster.error('Oops! Something went wrong', '', {
                  timeOut: 2000,
                });
              }
            });
        } else if (this.roleId == 5) {
          this.httpSv
            .deleteServiceRequest(this.data.id)
            .subscribe((res: any) => {
              if (res['status']) {
                this.dialogRef.close(true);
              } else {
                this.toster.error('Oops! Something went wrong', '', {
                  timeOut: 2000,
                });
              }
            });
        }
      }
    });
  }
  getDate(data: any) {
    const date = new Date(data);
    // Define arrays for month and weekday names
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Format the date manually
    const weekday = weekdays[date.getUTCDay()];
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const utcDate = new Date(date);

    // Convert to IST (UTC + 5:30)
    const istDate = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000);

    // Get hours and minutes
    let hours = istDate.getUTCHours();
    let minutes: any = istDate.getUTCMinutes();

    // Format to AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    // Format minutes to 2 digits
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${weekday}, ${month}, ${day} ${year} at ${hours}:${minutes} ${ampm}`;
  }
  allTypeOfService: any[] = [];
  getServiceDtls(id: any, roleId: any) {
    console.log(this.data.type, this.data);
    console.log(roleId, 'sfdsvsdf');

    if (this.data.type == 'eu') {
      this.httpSv
        .getServicesRequestEnterpriseDts(id, roleId)
        ?.subscribe((res: any) => {
          if (res['status']) {
            let temp: any;
            console.log(roleId);
            if (roleId == 1) {
              console.log(this.serviceReqName, 'awda');
              temp = res['results'];
              this.address = temp.address;
            } else if (roleId == 3) {
              temp = res['results'];
              this.address = temp.address;
            } else if (roleId == 2) {
              temp = res['results'];
              this.address = temp.address;
              this.city = temp.address;
            } else {
              temp = res['results'];
              this.address = temp.address;
            }
            this.serviceReqName = temp.services;
            console.log(this.serviceReqName, this.address);
            this.selectedLicenceNumber = temp.fssai_regd_no;
            this.companyName = temp.business_name;
            this.status = temp.status;
            this.userId = temp.user_id;
            this.apiStatus = temp.status;
            this.notes = temp.notes;
            this.ModifierName = temp.last_modified.name;
            const date = new Date(temp.last_modified.datetime);
            this.recentRepo = temp.last_three_status;
            // Define arrays for month and weekday names
            // Combine all parts into the desired format
            this.ModifiedDate = this.getDate(date);
            this.state = temp.state;
            this.selectedState = temp.state;
            if (roleId != 3 && roleId != 1 && roleId != 2) {
              this.httpSv.getAllLicenses().subscribe(
                (res: any) => {
                  if (res['status']) {
                    const license = res['results'].map((ele: any) => {
                      if (ele.registration_no) {
                        return { name: ele.registration_no };
                      } else return { name: 'N/A' };
                    });
                    this.allLicenceNumber = license;
                  } else {
                  }
                },
                (err: any) => {
                  // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
                },
              );
            }
            if (roleId == 1 || roleId == 5 || roleId == 2) {
              // this.selectedTypeOfService = temp.
              console.log('welcome');
              this.httpSv.getAllTypeOfService().subscribe(
                (res: any) => {
                  if (res['status']) {
                    if (res['result'].length > 0) {
                      res['result'].forEach((service: any) => {
                        this.allTypeOfService = [
                          ...this.allTypeOfService,
                          service,
                        ];
                      });
                      this.allTypeOfService.push({
                        id: 13,
                        name: 'New license',
                      });
                    } else this.allTypeOfService = [{ name: 'N/A' }];
                  } else {
                  }
                },
                (err: any) => {
                  // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
                },
              );
              if (roleId == 1 || roleId == 2)
                this.allLicenceNumber = temp.fssai_lic_no_dropdown;

              // if(roleId == 1){
              //   this.httpSv.getAllLicensesDrowpDwonEnterprise().subscribe(
              //     (res: any) => {
              //       if (res['status']) {
              //         const license = res['results'].map((ele: any) => {
              //           if (ele.registration_no) {
              //             return { name: ele.registration_no };
              //           } else return { name: 'N/A' };
              //         });
              //         this.allLicenceNumber = license;
              //       } else {
              //       }
              //     },
              //     (err: any) => {
              //       // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
              //     }
              //   );
              // }
              //   if(roleId == 2){
              //     this.httpSv.getAMAllLicensesDropdown(id, this.data.type)?.subscribe(
              //       (res: any) => {
              //         if (res['status']) {
              //           const license = res['results'].map((ele: any) => {
              //             if (ele.registration_no) {
              //               return { name: ele.registration_no };
              //             } else return { name: 'N/A' };
              //           });
              //           this.allLicenceNumber = license;
              //         } else {
              //         }
              //       },
              //       (err: any) => {
              //         // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
              //       }
              //     );
              //   }
              //   console.log(this.allTypeOfService, this.allLicenceNumber);
            } else if (roleId == 1) {
              this.httpSv.getAllTypeOfService().subscribe(
                (res: any) => {
                  if (res['status']) {
                    if (res['result'].length > 0) {
                      this.allTypeOfService = res['result'];
                      this.allTypeOfService.push({
                        id: 13,
                        name: 'New license',
                      });
                    } else this.allTypeOfService = [{ name: 'N/A' }];
                  } else {
                  }
                },
                (err: any) => {
                  // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
                },
              );
              const license = temp.fssai_lic_no_dropdown.map((ele: any) => {
                if (ele) {
                  return { name: ele };
                } else return { name: 'N/A' };
              });
              this.allLicenceNumber = license;
            }
          }
        });
    } else if (this.data.type == 'ru') {
      this.httpSv
        .getServicesRequestretailDts(id, roleId)
        ?.subscribe((res: any) => {
          if (res['status']) {
            let temp: any;
            console.log(roleId);
            if (roleId == 1) {
              console.log(this.serviceReqName, 'awda');
              temp = res['results'];
              this.address = temp.address;
            } else if (roleId == 3) {
              temp = res['results'];
              this.address = temp.address;
            } else {
              temp = res['results'];
              this.address = temp.address;
            }
            this.serviceReqName = temp.services;
            console.log(this.serviceReqName);
            this.selectedLicenceNumber = temp.fssai_regd_no;
            this.companyName = temp.business_name;
            this.userId = temp.user_id;
            this.status = temp.status;
            this.apiStatus = temp.status;
            this.selectedState = temp.state;
            this.ModifierName = temp.last_modified.name;
            const date = new Date(temp.last_modified.datetime);
            this.recentRepo = temp.last_three_status;

            // Combine all parts into the desired format
            this.ModifiedDate = this.getDate(date);
            this.notes = temp.notes;
            this.state = temp.state;

            if (roleId != 3 && roleId != 1) {
              this.httpSv.getAllLicenses().subscribe(
                (res: any) => {
                  if (res['status']) {
                    const license = res['results'].map((ele: any) => {
                      if (ele.registration_no) {
                        return { name: ele.registration_no };
                      } else return { name: 'N/A' };
                    });
                    this.allLicenceNumber = license;
                  } else {
                  }
                },
                (err: any) => {
                  // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
                },
              );
            }
            if (roleId == 1 || roleId == 5 || roleId == 2) {
              // this.selectedTypeOfService = temp.
              this.httpSv.getAllTypeOfService().subscribe(
                (res: any) => {
                  if (res['status']) {
                    if (res['result'].length > 0) {
                      this.allTypeOfService = res['result'];
                      this.allTypeOfService.push({
                        id: 13,
                        name: 'New license',
                      });
                    } else this.allTypeOfService = [{ name: 'N/A' }];
                  } else {
                  }
                },
                (err: any) => {
                  // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
                },
              );
              // if(roleId==1 || roleId==2)
              this.allLicenceNumber = temp.fssai_lic_no_dropdown;
              // this.httpSv.getAllLicensesDrowpDwonRetail().subscribe(
              //   (res: any) => {
              //     if (res['status']) {
              //       const license = res['results'].map((ele: any) => {
              //         if (ele.registration_no) {
              //           return { name: ele.registration_no };
              //         } else return { name: 'N/A' };
              //       });
              //       this.allLicenceNumber = license;
              //     } else {
              //     }
              //   },
              //   (err: any) => {
              //     // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
              //   }
              // );
              console.log(this.allTypeOfService, this.allLicenceNumber);
            }
          }
        });
    } else if (roleId == 5) {
      console.log('sdgvdgdf');
      this.httpSv
        .getServicesRequestEnterpriseDts(id, roleId)
        ?.subscribe((res: any) => {
          if (res['status']) {
            let temp: any;
            console.log(roleId);
            if (roleId == 1) {
              console.log(this.serviceReqName, 'awda');
              temp = res['results'];
              this.address = temp.address;
            } else if (roleId == 3) {
              temp = res['results'];
              this.address = temp.address;
            } else {
              temp = res['results'][0];
              this.address = temp.address;
            }
            this.serviceReqName = temp.services;
            console.log(this.serviceReqName);
            this.selectedLicenceNumber = temp.fssai_regd_no;
            this.companyName = temp.business_name;
            this.status = temp.status;
            this.userId = temp.user_id;
            this.apiStatus = temp.status;
            this.ModifierName = temp.last_modified.name;
            const date = new Date(temp.last_modified.datetime);
            this.recentRepo = temp.last_three_status;

            // Combine all parts into the desired format
            this.ModifiedDate = this.getDate(date);
            this.state = temp.state;
            if (roleId != 3 && roleId != 1) {
              this.httpSv.getAllLicenses().subscribe(
                (res: any) => {
                  if (res['status']) {
                    const license = res['results'].map((ele: any) => {
                      if (ele.registration_no) {
                        return { name: ele.registration_no };
                      } else return { name: 'N/A' };
                    });
                    this.allLicenceNumber = license;
                  } else {
                  }
                },
                (err: any) => {
                  // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
                },
              );
            }
            if (roleId == 5) {
              // this.selectedTypeOfService = temp.
              this.httpSv.getAllTypeOfService().subscribe(
                (res: any) => {
                  if (res['status']) {
                    if (res['result'].length > 0) {
                      this.allTypeOfService = res['result'];
                      this.allTypeOfService.push({
                        id: 13,
                        name: 'New license',
                      });
                    } else this.allTypeOfService = [{ name: 'N/A' }];
                  } else {
                  }
                },
                (err: any) => {
                  // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
                },
              );
              this.httpSv.getAllLicensesDrowpDwonEnterprise().subscribe(
                (res: any) => {
                  if (res['status']) {
                    const license = res['results'].map((ele: any) => {
                      if (ele.registration_no) {
                        return { name: ele.registration_no };
                      } else return { name: 'N/A' };
                    });
                    this.allLicenceNumber = license;
                  } else {
                  }
                },
                (err: any) => {
                  // this.toster.error('Oops! Someting went wrong', '', { timeOut: 2000 })
                },
              );
              console.log(this.allTypeOfService, this.allLicenceNumber);
            }
          }
        });
    }
  }
  remarks: any;
  checkAndUpdateRemarks() {
    if (this.status != this.apiStatus && this.roleId != 5) {
      this.dialog
        .open(DialogueAddRemark, {})
        .afterClosed()
        .subscribe((res) => {
          if (res['status']) {
            this.remarks = res['data'];
            this.updateServiceReq();
          }
        });
    } else {
      this.updateServiceReq();
    }
  }
  updateServiceReq() {
    console.log(this.status, this.apiStatus);

    if (this.data.type == 'eu') {
      console.log(this.data);
      let licenseNo = new FormData();
      licenseNo.append('fssai_regd_no', this.selectedLicenceNumber);
      if (this.selectedLicenceNumber != 'N/A') {
        if (this.selectedLicenceNumber != null) {
          if (this.serviceReqName != '') {
            if (this.companyName != '') {
              if (this.status != '') {
                if (this.address != '') {
                  if (this.state != '') {
                    const serviceId =
                      this.allTypeOfService[
                        this.allTypeOfService.findIndex(
                          (ele: any) => ele.name == this.serviceReqName,
                        )
                      ].id;
                    const payloadData = {
                      services_id: serviceId,
                      fssai_regd_no: this.selectedLicenceNumber,
                      business_name: this.companyName,
                      address: this.address,
                      state: this.state,
                      status: this.status,
                      notes: this.notes,
                      remarks: this.remarks,
                    };
                    this.httpSv
                      .updateEUServiceRequest(this.data.id, payloadData)
                      .subscribe(
                        (res: any) => {
                          if (res['status']) {
                            this.toster.success(
                              'Details have been successfully updated.',
                              '',
                              { timeOut: 2000 },
                            );
                            this.dialogRef.close(true);
                          } else {
                            this.toster.error(
                              'Oops! Something went wrong',
                              '',
                              { timeOut: 2000 },
                            );
                            this.close();
                          }
                        },
                        (err: any) => {
                          this.toster.error('Oops! Something went wrong', '', {
                            timeOut: 2000,
                          });
                          this.close();
                        },
                      );
                  } else {
                    this.toster.error('Please fill the state field ', '', {
                      timeOut: 2000,
                    });
                  }
                } else {
                  this.toster.error('Please fill the address field', '', {
                    timeOut: 2000,
                  });
                }
              } else {
                this.toster.error('Please select the status ', '', {
                  timeOut: 2000,
                });
              }
            } else {
              this.toster.error('Please select the company name', '', {
                timeOut: 2000,
              });
            }
          } else {
            this.toster.error('Please select the service request name ', '', {
              timeOut: 2000,
            });
          }
        } else {
          this.toster.error('Please select any license', '', { timeOut: 2000 });
        }
      } else {
        this.toster.error('Some technical issue with this license', '', {
          timeOut: 2000,
        });
      }
    } else if (this.data.type == 'ru') {
      let licenseNo = new FormData();
      licenseNo.append('fssai_regd_no', this.selectedLicenceNumber);
      if (this.selectedLicenceNumber != 'N/A') {
        if (this.selectedLicenceNumber != null) {
          if (this.serviceReqName != '') {
            if (this.companyName != '') {
              if (this.status != '') {
                if (this.address != '') {
                  if (this.state != '') {
                    const serviceId =
                      this.allTypeOfService[
                        this.allTypeOfService.findIndex(
                          (ele: any) => ele.name == this.serviceReqName,
                        )
                      ].id;
                    const payloadData = {
                      services_id: serviceId,
                      fssai_regd_no: this.selectedLicenceNumber,
                      business_name: this.companyName,
                      address: this.address,
                      state: this.state,
                      status: this.status,
                      notes: this.notes,
                      remarks: this.remarks,
                    };
                    this.httpSv
                      .updateRUServiceRequest(this.data.id, payloadData)
                      .subscribe(
                        (res: any) => {
                          if (res['status']) {
                            this.toster.success(
                              'Details have been successfully updated.',
                              '',
                              { timeOut: 2000 },
                            );
                            this.ngOnInit();
                            this.dialogRef.close(true);
                          } else {
                            this.toster.error(
                              'Oops! Something went wrong',
                              '',
                              { timeOut: 2000 },
                            );
                            this.close();
                          }
                        },
                        (err: any) => {
                          this.toster.error('Oops! Something went wrong', '', {
                            timeOut: 2000,
                          });
                          this.close();
                        },
                      );
                  } else {
                    this.toster.error('Please fill the state field ', '', {
                      timeOut: 2000,
                    });
                  }
                } else {
                  this.toster.error('Please fill the address field', '', {
                    timeOut: 2000,
                  });
                }
              } else {
                this.toster.error('Please select the status ', '', {
                  timeOut: 2000,
                });
              }
            } else {
              this.toster.error('Please select the company name', '', {
                timeOut: 2000,
              });
            }
          } else {
            this.toster.error('Please select the service request name ', '', {
              timeOut: 2000,
            });
          }
        } else {
          this.toster.error('Please select any license', '', { timeOut: 2000 });
        }
      } else {
        this.toster.error('Some technical issue with this license', '', {
          timeOut: 2000,
        });
      }
    } else if (this.roleId == 5) {
      if (this.selectedLicenceNumber != 'N/A') {
        let licenseNo = new FormData();
        licenseNo.append('fssai_regd_no', this.selectedLicenceNumber);
        licenseNo.append('notes', this.notes);
        this.httpSv.updateServiceRequest(this.data.id, licenseNo).subscribe(
          (res: any) => {
            if (res['status']) {
              this.toster.success(
                'Details have been successfully updated.',
                '',
                { timeOut: 2000 },
              );
              this.dialogRef.close(true);
            } else {
              this.toster.error('Oops! Something went wrong', '', {
                timeOut: 2000,
              });
              this.close();
            }
          },
          (err: any) => {
            this.toster.error('Oops! Something went wrong', '', {
              timeOut: 2000,
            });
            this.close();
          },
        );
      } else {
        this.toster.error('Some technical issue with this license', '', {
          timeOut: 2000,
        });
      }
    }
  }

  checkAndUpdateRemarksAM() {
    if (this.status != this.apiStatus && this.roleId != 5) {
      this.dialog
        .open(DialogueAddRemark, {})
        .afterClosed()
        .subscribe((res) => {
          if (res['status']) {
            this.remarks = res['data'];
            this.updateAMServiceReq();
          }
        });
    } else {
      this.updateAMServiceReq();
    }
  }
  updateAMServiceReq() {
    if (this.data.type == 'eu') {
      let licenseNo = new FormData();
      licenseNo.append('fssai_regd_no', this.selectedLicenceNumber);
      if (this.selectedLicenceNumber != 'N/A') {
        if (this.selectedLicenceNumber != null) {
          if (this.serviceReqName != '') {
            if (this.companyName != '') {
              if (this.status != '') {
                if (this.address != '') {
                  if (this.state != '') {
                    const serviceId =
                      this.allTypeOfService[
                        this.allTypeOfService.findIndex(
                          (ele: any) => ele.name == this.serviceReqName,
                        )
                      ].id;
                    const payloadData = {
                      services_id: serviceId,
                      fssai_regd_no: this.selectedLicenceNumber,
                      business_name: this.companyName,
                      address: this.address,
                      state: this.state,
                      notes: this.notes,
                      status: this.status,
                      remarks: this.remarks,
                    };
                    this.httpSv
                      .updateAMEUServiceRequest(this.data.id, payloadData)
                      .subscribe(
                        (res: any) => {
                          if (res['status']) {
                            this.toster.success(
                              'Details have been successfully updated.',
                              '',
                              { timeOut: 2000 },
                            );
                            this.dialogRef.close(true);
                          } else {
                            this.toster.error(
                              'Oops! Something went wrong',
                              '',
                              { timeOut: 2000 },
                            );
                            this.close();
                          }
                        },
                        (err: any) => {
                          this.toster.error('Oops! Something went wrong', '', {
                            timeOut: 2000,
                          });
                          this.close();
                        },
                      );
                  } else {
                    this.toster.error('Please fill the state field ', '', {
                      timeOut: 2000,
                    });
                  }
                } else {
                  this.toster.error('Please fill the address field', '', {
                    timeOut: 2000,
                  });
                }
              } else {
                this.toster.error('Please select the status ', '', {
                  timeOut: 2000,
                });
              }
            } else {
              this.toster.error('Please select the company name', '', {
                timeOut: 2000,
              });
            }
          } else {
            this.toster.error('Please select the service request name ', '', {
              timeOut: 2000,
            });
          }
        } else {
          this.toster.error('Please select any license', '', { timeOut: 2000 });
        }
      } else {
        this.toster.error('Some technical issue with this license', '', {
          timeOut: 2000,
        });
      }
    } else if (this.data.type == 'ru') {
      let licenseNo = new FormData();
      licenseNo.append('fssai_regd_no', this.selectedLicenceNumber);
      if (this.selectedLicenceNumber != 'N/A') {
        if (this.selectedLicenceNumber != null) {
          if (this.serviceReqName != '') {
            if (this.companyName != '') {
              if (this.status != '') {
                if (this.address != '') {
                  if (this.state != '') {
                    const serviceId =
                      this.allTypeOfService[
                        this.allTypeOfService.findIndex(
                          (ele: any) => ele.name == this.serviceReqName,
                        )
                      ].id;
                    console.log(this.serviceReqName, this.allTypeOfService);
                    const payloadData = {
                      services_id: serviceId,
                      fssai_regd_no: this.selectedLicenceNumber,
                      business_name: this.companyName,
                      address: this.address,
                      state: this.state,
                      notes: this.notes,
                      status: this.status,
                      remarks: this.remarks,
                    };
                    this.httpSv
                      .updateAMRUServiceRequest(this.data.id, payloadData)
                      .subscribe(
                        (res: any) => {
                          if (res['status']) {
                            this.toster.success(
                              'Details have been successfully updated.',
                              '',
                              { timeOut: 2000 },
                            );
                            this.dialogRef.close(true);
                          } else {
                            this.toster.error(
                              'Oops! Something went wrong',
                              '',
                              { timeOut: 2000 },
                            );
                            this.close();
                          }
                        },
                        (err: any) => {
                          this.toster.error('Oops! Something went wrong', '', {
                            timeOut: 2000,
                          });
                          this.close();
                        },
                      );
                  } else {
                    this.toster.error('Please fill the state field ', '', {
                      timeOut: 2000,
                    });
                  }
                } else {
                  this.toster.error('Please fill the address field', '', {
                    timeOut: 2000,
                  });
                }
              } else {
                this.toster.error('Please select the status ', '', {
                  timeOut: 2000,
                });
              }
            } else {
              this.toster.error('Please select the company name', '', {
                timeOut: 2000,
              });
            }
          } else {
            this.toster.error('Please select the service request name ', '', {
              timeOut: 2000,
            });
          }
        } else {
          this.toster.error('Please select any license', '', { timeOut: 2000 });
        }
      } else {
        this.toster.error('Some technical issue with this license', '', {
          timeOut: 2000,
        });
      }
    }
  }

  close() {
    this.dialogRef.close(false);
  }
}

@Component({
  selector: 'app-user-modify-dialog',
  templateUrl: './dialogue.addDocument.component.html',
  styleUrls: ['./service-request.component.css'],
})
export class DialogAddDocumentSR implements OnInit {
  role: any;
  licenseData: any;
  documentName: any = '';
  fileData: any;
  fileName: any = '';
  docName: string = '';
  docDes: string = '';
  allTypeOfDocs: any = [
    {
      ref: 'application',
      value: 'Company Details',
    },
    {
      ref: 'calibration',
      value: 'Calibration',
    },
    {
      ref: 'health',
      value: 'Health',
    },
    {
      ref: 'testing',
      value: 'Testing',
    },
    {
      ref: 'training',
      value: 'Training',
    },
    {
      ref: 'others',
      value: 'Others',
    },
    {
      ref: 'audits',
      value: 'Audits',
    },
  ];
  productName: any = '';
  productId: any = '';
  testingDate: any;

  trainingName: any = '';
  trainingId: any = '';
  trainingDate: any;

  healthName: any = '';
  healthId: any = '';
  healthDate: any;

  calibirationName: any = '';
  calibirationId: any = '';
  calibirationDate: any;

  othersName: string = ''
  othersDes: string = ''
  othersDate: any
  selectedDoc:any;
  tcFlag:any =true
  isEdit:any = false
  employeeNameInput$ = new Subject<string>();
  employeeIdInput$ = new Subject<string>();

  productNameInput$ = new Subject<string>();
  productIdInput$ = new Subject<string>();
  productDocumentId:any = 0

  equipmentNameInput$ = new Subject<string>();
  equipmentIdInput$ = new Subject<string>();
  instrumentDocumentId:any = 0

  auditsName:any = ''
  auditsDes:any = ''
  auditsDate:any= ''
  score:any = ''
  allStatus:any= [ 
    {name:'Requested',value:'Requested'},
    {name:'Uploaded',value:'Uploaded'},
    {name:'Resubmit',value:'Resubmit'},
    {name:'Verified',value:'Verified'},
    {name:'Valid',value:'Valid'},
    {name:'Expired',value:'Expired'},
  ]
  selectedStatus:any = 'Uploaded'
  constructor(
    public dialogRef: MatDialogRef<DialogAddDocumentSR>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataService: DataService,
    public dialog: MatDialog,
    
  ) {
    this.employeeNameInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.employees.filter((employee:any) => 
        employee.employee_name.toLowerCase().includes(term.toLowerCase())
      ))
    ).subscribe();

    this.employeeIdInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.employees.filter((employee:any) => 
        employee.employee_id.toLowerCase().includes(term.toLowerCase())
      ))
    ).subscribe();

    this.productNameInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.products.filter((product:any) => 
        product.product_name.toLowerCase().includes(term.toLowerCase())
      ))
    ).subscribe();

    this.productIdInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.products.filter((product:any) => 
        product.product_id.toLowerCase().includes(term.toLowerCase())
      ))
    ).subscribe();

    this.equipmentNameInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.instruments.filter((instrument:any) => 
        instrument.equipment_name.toLowerCase().includes(term.toLowerCase())
      ))
    ).subscribe();

    this.equipmentIdInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.instruments.filter((instrument:any) => 
        instrument.instrument_id.toLowerCase().includes(term.toLowerCase())
      ))
    ).subscribe();
  }
  lic_no:any = 0
  ngOnInit(): void {
    console.log(this.data)
    const roleIdEncoded = localStorage.getItem('role')
    if (roleIdEncoded) {
      this.role = AES.decrypt(
        roleIdEncoded,
        this.dataService.secretKey,
      ).toString(enc.Utf8);
    } else {
      this.toster.error('Opps! something went wrong', '', { timeOut: 2000 });
    }
    const temp = this.data.data
    if(this.data.data.fromData){
      this.isEdit = true
      this.lic_no = this.data.data.fssai_regd_no
    }
    else{
      this.lic_no = this.data.data.licensesNo
    }
    console.log("Lic no",this.lic_no)
    switch (this.data.data.fromData) {
      case 'application':
        this.selectedDoc = 'application';
        this.docName = temp.document_name;
        this.docDes = temp.description;
        this.selectedStatus = temp.status;
        break;
      case 'testing':
        this.selectedDoc = 'testing'
        this.productName = temp.product_name
        this.productId = temp.product_id
        this.testingDate = temp.report_date
        this.selectedStatus = temp.status
        this.getAllProductDetails()
        break;
      case 'training':
        this.selectedDoc = 'training'
        this.trainingName = temp.employee_name
        this.trainingId = temp.employee_id
        this.trainingDate = temp.training_date
        this.selectedStatus = temp.status
        this.getAllEmployeeDetails();
        break;
      case 'health':
        this.selectedDoc = 'health'
        this.healthName = temp.employee_name
        this.healthId = temp.employee_id
        this.healthDate = temp.report_date
        this.selectedStatus = temp.status
        this.getAllEmployeeDetails();
        break;
      case 'calibration':
        this.selectedDoc = 'calibration'
        this.calibirationName = temp.instrument_name
        this.calibirationId = temp.equipment_id
        this.calibirationDate = temp.calibration_date
        this.selectedStatus = temp.status
        this.getAllEqpmentDetails()
        break;
      case 'others':
        this.selectedDoc = 'others';
        this.othersName = temp.document_name;
        this.othersDes = temp.description;
        this.othersDate = temp.expiration_date;
        this.selectedStatus = temp.status;
        break;
      case 'audits':
        this.selectedDoc = 'audits';
        this.auditsName = temp.audit_name;
        this.auditsDes = temp.audit_id;
        this.auditsDate = temp.audit_date;
        this.selectedStatus = temp.status;
        this.score = temp.score;
        break;
    }
  }
  products: any = []

  employees: any = [];

  instruments: any = [];

  employeeDocumentId:any = 0;
  onchangeDoc(data:any){
    console.log("changed",data)
    switch(data){
      case 'testing':
        this.getAllProductDetails();
        break;
      case 'training':
        this.getAllEmployeeDetails();
        break;
      case 'health':
        this.getAllEmployeeDetails();
        break;
      case 'calibration':
        this.getAllEqpmentDetails();
        break;
      
    }
  }
  onEmployeeNameChange(selectedName: string) {
    if(selectedName == null){
      this.trainingId = null;
      this.healthId = null;
      return;
    }
    const selectedEmployee = this.employees.find((employee:any) => employee.employee_name === selectedName);
    if (selectedEmployee) {
      this.trainingId = selectedEmployee.employee_id;  // Automatically set the corresponding employee ID
      this.healthId = selectedEmployee.employee_id;  // Automatically set the corresponding employee ID
      this.employeeDocumentId = selectedEmployee.id
    }
  }
  
  // Function triggered when the employee ID is changed
  onEmployeeIdChange(selectedId: string) {
    if(selectedId == null){
      this.trainingName = null;
      this.healthName = null;
      return;
    }
    const selectedEmployee = this.employees.find((employee:any) => employee.employee_id === selectedId);
    if (selectedEmployee) {
      this.trainingName = selectedEmployee.employee_name;  // Automatically set the corresponding employee name
      this.healthName = selectedEmployee.employee_name;  // Automatically set the corresponding employee name
      this.employeeDocumentId = selectedEmployee.id
    }
  }

  onProductNameChange(selectedName: string) {
    if(selectedName == null){
      this.productId = null;
      return;
    }
    const selectedProduct = this.products.find((product:any) => product.product_name === selectedName);
    if (selectedProduct) {
      this.productId = selectedProduct.product_id;  // Automatically set the corresponding employee ID
      this.productDocumentId = selectedProduct.id;  
    }
  }
  
  // Function triggered when the employee ID is changed
  onProductIdChange(selectedId: string) {
    if(selectedId == null){
      this.productName = null;
      return;
    }
    const selectedProduct = this.products.find((product:any) => product.product_id === selectedId);
    if (selectedProduct) {
      this.productName = selectedProduct.product_name;  // Automatically set the corresponding employee name
      this.productDocumentId = selectedProduct.id;
    }
  }

  onEquipmentNameChange(selectedName: string) {
    if(selectedName == null){
      this.calibirationId = null;
      return;
    }
    const selectedInstrument = this.instruments.find((instrument:any) => instrument.equipment_name === selectedName);
    if (selectedInstrument) {
      this.calibirationId = selectedInstrument.instrument_id;  // Automatically set the corresponding employee ID
      this.instrumentDocumentId = selectedInstrument.id;
    }
  }
  
  // Function triggered when the employee ID is changed
  onEquipmentIdChange(selectedId: string) {
    if(selectedId == null){
      this.calibirationName = null;
      return;
    }
    const selectedInstrument = this.instruments.find((instrument:any) => instrument.instrument_id === selectedId);
    if (selectedInstrument) {
      this.calibirationName = selectedInstrument.equipment_name;  // Automatically set the corresponding employee name
      this.instrumentDocumentId = selectedInstrument.id;

    }
  }

  getAllEmployeeDetails(){
    this.httpSv.getAllEmployeeDetails(this.lic_no)?.subscribe((res: any) => {
      if (res['status']) {
        this.employees = res['results'];
        console.log(this.employees)
        if(this.data.type && (this.trainingId || this.healthId))
          this.employeeDocumentId = this.employees.filter((employee:any) => (employee.employee_id == this.trainingId) || (employee.employee_id == this.healthId) )[0].id
      }
      else {
        // this.toster.error('Details not updated.', '', { timeOut: 2000 })
      }
    }, ((err: any) => {
      // this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
    }))
  }
  getAllProductDetails(){
    this.httpSv.getAllProductDetails( this.lic_no)?.subscribe((res: any) => {
      if (res['status']) {
        this.products = res['results'];
        if(this.data.type)
          this.productDocumentId = this.products.filter((product:any) => product.product_id == this.productId )[0].id
      }
      else {
        // this.toster.error('Details not updated.', '', { timeOut: 2000 })
      }
    }, ((err: any) => {
      // this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
    }))
  }
  getAllEqpmentDetails(){
    this.httpSv.getAllEquipmentDetails( this.lic_no)?.subscribe((res: any) => {
      if (res['status']) {
        this.instruments = res['results'];
        if(this.data.type)
          this.instrumentDocumentId = this.instruments.filter((instrument:any) => instrument.instrument_id == this.calibirationId )[0].id
      }
      else {
        // this.toster.error('Details not updated.', '', { timeOut: 2000 })
      }
    }, ((err: any) => {
      // this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
    }))
  }
  onImageChange(event: any) {
    const fsize = event.target.files[0].size;
    const fileSize = Math.round(fsize / 1024);
    const name = event.target.files[0].name;
    console.log(fileSize);
    if (fileSize > 10000) {
      this.toster.error('File Size should be less than 10Mb', '', {
        timeOut: 2000,
      });
      return;
    }
    let file: File = event.target.files[0];
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.fileData = file;
    reader.onload = (e: any) => {
      // this.src = e.target.result;
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        this.fileName = target.files[0].name;
      }
    };
    const fileDom = document.getElementById('file_inp') as HTMLInputElement;
    if (fileDom) {
      fileDom.value = '';
    }
  }
  close() {
    this.dialogRef.close(false);
  }
  save() {
    if (this.selectedDoc == 'application') this.addApplicationDtls();
    else if (this.selectedDoc == 'testing') this.addTestingDtls();
    else if (this.selectedDoc == 'training') this.addTrainingDtls();
    else if (this.selectedDoc == 'health') this.addHealthDtls();
    else if (this.selectedDoc == 'calibration') this.addCalibrationDtls();
    else if (this.selectedDoc == 'others') this.addOthersDtls();
    else if (this.selectedDoc == 'audits') this.addAuditsDtls();
  }
  addApplicationDtls() {
    const data = new FormData();
    let id: any = localStorage.getItem('user_id');
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    if (this.docName && this.docName != '') {
      if (this.docDes && this.docDes != '') {
        if (this.fileData && this.fileData != '') {
          if (this.tcFlag) {
            data.append('document_name', this.docName);
            data.append('description', this.docDes);
            data.append('documents', this.fileData);
            data.append(
              'fssai_regd_no',
              this.data.licensesNo
                ? this.data.licensesNo
                : this.data.data.licensesNo,
            );
            data.append('user_id', this.data.userId ? this.data.userId : id);
            data.append('service_requests_id', this.data.data.id);
            data.append('status', this.selectedStatus);
            this.httpSv
              .addLicenseApplicationDtls(this.role, data, this.data.data.type)
              ?.subscribe(
                (res: any) => {
                  if (res['status']) {
                    this.toster.success(
                      'Details have been successfully updated.',
                      '',
                      { timeOut: 2000 },
                    );
                    this.dialogRef.close(true);
                  } else {
                    data;
                    this.toster.error('Details not updated.', '', {
                      timeOut: 2000,
                    });
                    this.dialogRef.close(false);
                  }
                },
                (err: any) => {
                  this.toster.error('Oops! something went wrong', '', {
                    timeOut: 2000,
                  });
                  this.dialogRef.close(false);
                },
              );
          } else {
            this.toster.error(
              'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
              '',
              { timeOut: 2000 },
            );
          }
        } else {
          this.toster.error('Please upload the document', '', {
            timeOut: 2000,
          });
        }
      } else {
        this.toster.error('Please enter the document description', '', {
          timeOut: 2000,
        });
      }
    } else {
      this.toster.error('Please enter the document name', '', {
        timeOut: 2000,
      });
    }
  }

  addTestingDtls() {
    const data = new FormData();
    let id: any = localStorage.getItem('user_id');
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    // data.append('user_id', id)
    if (this.productName && this.productName != '') {
      if (this.productId && this.productId != '') {
        if (this.testingDate && this.testingDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('product_name', this.productName)
              data.append('product_id', this.productId)
              data.append('product_documents_id', this.productDocumentId)
              data.append('report_date', this.testingDate)
              data.append('documents', this.fileData)
              data.append('fssai_regd_no', this.data.licensesNo ? this.data.licensesNo : this.data.data.licensesNo)
              data.append('user_id', this.data.userId ? this.data.userId : id)
              data.append('service_requests_id', this.data.data.id)
              data.append('status', this.selectedStatus)
              this.httpSv.addLicenseTestingDtls(this.role, data, this.data.data.type)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
          } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please enter the date', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the product id', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the product name', '', { timeOut: 2000 }) }
  }

  addTrainingDtls() {
    const data = new FormData();
    let id: any = localStorage.getItem('user_id');
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    // data.append('user_id', id)
    if (this.trainingName && this.trainingName != '') {
      if (this.trainingId && this.trainingId != '') {
        if (this.trainingDate && this.trainingDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('employee_name', this.trainingName)
              data.append('employee_id', this.trainingId)
              data.append('training_date', this.trainingDate)
              data.append('documents', this.fileData)
              data.append('employee_documents_id', this.employeeDocumentId)
              data.append('fssai_regd_no', this.data.licensesNo ? this.data.licensesNo : this.data.data.licensesNo)
              data.append('user_id', this.data.userId ? this.data.userId : id)
              data.append('service_requests_id', this.data.data.id)
              data.append('status', this.selectedStatus)
              this.httpSv.addLicenseTrainingDtls(this.role, data, this.data.data.type)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
          } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please enter the training date', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the employee id', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the employee name', '', { timeOut: 2000 }) }
  }

  addHealthDtls() {
    const data = new FormData();
    let id: any = localStorage.getItem('user_id');
    // data.append('user_id', id)
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    if (this.healthName && this.healthName != '') {
      if (this.healthId && this.healthId != '') {
        if (this.healthDate && this.healthDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('employee_name', this.healthName)
              data.append('employee_id', this.healthId)
              data.append('report_date', this.healthDate)
              data.append('employee_documents_id', this.employeeDocumentId)
              data.append('documents', this.fileData)
              data.append('fssai_regd_no', this.data.licensesNo ? this.data.licensesNo : this.data.data.licensesNo)
              data.append('user_id', this.data.userId ? this.data.userId : id)
              data.append('service_requests_id', this.data.data.id)
              data.append('status', this.selectedStatus)
              this.httpSv.addLicenseHealthDtls(this.role, data, this.data.data.type)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
          } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please enter the report date', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the employee id', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the employee name', '', { timeOut: 2000 }) }
  }

  addCalibrationDtls() {
    const data = new FormData();
    let id: any = localStorage.getItem('user_id');
    // data.append('user_id', id)
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    if (this.calibirationName && this.calibirationName != '') {
      if (this.calibirationId && this.calibirationId != '') {
        if (this.calibirationDate && this.calibirationDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('instrument_name', this.calibirationName)
              data.append('equipment_id', this.calibirationId)
              data.append('calibration_date', this.calibirationDate)
              data.append('equipment_documents_id', this.instrumentDocumentId)
              data.append('documents', this.fileData)
              data.append('fssai_regd_no', this.data.licensesNo ? this.data.licensesNo : this.data.data.licensesNo)
              data.append('user_id', this.data.userId ? this.data.userId : id)
              data.append('service_requests_id', this.data.data.id)
              data.append('status', this.selectedStatus)
              this.httpSv.addLicenseCalibrationDtls(this.role, data, this.data.data.type)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else { this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 }) }
          } else { this.toster.error('Please upload the document', '', { timeOut: 2000 }) }
        } else { this.toster.error('Please enter the callibration date', '', { timeOut: 2000 }) }
      } else { this.toster.error('Please enter the instrument id', '', { timeOut: 2000 }) }
    } else { this.toster.error('Please enter the instrument name', '', { timeOut: 2000 }) }
  }
  
  addOthersDtls(){
    const data = new FormData()
    let id:any = localStorage.getItem('user_id')
    // data.append('user_id', id)
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    if (this.othersName && this.othersName != '') {
      if (this.othersDes && this.othersDes != '') {
        if (this.othersDate && this.othersDate != '') {
          if (this.fileData && this.fileData != '') {
            if (this.tcFlag) {
              data.append('document_name', this.othersName);
              data.append('description', this.othersDes);
              data.append('expiration_date', this.othersDate);
              data.append('documents', this.fileData);
              data.append(
                'fssai_regd_no',
                this.data.licensesNo
                  ? this.data.licensesNo
                  : this.data.data.licensesNo,
              );
              data.append('user_id', this.data.userId ? this.data.userId : id);
              data.append('service_requests_id', this.data.data.id);
              data.append('status', this.selectedStatus);
              this.httpSv
                .addLicenseOthersDtls(this.role, data, this.data.data.type)
                ?.subscribe(
                  (res: any) => {
                    if (res['status']) {
                      this.toster.success(
                        'Details have been successfully updated.',
                        '',
                        { timeOut: 2000 },
                      );
                      this.dialogRef.close(true);
                    } else {
                      this.toster.error('Details not updated.', '', {
                        timeOut: 2000,
                      });
                      this.dialogRef.close(false);
                    }
                  },
                  (err: any) => {
                    this.toster.error('Oops! something went wrong', '', {
                      timeOut: 2000,
                    });
                    this.dialogRef.close(false);
                  },
                );
            } else {
              this.toster.error(
                'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
                '',
                { timeOut: 2000 },
              );
            }
          } else {
            this.toster.error('Please upload the document', '', {
              timeOut: 2000,
            });
          }
        } else {
          this.toster.error('Please enter the expiration date', '', {
            timeOut: 2000,
          });
        }
      } else {
        this.toster.error('Please enter the description', '', {
          timeOut: 2000,
        });
      }
    } else {
      this.toster.error('Please enter the document name', '', {
        timeOut: 2000,
      });
    }
  }
  addAuditsDtls() {
    const data = new FormData();
    if (this.fileData && this.fileData != '') {
      if (this.selectedStatus.length <= 0) {
        this.toster.error('Please select the Status', '', { timeOut: 2000 });
        return;
      }
      if (!this.auditsDate || this.auditsDate.length <= 0) {
        this.toster.error('Please select the Audit Date', '', {
          timeOut: 2000,
        });
        return;
      }
      let id: any = localStorage.getItem('user_id');
      // data.append('user_id', id)
      if (this.auditsName && this.auditsName != '') {
        if (this.auditsDes && this.auditsDes != '') {
          if (this.score && this.score != '') {
            if (this.score < 0 || this.score > 1000) {
              this.toster.error(
                'Please enter the Score between 0 to 1000',
                '',
                { timeOut: 2000 },
              );
              return;
            }
            if (this.tcFlag) {
              data.append('audit_name', this.auditsName);
              data.append('audit_id', this.auditsDes);
              data.append('audit_date', this.auditsDate);
              data.append('status', this.selectedStatus);
              data.append('score', this.score);
              data.append(
                'fssai_regd_no',
                this.data.licensesNo
                  ? this.data.licensesNo
                  : this.data.data.licensesNo,
              );
              data.append('user_id', this.data.userId ? this.data.userId : id);
              data.append('documents', this.fileData);
              data.append('service_requests_id', this.data.data.id);
              this.httpSv
                .addLicenseAuditDtls(this.role, data, this.data.userType)
                ?.subscribe(
                  (res: any) => {
                    if (res['status']) {
                      this.toster.success(
                        'Details have been successfully updated.',
                        '',
                        { timeOut: 2000 },
                      );
                      this.dialogRef.close(true);
                    } else {
                      data;
                      this.toster.error('Details not updated.', '', {
                        timeOut: 2000,
                      });
                      this.dialogRef.close(false);
                    }
                  },
                  (err: any) => {
                    this.toster.error('Oops! something went wrong', '', {
                      timeOut: 2000,
                    });
                    this.dialogRef.close(false);
                  },
                );
            } else {
              this.toster.error(
                'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
                '',
                { timeOut: 2000 },
              );
            }
          } else {
            this.toster.error('Please enter the Score', '', { timeOut: 2000 });
          }
        } else {
          this.toster.error('Please enter the Audit ID', '', { timeOut: 2000 });
        }
      } else {
        this.toster.error('Please enter the Audit name', '', { timeOut: 2000 });
      }
    } else {
      this.toster.error('Please upload the document', '', { timeOut: 2000 });
    }
  }
  update() {
    if (this.selectedDoc == 'application') this.updateApplicationDtls();
    else if (this.selectedDoc == 'testing') this.updateTestingDtls();
    else if (this.selectedDoc == 'training') this.updateTrainingDtls();
    else if (this.selectedDoc == 'health') this.updateHealthDtls();
    else if (this.selectedDoc == 'calibration') this.updateCalibirationDtls();
    else if (this.selectedDoc == 'others') this.updateOthersDtls();
    else if (this.selectedDoc == 'audits') this.updateAuditsDtls();
  }

  updateApplicationDtls() {
    const data = new FormData();
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    if (this.docName && this.docName != '') {
      data.append('document_name', this.docName);
      if (this.docDes && this.docDes != '') {
        data.append('description', this.docDes);
        if (this.fileData && this.fileData != '') {
          data.append('documents', this.fileData);
        }
        if (this.tcFlag) {
          data.append(
            'fssai_regd_no',
            this.data.licensesNo
              ? this.data.licensesNo
              : this.data.data.fssai_regd_no,
          );
          data.append('user_id', this.data.data.user_id);
          data.append('service_requests_id', this.data.data.id);
          data.append('status', this.selectedStatus);

          this.httpSv
            .updateLicienceApplicationDtls(
              this.data.data.id,
              data,
              this.role,
              this.data.type.type,
            )
            ?.subscribe(
              (res: any) => {
                if (res['status']) {
                  this.toster.success(
                    'Details have been successfully updated.',
                    '',
                    { timeOut: 2000 },
                  );
                  this.dialogRef.close(true);
                } else {
                  this.toster.error('Details not updated.', '', {
                    timeOut: 2000,
                  });
                  this.dialogRef.close(false);
                }
              },
              (err: any) => {
                this.toster.error('Oops! something went wrong', '', {
                  timeOut: 2000,
                });
                this.dialogRef.close(false);
              },
            );
        } else {
          this.toster.error(
            'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
            '',
            { timeOut: 2000 },
          );
        }
        // } else {
        //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
        // }
      } else {
        this.toster.error('Please enter the document description', '', {
          timeOut: 2000,
        });
      }
    } else {
      this.toster.error('Please enter the document name', '', {
        timeOut: 2000,
      });
    }
  }
  updateTestingDtls() {
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    const data = new FormData();
    if (this.productName && this.productName != '') {
      if (this.productId && this.productId != '') {
        if (this.testingDate && this.testingDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData)
            }            
            if (this.tcFlag) {
              data.append('product_name', this.productName)
              data.append('product_id', this.productId)
              data.append('product_documents_id', this.productDocumentId)
              data.append('report_date', this.testingDate)
              data.append('fssai_regd_no', this.data.licensesNo ? this.data.licensesNo : this.data.data.fssai_regd_no)
              data.append('user_id', this.data.data.user_id)
              data.append('service_requests_id', this.data.data.id)
              data.append('status', this.selectedStatus)
              this.httpSv.updateLicienceTestingDtls(this.data.data.id, data,this.role, this.data.type.type)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else {
              this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
            }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the date', '', { timeOut: 2000 });
        }
      } else {
        this.toster.error('Please enter the product id', '', { timeOut: 2000 });
      }
    } else {
      this.toster.error('Please enter the product name', '', { timeOut: 2000 });
    }
  }

  updateTrainingDtls() {
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    const data = new FormData();
    if (this.trainingName && this.trainingName != '') {
      if (this.trainingId && this.trainingId != '') {
        if (this.trainingDate && this.trainingDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData)
            }
            if (this.tcFlag) {
              data.append('employee_name', this.trainingName)
              data.append('employee_id', this.trainingId)
              data.append('training_date', this.trainingDate)
              data.append('employee_documents_id', this.employeeDocumentId)
              data.append('service_requests_id', this.data.data.id)
              data.append('fssai_regd_no', this.data.licensesNo ? this.data.licensesNo : this.data.data.fssai_regd_no)
              data.append('user_id', this.data.data.user_id)
              data.append('status', this.selectedStatus)
              this.httpSv.updateLicienceTrainingDtls(this.data.data.id, data,this.role, this.data.type.type)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else {
              this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
            }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the training date', '', {
            timeOut: 2000,
          });
        }
      } else {
        this.toster.error('Please enter the employee id', '', {
          timeOut: 2000,
        });
      }
    } else {
      this.toster.error('Please enter the employee name', '', {
        timeOut: 2000,
      });
    }
  }
  updateHealthDtls() {
    console.log('aaaaaaaaaa');
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    const data = new FormData();
    if (this.healthName && this.healthName != '') {
      console.log('111');

      if (this.healthId && this.healthId != '') {
        console.log('222');

        if (this.healthDate && this.healthDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData)
            }
            if (this.tcFlag) {
              data.append('employee_name', this.healthName)
              data.append('employee_id', this.healthId)
              data.append('report_date', this.healthDate)
              data.append('employee_documents_id', this.employeeDocumentId)
              data.append('service_requests_id', this.data.data.id)
              data.append('fssai_regd_no', this.data.licensesNo ? this.data.licensesNo : this.data.data.fssai_regd_no)
              data.append('user_id', this.data.data.user_id)
              data.append('status', this.selectedStatus)
              this.httpSv.updateLicienceHealthDtls(this.data.data.id, data,this.role, this.data.type.type)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else {
              this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
            }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the report date', '', {
            timeOut: 2000,
          });
        }
      } else {
        this.toster.error('Please enter the employee id', '', {
          timeOut: 2000,
        });
      }
    } else {
      this.toster.error('Please enter the employee name', '', {
        timeOut: 2000,
      });
    }
  }
  updateCalibirationDtls() {
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    const data = new FormData();
    if (this.calibirationName && this.calibirationName != '') {
      if (this.calibirationId && this.calibirationId != '') {
        if (this.calibirationDate && this.calibirationDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData)
            }
            if (this.tcFlag) {
              data.append('instrument_name', this.calibirationName)
              data.append('equipment_id', this.calibirationId)
              data.append('calibration_date', this.calibirationDate)
              data.append('equipment_documents_id', this.instrumentDocumentId)
              data.append('service_requests_id', this.data.data.id)
              data.append('fssai_regd_no', this.data.licensesNo ? this.data.licensesNo : this.data.data.fssai_regd_no)
              data.append('user_id', this.data.data.user_id)
              data.append('status', this.selectedStatus)
              this.httpSv.updateLicienceCalibirationDtls(this.data.data.id, data,this.role, this.data.type.type)?.subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Details have been successfully updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(true)
                }
                else {
                  this.toster.error('Details not updated.', '', { timeOut: 2000 })
                  this.dialogRef.close(false)
                }
              }, ((err: any) => {
                this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
                this.dialogRef.close(false)
              }))
            } else {
              this.toster.error('Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy', '', { timeOut: 2000 })
            }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the callibration date', '', {
            timeOut: 2000,
          });
        }
      } else {
        this.toster.error('Please enter the instrument id', '', {
          timeOut: 2000,
        });
      }
    } else {
      this.toster.error('Please enter the instrument name', '', {
        timeOut: 2000,
      });
    }
  }
  updateOthersDtls() {
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    const data = new FormData();
    if (this.othersName && this.othersName != '') {
      if (this.othersDes && this.othersDes != '') {
        if (this.othersDate && this.othersDate != '') {
          if (this.fileData && this.fileData != '') {
            data.append('documents', this.fileData);
          }
          if (this.tcFlag) {
            data.append('document_name', this.othersName);
            data.append('description', this.othersDes);
            data.append('expiration_date', this.othersDate);
            data.append('service_requests_id', this.data.data.id);
            data.append(
              'fssai_regd_no',
              this.data.licensesNo
                ? this.data.licensesNo
                : this.data.data.fssai_regd_no,
            );
            data.append('user_id', this.data.data.user_id);
            data.append('status', this.selectedStatus);
            this.httpSv
              .updateLicienceOthersDtls(
                this.data.data.id,
                data,
                this.role,
                this.data.type.type,
              )
              ?.subscribe(
                (res: any) => {
                  if (res['status']) {
                    this.toster.success(
                      'Details have been successfully updated.',
                      '',
                      { timeOut: 2000 },
                    );
                    this.dialogRef.close(true);
                  } else {
                    this.toster.error('Details not updated.', '', {
                      timeOut: 2000,
                    });
                    this.dialogRef.close(false);
                  }
                },
                (err: any) => {
                  this.toster.error('Oops! something went wrong', '', {
                    timeOut: 2000,
                  });
                  this.dialogRef.close(false);
                },
              );
          } else {
            this.toster.error(
              'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
              '',
              { timeOut: 2000 },
            );
          }
          // } else {
          //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
          // }
        } else {
          this.toster.error('Please enter the expiration date', '', {
            timeOut: 2000,
          });
        }
      } else {
        this.toster.error('Please enter the description', '', {
          timeOut: 2000,
        });
      }
    } else {
      this.toster.error('Please enter the document name', '', {
        timeOut: 2000,
      });
    }
  }
  updateAuditsDtls() {
    const data = new FormData();
    if (!this.auditsDate || this.auditsDate.length <= 0) {
      this.toster.error('Please select the Audit Date', '', { timeOut: 2000 });
      return;
    }
    if (this.score && this.score != '') {
      if (this.score < 0 || this.score > 1000) {
        this.toster.error('Please enter the Score between 0 to 1000', '', {
          timeOut: 2000,
        });
        return;
      }
    } else {
      this.toster.error('Please select the Score', '', { timeOut: 2000 });
      return;
    }
    if (this.selectedStatus.length <= 0) {
      this.toster.error('Please select the Status', '', { timeOut: 2000 });
      return;
    }
    data.append('status', this.selectedStatus);
    // data.append('audit_date',this.auditsDate)
    data.append('score', this.score);
    if (this.auditsName && this.auditsName != '') {
      data.append('audit_name', this.auditsName);
      if (this.auditsDes && this.auditsDes != '') {
        data.append('audit_id', this.auditsDes);
        if (this.auditsDate && this.auditsDate != '') {
          data.append('date', this.auditsDate);
        } else {
          this.toster.error('Please enter the Audit Date', '', {
            timeOut: 2000,
          });
          return;
        }
        if (this.tcFlag) {
          data.append(
            'fssai_regd_no',
            this.data.licensesNo
              ? this.data.licensesNo
              : this.data.data.fssai_regd_no,
          );
          data.append('user_id', this.data.data.user_id);
          if (this.fileData) data.append('documents', this.fileData);
          data.append('service_requests_id', this.data.data.id);

          this.httpSv
            .updateLicienceAuditDtls(
              this.data.data.id,
              data,
              this.role,
              this.data.userType,
            )
            ?.subscribe(
              (res: any) => {
                if (res['status']) {
                  this.toster.success(
                    'Details have been successfully updated.',
                    '',
                    { timeOut: 2000 },
                  );
                  this.dialogRef.close(true);
                } else {
                  this.toster.error('Details not updated.', '', {
                    timeOut: 2000,
                  });
                  this.dialogRef.close(false);
                }
              },
              (err: any) => {
                this.toster.error('Oops! something went wrong', '', {
                  timeOut: 2000,
                });
                this.dialogRef.close(false);
              },
            );
        } else {
          this.toster.error(
            'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy',
            '',
            { timeOut: 2000 },
          );
        }
        // } else {
        //   this.toster.error('Please upload the document', '', { timeOut: 2000 })
        // }
      } else {
        this.toster.error('Please enter the Audit ID', '', { timeOut: 2000 });
      }
    } else {
      this.toster.error('Please enter the Audit name', '', { timeOut: 2000 });
    }
  }
}

@Component({
  selector: 'app-user-modify-dialog',
  templateUrl: './dialogue.addRemarks.component.html',
  styleUrls: ['./service-request.component.css'],
})
export class DialogueAddRemark implements OnInit {
  remarks: any;
  constructor(
    public dialogRef: MatDialogRef<DialogAddDocumentSR>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataService: DataService,
    public dialog: MatDialog,
  ) {}
  ngOnInit(): void {}
  close() {
    this.dialogRef.close({ status: false });
  }
  save() {
    if (!this.remarks || this.remarks.length == 0) {
      this.toster.error('Please enter Remarks', '', { timeOut: 2000 });
      return;
    }
    if (this.remarks.length > 30) {
      this.dialogRef.close({ status: true, data: this.remarks });
    } else {
      this.toster.error('Please enter atleast 30 Characters', '', {
        timeOut: 2000,
      });
    }
  }
}

@Component({
  selector: 'app-user-modify-dialog',
  templateUrl: './dialogue.auditReport.component.html',
  styleUrls: ['./service-request.component.css'],
})
export class DialogueAuditReport implements OnInit {
  remarks: any;
  constructor(
    public dialogRef: MatDialogRef<DialogAddDocumentSR>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataService: DataService,
    public dialog: MatDialog,
  ) {}
  id: any;
  auditData: any;
  title: any = 'Service Request';
  ngOnInit(): void {
    console.log(this.data);
    if (this.data.from == 'license') {
      this.auditData = this.data.data;
      this.title = this.data.type.toUpperCase();
    } else {
      this.id = this.data;
      this.getAuditReport();
    }
  }
  getAuditReport() {
    this.httpSv.getAuditReport(this.id).subscribe(
      (res: any) => {
        if (res['status']) {
          this.auditData = res['results'];
        } else {
          this.toster.error('Something Went Wrong! Please try again', '', {
            timeOut: 2000,
          });
          this.dialogRef.close(false);
        }
      },
      (err: any) => {
        this.toster.error('Oops! something went wrong', '', { timeOut: 2000 });
        this.dialogRef.close(false);
      },
    );
  }
  getDate(data: any) {
    const date = new Date(data);
    // Define arrays for month and weekday names
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Format the date manually
    const weekday = weekdays[date.getUTCDay()];
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const utcDate = new Date(date);

    // Convert to IST (UTC + 5:30)
    const istDate = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000);

    // Get hours and minutes
    let hours = istDate.getUTCHours();
    let minutes: any = istDate.getUTCMinutes();

    // Format to AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    // Format minutes to 2 digits
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${weekday}, ${month}, ${day} ${year} at ${hours}:${minutes} ${ampm}`;
  }
  close() {
    this.dialogRef.close();
  }
}
