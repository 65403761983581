<div class="service_modify_main_div">
  <div
    class="service_modify_header"
    [ngClass]="roleId == '3'?'Bm_edit_option':null"
  >
    <span>Modify Service Request Details</span>
    <div
      class="ces_icons_header"
      style="display: flex; justify-content: space-between"
      *ngIf="roleId==3 || roleId==1 || roleId==5 || roleId==2"
    >
      <!-- <img
            src="../../../assets/img/reset_icon.svg" alt="" srcset=""
            style="cursor: pointer;" (click)="handleBmReset()" >  -->
      <!-- <img *ngIf="!isBlock" src="../../../assets/img/remove_icon.svg" alt="" style="cursor: pointer;"
                (click)="handleSAServiceRequestDelete()"> -->
      <img
        src="../../../assets/img/close.svg"
        alt=""
        style="cursor: pointer"
        (click)="close()"
      />
    </div>
  </div>
  <div class="license_modify_content_main_div">
    <div class="license_modify_sub_header">
      <div
        [ngClass]="selectedTab == 'sr'?'license_modify_sub_header_active':''"
        (click)="handleTabChange('sr')"
      >
        SERVICE REQUEST DETAILS
      </div>
      <div
        [ngClass]="selectedTab == 'docs'?'license_modify_sub_header_active':''"
        (click)="handleTabChange('docs')"
      >
        RELATED DOCUMENTS
      </div>
    </div>
  </div>
  <div class="service_modify_content_main_div" *ngIf="selectedTab == 'sr'">
    <!-- <div class="service_modify_sub_header" *ngIf="roleId!=3">SERVICE REQUEST </div> -->
    <div class="service_modify_inp_main_div">
      <!-- ------------------------------------------ SERVICE REQUEST  --------------------------------------------------- -->

      <div class="service_modify_pd_main_div">
        <div class="service_modify_pd_inp_main_div">
          <div class="service_modify_pd_inp_sub_div">
            <label for="name">Service Request Name</label>
            <div *ngIf="isBlock">
              <input
                type="text"
                style="cursor: pointer"
                [(ngModel)]="serviceReqName"
                [disabled]="true"
                [ngClass]="isBlock?'input_disable':''"
              />
            </div>
            <div *ngIf="!isBlock">
              <ng-select
                class="multi-select"
                [items]="allTypeOfService"
                bindLabel="name"
                bindValue="name"
                [multiple]="false"
                groupBy="selectedAllGroup"
                [selectableGroup]="true"
                name="typeOfLicence"
                placeholder="Select Servive Name"
                (change)="onMaterialGroupChange($event)"
                [(ngModel)]="serviceReqName"
              >
                <ng-template
                  ng-optgroup-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                  [ngClass]="isBlock?'input_disable':null"
                >
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="service_modify_pd_inp_sub_div">
            <label for="email">License Number</label>
            <!-- <input type="email"> -->
            <!-- <ng-select  class=""class="multi-select disabled" style="cursor: pointer;" [items]="allLicenceNumber" bindLabel="name" bindValue="name"
                            [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true" name="typeOfLicence"
                            placeholder="Select License Number" (change)="onMaterialGroupChange($event)"
                            [(ngModel)]="selectedLicenceNumber" [disabled]="isBlock?true:false"
                            [ngClass]="isBlock==true?'input_disable':null">
                        
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index"
                                [ngClass]="isBlock?'input_disable':null">

                            </ng-template>
                        </ng-select> -->
            <input
              type="text"
              [(ngModel)]="selectedLicenceNumber"
              [disabled]="isBlock?true:false"
              class="disabled"
              [ngClass]="isBlock?'input_disable':''"
            />
          </div>
          <div class="service_modify_pd_inp_sub_div">
            <label for="comp_name">Company Name</label>
            <input
              type="text"
              [(ngModel)]="companyName"
              [disabled]="isBlock?true:false"
              class="disabled"
              [ngClass]="isBlock?'input_disable':''"
            />
          </div>
          <div class="service_modify_pd_inp_sub_div">
            <label for="status">Status</label>
            <div class="dropdown">
              <button
                class="btn dropdown-toggle updateSelect"
                [disabled]="roleId == 5 ? true : false"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="width: 100%; text-align: left; min-height: 48px"
              >
                <span *ngIf="status == ''">&nbsp;</span>
                <span *ngIf="status != ''"
                  >{{status.split('_').join(' ')}}</span
                >
              </button>
              <ul
                class="dropdown-menu dropDownServiceReq"
                style="width: 100%; max-height: 10rem; overflow: auto"
              >
                <li
                  class="dropdown-item"
                  *ngIf="roleId != '3' && roleId!='2'"
                  [ngClass]="selectedFilter.active?'filterActiveCss':''"
                  (click)="handleStatusChange('Submitted')"
                >
                  Submitted
                </li>
                <li
                  class="dropdown-item"
                  *ngIf="roleId != '3'"
                  [ngClass]="selectedFilter.expired?'filterActiveCss':''"
                  (click)="handleStatusChange('Accepted')"
                >
                  Accepted
                </li>
                <li
                  class="dropdown-item"
                  *ngIf="roleId != '2'"
                  [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                  (click)="handleStatusChange('Approved')"
                >
                  Approved
                </li>
                <li
                  class="dropdown-item"
                  *ngIf="roleId != '3'"
                  [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                  (click)="handleStatusChange('Pending_Payment')"
                >
                  Pending Payment
                </li>
                <li
                  class="dropdown-item"
                  *ngIf="roleId != '3' && roleId != '2'"
                  [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                  (click)="handleStatusChange('In_Progress')"
                >
                  In Progress
                </li>
                <li
                  class="dropdown-item"
                  *ngIf="roleId != '3' && roleId != '2'"
                  [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                  (click)="handleStatusChange('Pending_Documentation')"
                >
                  Pending Documentation
                </li>
                <li
                  class="dropdown-item"
                  *ngIf="roleId != '3' && roleId != '2'"
                  [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                  (click)="handleStatusChange('Completed')"
                >
                  Completed
                </li>
                <li
                  class="dropdown-item"
                  *ngIf="roleId != '2'"
                  [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                  (click)="handleStatusChange('Rejected')"
                >
                  Rejected
                </li>
                <li
                  class="dropdown-item"
                  *ngIf="roleId != '3' && roleId != '2'"
                  [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                  (click)="handleStatusChange('Cancelled')"
                >
                  Cancelled
                </li>
              </ul>
            </div>
          </div>
          <div class="service_modify_pd_inp_sub_div" *ngIf="roleId!=3">
            <label for="address">City</label>
            <input
              type="text"
              [(ngModel)]="address"
              [disabled]="isBlock?true:false"
              class="disabled"
              [ngClass]="isBlock?'input_disable':''"
            />
          </div>
          <div class="service_modify_pd_inp_sub_div" *ngIf="roleId==3">
            <label for="address">City</label>
            <input
              type="text"
              [(ngModel)]="address"
              [disabled]="isBlock?true:false"
              class="disabled"
              [ngClass]="isBlock?'input_disable':''"
            />
          </div>
          <!-- <div class="service_modify_pd_inp_sub_div">
                        <label for="state">State</label>
                        <input type="text" [(ngModel)]="state" [disabled]="isBlock?true:false"
                            [ngClass]="isBlock?'input_disable':''">
                    </div> -->

          <div class="service_modify_pd_inp_sub_div">
            <label for="state">State</label>
            <!-- <ng-select  style="margin-top: 15px;cursor:pointer" class="multi-select disabled" [items]="allState" bindLabel="name" bindValue="id"
                            [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="false" name="KOB"
                            placeholder="Select state" (change)="onSelectedStateChange($event)"
                            [(ngModel)]="selectedState">
    
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
    
                            </ng-template>
                        </ng-select> -->
            <input
              type="text"
              [(ngModel)]="selectedState"
              [disabled]="isBlock?true:false"
              class="disabled"
              [ngClass]="isBlock?'input_disable':''"
            />
          </div>

          <div
            class="service_modify_pd_inp_sub_div"
            *ngIf="roleId!=3 && roleId!=5"
          >
            <label for="state">Notes</label>
            <textarea style="height: 75%" [(ngModel)]="notes"></textarea>
            <!-- <input type="text" [(ngModel)]="selectedState" [disabled]="isBlock?true:false"
                        class="disabled" [ngClass]="isBlock?'input_disable':''"> -->
          </div>
          <div class="service_modify_pd_inp_sub_div">
            <div class="d-flex justify-content-between">
              <label for="state">Recent Status Changes</label>
              <label
                for="state"
                style="color: #6386fa; cursor: pointer"
                (click)="getAudit()"
                >See More</label
              >
            </div>

            <!-- Header -->
            <div
              class="license_modify_pd_main_div headerr"
              style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 20px;
                border-bottom: 2px solid #ddd;
                padding: 10px 0;
              "
            >
              <div style="min-width: 5rem">User Name</div>
              <div style="min-width: 5rem">From Status</div>
              <div style="min-width: 5rem">To Status</div>
              <!-- <div style="min-width:5rem;">Remark</div> -->
              <div style="min-width: 15rem">Status Changed on</div>
            </div>

            <!-- Data Rows -->
            <div
              *ngFor="let data of recentRepo.slice(0, 3);let i = index"
              style="border-bottom: 1px solid #ddd"
            >
              <div
                class="license_modify_pd_main_div"
                style="
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr 1fr;
                  gap: 20px;
                  padding: 10px 0;
                "
              >
                <div style="min-width: 5rem">{{data.name || 'N/A'}}</div>
                <div style="min-width: 5rem">
                  {{i+1 < recentRepo.length ? recentRepo[i+1].status : 'N/A' }}
                </div>
                <div style="min-width: 5rem">
                  {{data.status.replace('_',' ')}}
                </div>
                <div style="min-width: 15rem">{{getDate(data.datetime)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="service_modify_content_main_div"
    *ngIf="selectedTab == 'docs'"
    style="
      flex: 1;
      overflow-y: auto;
      max-height: 30rem;
      overflow: auto;
      margin-bottom: 1rem;
    "
  >
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      style="width: 100%; position: relative"
    >
      <!-- Sl NO -->
      <ng-container matColumnDef="sl_no">
        <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
        <td class="" mat-cell *matCellDef="let element; let i = index">
          {{i+1}}
        </td>
      </ng-container>

      <!-- Document Name  -->

      <ng-container matColumnDef="doc_name">
        <th class="common_table_data" mat-header-cell *matHeaderCellDef>
          Name
        </th>
        <td
          class="common_table_data"
          mat-cell
          *matCellDef="let element"
          style="cursor: pointer; color: #6386fa"
          (click)="updateDoc('application',element)"
        >
          {{element.document_name || element.product_name ||
          element.employee_name || element.instrument_name || element.audit_name
          }}
        </td>
      </ng-container>

      <!-- Description  -->

      <ng-container matColumnDef="from">
        <th class="common_table_data" mat-header-cell *matHeaderCellDef>
          Document Folder
        </th>
        <td class="common_table_data" mat-cell *matCellDef="let element">
          {{element.from}}
        </td>
      </ng-container>

      <!--  View License -->

      <ng-container matColumnDef="view_doc">
        <th class="common_table_data" mat-header-cell *matHeaderCellDef>
          View Document
        </th>
        <td class="common_table_data" mat-cell *matCellDef="let element">
          <img
            src="../../../assets/img/carbon_view.svg"
            alt=""
            style="cursor: pointer"
            (click)="viewLicence(element.documents)"
          />
        </td>
      </ng-container>

      <!-- Status -->

      <ng-container matColumnDef="status">
        <th class="common_table_data" mat-header-cell *matHeaderCellDef>
          Status
        </th>
        <td class="common_table_data" mat-cell *matCellDef="let element">
          <span> {{element.status}}</span>
        </td>
      </ng-container>

      <!-- Modify -->

      <!-- <ng-container matColumnDef="modify">
                <th class="common_table_data" mat-header-cell *matHeaderCellDef> Modify</th>
                <td class="common_table_data" mat-cell *matCellDef="let element">
                    <img src="../../../assets/img/edit_icon.svg" alt="" style="cursor: pointer;"
                        (click)="updateDoc('application',element)">
                </td>
            </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <!-- <div style="flex: 1;">&nbsp;</div> -->
  <div class="service_modify_btn_main_div" *ngIf="selectedTab == 'sr'">
    <div class="serviceFooter">
      <div class="lastModified">
        <div style="margin-right: 2rem">
          Last Modified By - <b>{{ModifierName}}</b>
        </div>
        <div>Last Modified - <b>{{ModifiedDate}}</b></div>
      </div>
      <div class="service_modify_pd_btn_div" *ngIf="(roleId!=3 && roleId!=2) ">
        <div (click)="close()">CANCEL</div>
        <div (click)="checkAndUpdateRemarks()">UPDATE</div>
      </div>
    </div>
    <div class="service_modify_pd_btn_div" *ngIf="roleId==2">
      <div (click)="close()">CANCEL</div>
      <div (click)="checkAndUpdateRemarksAM()">UPDATE</div>
    </div>
  </div>
  <div class="service_modify_btn_main_div" *ngIf="selectedTab == 'docs'">
    <!-- <div class="service_modify_pd_btn_div" *ngIf="roleId!=3 && roleId!=2">
            <div >&nbsp;</div>
            <div (click)="updateServiceReq()">UPDATE</div>
        </div> -->
    <div class="service_modify_pd_btn_div">
      <div (click)="close()">CANCEL</div>
      <div (click)="addDocument()">ADD NEW DOCUMENT</div>
    </div>
  </div>
</div>
