<div class="login_main_div">
  <div class="login_container_main_div">
    <div class="login_container_sub_div">
      <div class="text_center">
        <img src="../../../assets/img/logo_with_name.svg" alt="" />
      </div>
      <div class="text_center logIn_txt">Reset Password</div>

      <div class="inpt_main_div">
        <div>Password</div>
        <div>
          <input
            [type]="passwordToggleHide ? 'password' : 'text'"
            [(ngModel)]="password"
            name="user_id"
            id=""
          />
          <img
            src="../../../assets/img/eye-close-line.svg"
            alt=""
            style="cursor: pointer; margin-right: 10px"
            *ngIf="passwordToggleHide"
            (click)="passwordToggleHide = false"
          />
          <img
            src="../../../assets/img/eye-2-line.svg"
            alt=""
            style="cursor: pointer; margin-right: 10px"
            *ngIf="!passwordToggleHide"
            (click)="passwordToggleHide = true"
          />
        </div>
      </div>
      <div class="inpt_main_div">
        <div>Confirm Password</div>
        <div>
          <input
            [type]="cPasswordToggleHide ? 'password' : 'text'"
            [(ngModel)]="cPassword"
            name="user_id"
            id=""
          />
          <img
            src="../../../assets/img/eye-close-line.svg"
            alt=""
            style="cursor: pointer; margin-right: 10px"
            *ngIf="cPasswordToggleHide"
            (click)="cPasswordToggleHide = false"
          />
          <img
            src="../../../assets/img/eye-2-line.svg"
            alt=""
            style="cursor: pointer; margin-right: 10px"
            *ngIf="!cPasswordToggleHide"
            (click)="cPasswordToggleHide = true"
          />
        </div>
      </div>
      <div class="login_btn_main_div">
        <button (click)="updatePassword()">Submit</button>
      </div>
    </div>
  </div>
</div>
