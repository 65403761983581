import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data-share/data.service';
import { HttpService } from 'src/app/services/api/http.service';
import { ToastrService } from 'ngx-toastr';

import { AES, enc } from 'crypto-js';

@Component({
  selector: 'delete-confirm-dialog',
  templateUrl: './delete_confirm_dialog.html',
  styleUrls: ['./delete_confirm_dialog.css'],
})
export class DialogDeleteCofirm implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteCofirm>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataShare: DataService,
    public http: HttpService,
    private toster: ToastrService,
  ) {}
  role: any;
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(
        enc.Utf8,
      );
      this.dataShare.roleId = this.role;
    }
  }
  close() {
    this.dialogRef.close(false);
  }
  delete() {
    this.dialogRef.close(true);
  }
}
