<div class="shwoNotififications">
  <div class="d-flex justify-content-between">
    <div style="font-size: 16px; font-weight: 900; color: black">
      Notifications
    </div>
    <img
      style="width: 20px"
      (click)="close()"
      src="../../../assets/img/carbon_close-filled.svg"
    />
  </div>
  <div *ngIf="notifications.length == 0" style="margin: auto">
    <div class="notificationHeader">No Notifications Found</div>
  </div>
  <div
    *ngFor="let notif of notifications"
    class="notif"
    (click)="readNotifications(notif)"
  >
    <div class="notificationHeader">
      <div style="font-weight: 700">
        <div class="redDot" *ngIf="!notif.is_read"></div>
        {{ notif.type.toUpperCase().split("_").join(" ") }}
      </div>
      <div>
        {{ getDate(notif.created_at) }}
      </div>
    </div>
    <div class="notificationContent" [innerHTML]="notif.remarks">
      <!-- {{notif.remarks}} -->
    </div>
  </div>
</div>
