import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe, JsonPipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { AES, enc } from 'crypto-js';
import { NotificationsComponent } from '../common_dialog/notifications/notifications.component';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [MatDatepicker, MatPaginatorModule],
})
export class ReportsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  constructor(
    private httpSv: HttpService,
    private toster: ToastrService,
    private cdr: ChangeDetectorRef,
    private dataShare: DataService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
  ) {}
  notifications: any = [];
  isNewNotifications: boolean = false;
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      this.roleId = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
    } else {
      this.toster.error('Opps! something went wrong', '', { timeOut: 2000 });
    }

    this.selectorTabData = 'sr';
    setTimeout(async () => {
      this.photo = await localStorage.getItem('photo');
      this.username = await localStorage.getItem('username');
      console.log(this.photo);
    }, 0);

    this.getServiceRequest();
    this.getServiceCount();
    this.getNotifications();
  }
  username: any = 'User';
  photo: any = '../../../assets/img/default_dp.png';
  tPhoto: any = '../../../assets/img/default_dp.png';
  role: any;
  navigate(route: string) {
    if (this.router.url == route) return;
    else this.router.navigate([route]);
  }
  ngAfterViewInit() {
    this.dataSourceLicense.paginator = this.paginator;
    this.dataSourceService.paginator = this.paginator;
  }
  getNotifications() {
    this.httpSv.getNotifications(37).subscribe(
      (res: any) => {
        if (res['status']) {
          this.notifications = res['result'];
          const newNotf = this.notifications.filter(
            (data: any) => data.is_read === false,
          );
          console.log(this.notifications, newNotf);
          if (newNotf.length > 0) this.isNewNotifications = true;
          else this.isNewNotifications = false;
        } else {
          this.notifications = [];
          this.isNewNotifications = false;
        }
      },
      (err: any) => {
        this.toster.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  @Output() messageEmitter = new EventEmitter<any>();
  moveTab(tab: any, to: any = 'Enterprise') {
    this.messageEmitter.emit(tab);
    this.dataShare.prefilter = to;
  }
  readNotifications(element: any) {
    this.moveTab('service', element.type == 'eu' ? 'Enterprise' : 'Retail');
    this.dataShare.dialogRef(element.service_request_id, element.type);
    let data = {
      id: element.id,
      is_read: 1,
    };
    this.httpSv.readNotification(data).subscribe(
      (res: any) => {
        if (res['status']) {
          this.getNotifications();
        } else {
          this.toster.error(res['message'], '', { timeOut: 2000 });
        }
      },
      (err: any) => {
        this.toster.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  showNotif: boolean = false;
  toggleNotif() {
    this.dialog
      .open(NotificationsComponent, {
        data: this.notifications,
        // position: {
        //   top: `5rem`,
        //   right: `25rem`,
        // },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getNotifications();
        }
      });
  }
  lengthUser: any;
  lengthLicense: any;
  lengthSr: any;
  pageSizeUser = 20;
  pageSizeSr = 20;
  pageSizeLicense = 20;
  pageIndexUser = 0;
  pageIndexSr = 0;
  pageIndexLicense = 0;
  pageSizeOptions = [20, 50, 100, 200, 500];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: PageEvent = new PageEvent();

  handlePageEvent(e: PageEvent, from: any) {
    this.pageEvent = e;
    console.log(e);
    if (from == 'sr') {
      this.lengthSr = e.length;
      this.pageSizeSr = e.pageSize;
      this.pageIndexSr = e.pageIndex;
      this.getServiceRequest();
    } else if (from == 'user') {
      this.lengthUser = e.length;
      this.pageSizeUser = e.pageSize;
      this.pageIndexUser = e.pageIndex;
      this.getUserList();
    } else if (from == 'license') {
      this.lengthLicense = e.length;
      this.pageSizeLicense = e.pageSize;
      this.pageIndexLicense = e.pageIndex;
      this.getLicenseData();
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }
  selectorTabData: any;
  roleId: any;
  postPerPage: any = 50;
  pageNumber: any = 1;
  totalItems: any;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  displayedColumns: string[] = [
    'sl_no',
    'name',
    'user_type',
    'city',
    'state',
    'no_licenses',
    'am',
    'user_since',
    'no_docs',
    'storage',
    'paid',
    'status',
  ];
  dataEnterprise: any[] = [];
  dataSource: any = new MatTableDataSource();
  userData: any;

  displayedColumnsLicense: string[] = [
    'sl_no',
    'name',
    'license',
    'type',
    'date',
    'status',
    'city',
    'state',
    'am',
  ];
  dataEnterpriseLicense: any[] = [];
  dataSourceLicense: any = new MatTableDataSource();
  licenseData: any;

  displayedColumnsService: string[] = [
    'sl_no',
    'id',
    'name',
    'date',
    'type',
    'status',
    'time',
    'city',
    'state',
    'am',
  ];
  dataEnterpriseService: any[] = [];
  dataSourceService: any = new MatTableDataSource();
  serviceData: any;
  filterr: any = 'ALL';
  selectedFilter: any = 'all';

  userNameFilter: any = '';
  userNameDropdown: any[] = [];
  userCityFilter: any = '';
  userCityDropdown: any[] = [];
  userStateFilter: any = '';
  userStateDropdown: any[] = [];
  userAmFilter: any = '';
  userAmDropdown: any[] = [];
  userStatusFilter: any = '';
  userStatusDropdown: any[] = [];

  licenseUserFiler: any = '';
  licenseUserDropdown: any[] = [];
  licenseStatusFilter: any = '';
  licenseStatusDropdown: any[] = [];
  licenseCityFilter: any = '';
  licenseCityDropdown: any[] = [];
  licenseStateFilter: any = '';
  licenseStateDropdown: any[] = [];
  licenseAmFilter: any = '';
  licenseAmDropdown: any[] = [];
  licenseTypeFilter: any = '';
  licenseDateFilter: any = '';
  licenseTypeDropdown: any[] = [];
  licenseDateDropdown: any[] = [];

  serviceUserFiler: any = '';
  serviceUserDropdown: any[] = [];
  serviceStatusFilter: any = '';
  serviceStatusDropdown: any[] = [];
  serviceCityFilter: any = '';
  serviceCityDropdown: any[] = [];
  serviceStateFilter: any = '';
  serviceStateDropdown: any[] = [];
  serviceAmFilter: any = '';
  serviceAmDropdown: any[] = [];
  serviceTypeFilter: any = '';
  serviceTypeDropdown: any[] = [];
  startDate: any = null;
  endDate: any = null;

  serviceApiData: any;
  licenseApiData: any;
  userApiData: any;

  currentUserData: any;
  currentLicenseData: any;
  currentServiceData: any;
  userDataCount: any = { enterprise_user: {}, retail_user: {} };
  licenseDataCount: any = { enterprise_user: {}, retail_user: {} };
  serviceDataCount: any = { enterprise_user: {}, retail_user: {} };

  selectorTab(data: any) {
    this.selectorTabData = data;
    this.startDate = null;
    this.endDate = null;
    if (data == 'user') {
      this.getUserList();
      this.getUserCount();
    }
    if (data == 'license') {
      this.getLicenseData();
      this.getLicenseCount();
    }
    if (data == 'sr') {
      this.getServiceRequest();
      this.getServiceCount();
    }
  }

  getUserCount() {
    this.httpSv.getUserDataCount(this.roleId).subscribe((res: any) => {
      if (res['status']) {
        this.userDataCount = res['results'];
      }
    });
  }
  getLicenseCount() {
    this.httpSv.getLicenseDataCount(this.roleId).subscribe((res: any) => {
      if (res['status']) {
        this.licenseDataCount = res['results'];
      }
    });
  }
  getServiceCount() {
    this.httpSv.getServiceDataCount(this.roleId).subscribe((res: any) => {
      if (res['status']) {
        this.serviceDataCount = res['results'];
      }
    });
  }
  setDate(from: any, date: any) {
    if (from == 'start') this.startDate = date.value;
    else this.endDate = date.value;
    this.rangeChange(this.selectorTabData);
  }

  rangeChange(from: any) {
    if (this.startDate != null && this.endDate != null) {
      let from = this.convert(this.startDate);
      let to = this.convert(this.endDate);
      if (this.selectorTabData == 'user') {
        this.httpSv.getUserReportCountDate(from, to).subscribe(
          (res: any) => {
            if (res['status']) {
              this.dataSource = res['results'];
              this.userData = res['results'];
              this.userApiData = res['results'];
              this.createUserDropdown(res['results']);
            } else {
              this.dataSource = [];
              this.userData = [];
              this.userApiData = [];
              this.createUserDropdown([]);
            }
          },
          () => {
            this.dataSource = [];
            this.userData = [];
            this.userApiData = [];
            this.createUserDropdown([]);
          },
        );
      } else if (this.selectorTabData == 'sr') {
        this.httpSv.getServiceRequestReportDate(from, to).subscribe(
          (res: any) => {
            if (res['status']) {
              this.dataSourceService = res['results'];
              this.serviceData = res['results'];
              this.serviceApiData = res['results'];
              this.createServiceDropdown(res['results']);
            } else {
              this.dataSourceService = [];
              this.serviceData = [];
              this.serviceApiData = [];
              this.createServiceDropdown([]);
            }
          },
          () => {
            this.dataSourceService = [];
            this.serviceData = [];
            this.serviceApiData = [];
            this.createServiceDropdown([]);
          },
        );
      } else {
        this.httpSv.getLicenseReportCountDate(from, to).subscribe(
          (res: any) => {
            if (res['status']) {
              let data = res['results'];
              data.map((res: any) => {
                res.created_at = res.created_at.split('T')[0];
              });
              this.dataSourceLicense = data;
              this.licenseData = data;
              this.licenseApiData = data;
              this.createLicenseDropdown(data);
            } else {
              this.dataSourceLicense = new MatTableDataSource([]);
              this.licenseData = [];
              this.licenseApiData = [];
              this.createLicenseDropdown([]);
            }
          },
          () => {
            this.dataSourceLicense = new MatTableDataSource([]);
            this.licenseData = [];
            this.licenseApiData = [];
            this.createLicenseDropdown([]);
          },
        );
      }
    } else if (this.startDate == null && this.endDate == null) {
      if (from == 'user') this.getUserList();
      else if (from == 'license') this.getLicenseData();
      else this.getServiceRequest();
    }
  }
  convert(str: any) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }
  getUserList() {
    this.httpSv
      .getUserReport(
        this.roleId,
        this.pageSizeUser,
        this.pageIndexUser + 1,
        this.selectedFilter,
      )
      .subscribe(
        (res: any) => {
          if (res['status']) {
            res['results'].map((data: any) => {
              if (data.date_joined.indexOf('-') != -1) {
                data.date_joined =
                  this.datePipe.transform(data.date_joined, 'dd/MM/yyyy') || '';
              }
            });
            let data = res['results'];
            let temp: any[] = [];
            if (this.selectedFilter != 'all') {
              data.map((res: any) => {
                if (this.selectedFilter == 'enterprise') {
                  if (res.user_type == 'enterprise_user') temp.push(res);
                } else {
                  if (res.user_type == 'retail_user') temp.push(res);
                }
              });
            } else temp = data;
            this.currentUserData = temp;
            this.dataSource = temp;
            this.cdr.detectChanges();
            this.userData = temp;
            this.lengthUser = res['total_count'];
            this.cdr.detectChanges();
            console.log(this.lengthUser, '%%%%%%');
            this.userApiData = temp;
            setTimeout(() => {
              this.lengthUser = res['total_count'];
            });
            this.createUserDropdown(temp);
          } else {
            this.dataSource = [];
          }
        },
        () => {
          this.dataSource = [];
        },
      );
  }
  createUserDropdown(data: any) {
    data.map((res: any) => {
      if (
        res.user_type &&
        !this.userNameDropdown.includes(res.user_type.toLowerCase())
      )
        this.userNameDropdown.push(res.user_type.toLowerCase());
      if (res.city && !this.userCityDropdown.includes(res.city.toLowerCase()))
        this.userCityDropdown.push(res.city.toLowerCase());
      if (
        res.state &&
        !this.userStateDropdown.includes(res.state.toLowerCase())
      )
        this.userStateDropdown.push(res.state.toLowerCase());
      if (
        res.account_manager &&
        !this.userAmDropdown.includes(res.account_manager.toLowerCase())
      ) {
        this.userAmDropdown.push(res.account_manager.toLowerCase());
      }
      if (
        res.status &&
        !this.userStatusDropdown.includes(res.status.toLowerCase())
      ) {
        this.userStatusDropdown.push(res.status.toLowerCase());
      }
    });
  }

  userApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.userNameFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.userApiData;
        if (this.userStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.userStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.userStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }

        if (this.userCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.city && data.city.toLowerCase() == this.userCityFilter)
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.userAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.userData.map((apiData: any) => {
          if (
            apiData.user_type &&
            apiData.user_type.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.userData = result;
      this.dataSource = this.userData;
      this.currentUserData = this.userData;
    }
    if (from == 'city') {
      this.userCityFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.userApiData;
        if (this.userStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.userStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.userStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.user_type &&
              data.user_type.toLowerCase() == this.userNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.userAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.userData.map((apiData: any) => {
          if (apiData.city && apiData.city.toLowerCase() == data.toLowerCase())
            result.push(apiData);
        });
      }
      this.userData = result;
      this.dataSource = this.userData;
      this.currentUserData = this.userData;
    }
    if (from == 'status') {
      this.userStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.userApiData;
        if (this.userCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.userCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.userStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.user_type &&
              data.user_type.toLowerCase() == this.userNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.userAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.userData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.userData = result;
      this.dataSource = this.userData;
      this.currentUserData = this.userData;
    }
    if (from == 'am') {
      this.userAmFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.userApiData;
        if (this.userCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.userCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.userStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.user_type &&
              data.user_type.toLowerCase() == this.userNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.userStatusFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.userData.map((apiData: any) => {
          if (
            apiData.account_manager &&
            apiData.account_manager.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.userData = result;
      this.dataSource = this.userData;
      this.currentUserData = this.userData;
    }
    if (from == 'state') {
      this.userStateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.userApiData;
        if (this.userCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.userCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.userAmFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.user_type &&
              data.user_type.toLowerCase() == this.userNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.userStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.userStatusFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.userData.map((apiData: any) => {
          if (
            apiData.state &&
            apiData.state.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.userData = result;
      this.dataSource = this.userData;
      this.currentUserData = this.userData;
    }
  }

  getLicenseData() {
    this.httpSv
      .getLicenseReport(
        this.roleId,
        this.pageSizeLicense,
        this.pageIndexLicense + 1,
        this.selectedFilter,
      )
      .subscribe(
        (res: any) => {
          if (res['status']) {
            res['results'].map((data: any) => {
              if (data.created_at.indexOf('-') != -1) {
                data.created_at =
                  this.datePipe.transform(data.created_at, 'dd/MM/yyyy') || '';
              }
            });
            let data = res['results'];
            let temp: any[] = [];
            if (this.selectedFilter != 'all') {
              data.map((res: any) => {
                if (this.selectedFilter == 'enterprise') {
                  if (res.user_type == 'enterprise_user') temp.push(res);
                } else {
                  if (res.user_type == 'retail_user') temp.push(res);
                }
              });
            } else temp = data;
            temp.map((res: any) => {
              res.created_at = res.created_at.split('T')[0];
            });
            this.currentLicenseData = temp;
            this.dataSourceLicense = temp;
            this.cdr.detectChanges();
            this.lengthLicense = temp.length;
            this.licenseData = temp;
            this.licenseApiData = temp;
            this.createLicenseDropdown(temp);
          } else {
            this.licenseData = [];
          }
        },
        () => {
          this.licenseData = [];
        },
      );
  }
  createLicenseDropdown(data: any) {
    data.map((res: any) => {
      if (
        res.client_name &&
        !this.licenseUserDropdown.includes(res.client_name.toLowerCase())
      )
        this.licenseUserDropdown.push(res.client_name.toLowerCase());
      if (
        res.city &&
        !this.licenseCityDropdown.includes(res.city.toLowerCase())
      )
        this.licenseCityDropdown.push(res.city.toLowerCase());
      if (
        res.state &&
        !this.licenseStateDropdown.includes(res.state.toLowerCase())
      )
        this.licenseStateDropdown.push(res.state.toLowerCase());
      if (
        res.account_manager &&
        !this.licenseAmDropdown.includes(res.account_manager.toLowerCase())
      ) {
        this.licenseAmDropdown.push(res.account_manager.toLowerCase());
      }
      if (
        res.status &&
        !this.licenseStatusDropdown.includes(res.status.toLowerCase())
      ) {
        this.licenseStatusDropdown.push(res.status.toLowerCase());
      }
      if (
        res.license_type &&
        !this.licenseTypeDropdown.includes(res.license_type.toLowerCase())
      ) {
        this.licenseTypeDropdown.push(res.license_type.toLowerCase());
      }
      if (
        res.created_at &&
        !this.licenseDateDropdown.includes(res.created_at.toLowerCase())
      ) {
        this.licenseDateDropdown.push(res.created_at.toLowerCase());
      }
    });
  }

  licenseApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.licenseUserFiler = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.licenseApiData;
        if (this.licenseStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() ==
                this.licenseStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.licenseStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.created_at &&
              data.created_at.toLowerCase() ==
                this.licenseDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.license_type &&
              data.license_type.toLowerCase() ==
                this.licenseTypeFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.city && data.city.toLowerCase() == this.licenseCityFilter)
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.licenseAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.licenseData.map((apiData: any) => {
          if (
            apiData.client_name &&
            apiData.client_name.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.licenseData = result;
      this.dataSourceLicense = this.licenseData;
      this.currentLicenseData = this.licenseData;
    }
    if (from == 'type') {
      this.licenseTypeFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.licenseApiData;
        if (this.licenseStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() ==
                this.licenseStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.licenseStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.created_at &&
              data.created_at.toLowerCase() ==
                this.licenseDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.client_name &&
              data.client_name.toLowerCase() ==
                this.licenseUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.city && data.city.toLowerCase() == this.licenseCityFilter)
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.licenseAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.licenseData.map((apiData: any) => {
          if (
            apiData.license_type &&
            apiData.license_type.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.licenseData = result;
      this.dataSourceLicense = this.licenseData;
      this.currentLicenseData = this.licenseData;
    }
    if (from == 'city') {
      this.licenseCityFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.licenseApiData;
        if (this.licenseStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() ==
                this.licenseStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.licenseStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.client_name &&
              data.client_name.toLowerCase() ==
                this.licenseUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.created_at &&
              data.created_at.toLowerCase() ==
                this.licenseDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.license_type &&
              data.license_type.toLowerCase() == this.licenseTypeFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.licenseAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.licenseData.map((apiData: any) => {
          if (apiData.city && apiData.city.toLowerCase() == data.toLowerCase())
            result.push(apiData);
        });
      }
      this.licenseData = result;
      this.dataSourceLicense = this.licenseData;
      this.currentLicenseData = this.licenseData;
    }
    if (from == 'status') {
      this.licenseStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.licenseApiData;
        if (this.licenseCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.licenseCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.licenseStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.created_at &&
              data.created_at.toLowerCase() ==
                this.licenseDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.client_name &&
              data.client_name.toLowerCase() ==
                this.licenseUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.license_type &&
              data.license_type.toLowerCase() == this.licenseTypeFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.licenseAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.licenseData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.licenseData = result;
      this.dataSourceLicense = this.licenseData;
      this.currentLicenseData = this.licenseData;
    }
    if (from == 'am') {
      this.licenseAmFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.licenseApiData;
        if (this.licenseCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.licenseCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.licenseStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.client_name &&
              data.client_name.toLowerCase() ==
                this.licenseUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.created_at &&
              data.created_at.toLowerCase() ==
                this.licenseDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.license_type &&
              data.license_type.toLowerCase() == this.licenseTypeFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.licenseStatusFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.licenseData.map((apiData: any) => {
          if (
            apiData.account_manager &&
            apiData.account_manager.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.licenseData = result;
      this.dataSourceLicense = this.licenseData;
      this.currentLicenseData = this.licenseData;
    }
    if (from == 'date') {
      this.licenseDateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.licenseApiData;
        if (this.licenseCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.licenseCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.licenseStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.client_name &&
              data.client_name.toLowerCase() ==
                this.licenseUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.license_type &&
              data.license_type.toLowerCase() == this.licenseTypeFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.licenseAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.licenseStatusFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.licenseData.map((apiData: any) => {
          if (
            apiData.created_at &&
            apiData.created_at.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.licenseData = result;
      this.dataSourceLicense = this.licenseData;
      this.currentLicenseData = this.licenseData;
    }
    if (from == 'state') {
      this.licenseStateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.licenseApiData;
        if (this.licenseCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.licenseCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.licenseAmFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.client_name &&
              data.client_name.toLowerCase() ==
                this.licenseUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseDateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.created_at &&
              data.created_at.toLowerCase() ==
                this.licenseDateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.license_type &&
              data.license_type.toLowerCase() == this.licenseTypeFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.licenseStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.licenseStatusFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.licenseData.map((apiData: any) => {
          if (
            apiData.state &&
            apiData.state.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.licenseData = result;
      this.dataSourceLicense = this.licenseData;
      this.currentLicenseData = this.licenseData;
    }
  }

  getServiceRequest() {
    this.httpSv
      .getServiceRequestReport(
        this.roleId,
        this.pageSizeSr,
        this.pageIndexSr + 1,
        this.selectedFilter,
      )
      .subscribe(
        (res: any) => {
          if (res['status']) {
            res['results'].map((data: any) => {
              if (data.date.indexOf('-') != -1) {
                data.date =
                  this.datePipe.transform(data.date, 'dd/MM/yyyy') || '';
              }
            });
            let data = res['results'];
            let temp: any[] = [];
            if (this.selectedFilter != 'all') {
              data.map((res: any) => {
                if (this.selectedFilter == 'enterprise') {
                  if (res.user_type == 'enterprise_user') temp.push(res);
                } else {
                  if (res.user_type == 'retail_user') temp.push(res);
                }
              });
            } else temp = data;
            setTimeout(() => {
              this.currentServiceData = temp;
              this.dataSourceService = temp;
              this.lengthSr = temp.length;
              this.cdr.detectChanges();
              this.cdr.detectChanges();
              this.serviceData = temp;
              this.serviceApiData = temp;
              this.createServiceDropdown(temp);
            }, 0);
          } else {
            this.serviceData = [];
          }
        },
        () => {
          this.serviceData = [];
        },
      );
  }

  createServiceDropdown(data: any) {
    data.map((res: any) => {
      if (
        res.username &&
        !this.serviceUserDropdown.includes(res.username.toLowerCase())
      )
        this.serviceUserDropdown.push(res.username.toLowerCase());
      if (
        res.city &&
        !this.serviceCityDropdown.includes(res.city.toLowerCase())
      )
        this.serviceCityDropdown.push(res.city.toLowerCase());
      if (
        res.state &&
        !this.serviceStateDropdown.includes(res.state.toLowerCase())
      )
        this.serviceStateDropdown.push(res.state.toLowerCase());
      if (
        res.account_manager &&
        !this.serviceAmDropdown.includes(res.account_manager.toLowerCase())
      ) {
        this.serviceAmDropdown.push(res.account_manager.toLowerCase());
      }
      if (
        res.status &&
        !this.serviceStatusDropdown.includes(res.status.toLowerCase())
      ) {
        this.serviceStatusDropdown.push(res.status.toLowerCase());
      }
      if (
        res.services &&
        !this.serviceTypeDropdown.includes(res.services.toLowerCase())
      ) {
        this.serviceTypeDropdown.push(res.services.toLowerCase());
      }
    });
  }
  //   ConvertToCSV(objArray:any, headerList:any) {
  //     let array = typeof objArray !=
  //         'object' ? JSON.parse(objArray) : objArray;
  //     let str = '';
  //     let row = 'S.No,';

  //     for (let index in headerList) {
  //         row += headerList[index] + ',';
  //     }
  //     row = row.slice(0, -1);
  //     str += row + '\r\n';
  //     for (let i = 0; i < array.length; i++) {
  //         let line = (i + 1) + '';
  //         for (let index in headerList) {
  //             let head = headerList[index];
  //             line += ',' + array[i][head];
  //         }
  //         str += line + '\r\n';
  //     }
  //     return str;
  // }
  jsonToCsv(items: any) {
    const header = Object.keys(items[0]);

    const headerString = header.join(',');

    // handle null or undefined values here
    const replacer = (key: any, value: any) => value ?? '';

    const rowItems = items.map((row: any) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(','),
    );

    // join header and body, and break into separate lines
    const csv = [headerString, ...rowItems].join('\r\n');

    return csv;
  }
  extractData(from: any, data: any) {
    const dataArray: any[][] = [];
    if (from == 'sr') {
      data.forEach((item: any) => {
        const row: any[] = [];
        row.push(item.id);
        row.push(item.username);
        row.push(item.date);
        row.push(item.services);
        row.push(item.status);
        row.push(item.days_lapsed);
        row.push(item.city ? item.city : item.address);
        row.push(item.state);
        row.push(item.account_manager);
        dataArray.push(row);
      });
    } else if (from == 'license') {
      data.forEach((item: any) => {
        const row: any[] = [];
        row.push(item.username);
        row.push(item.registration_no);
        row.push(item.license_type);
        row.push(item.created_at);
        row.push(item.status);
        row.push(item.city ? item.city : item.address);
        row.push(item.state);
        row.push(item.account_manager);
        dataArray.push(row);
      });
    } else {
      data.forEach((item: any) => {
        const row: any[] = [];
        row.push(item.name);
        row.push(item.user_type);
        row.push(item.city ? item.city : item.address);
        row.push(item.state);
        row.push(item.no_of_licenses);
        row.push(item.account_manager);
        row.push(item.date_joined);
        row.push(item.no_of_documents);
        row.push(item.storage_used);
        row.push(item.paid_status);
        row.push(item.status);
        dataArray.push(row);
      });
    }
    return dataArray;
  }
  export(from: any) {
    if (from == 'pdf') {
      let data: any;
      let jsonData;
      if (this.selectorTabData == 'user') {
        data = [
          'User Name',
          'User Type',
          'City',
          'State',
          'Number of Licenses',
          'Account Manager',
          'User Since',
          'Number of Documents',
          'Storage',
          'Paid',
          'Status',
        ];
        jsonData = this.extractData('user', this.currentUserData);
      } else if (this.selectorTabData == 'license') {
        data = [
          'User Name',
          'License',
          'Type of License',
          'Date',
          'Status',
          'City',
          'State',
          'Account Manager',
        ];
        jsonData = this.extractData('license', this.currentLicenseData);
      } else {
        data = [
          'Id',
          'User Name',
          'Date',
          'Type of Request',
          'Current Status',
          'Days Lapsed',
          'City',
          'State',
          'Account Manager',
        ];
        jsonData = this.extractData('sr', this.currentServiceData);
      }
      // var width = doc.internal.pageSize.getWidth();
      // var height = doc.internal.pageSize.getHeight();

      const doc = new jsPDF();
      autoTable(doc, {
        margin: { top: 10, left: 5, right: 5, bottom: 0 },
        head: [data],
        body: jsonData,
      });
      doc.save(
        this.selectorTabData == 'sr'
          ? 'ServiceRequest'
          : this.selectorTabData + '.pdf',
      );
    } else {
      let data: any;
      let jsonData;
      if (this.selectorTabData == 'user') {
        data = [
          'User Name',
          'User Type',
          'City',
          'State',
          'Number of Licenses',
          'Account Manager',
          'User Since',
          'Number of Documents',
          'Storage',
          'Paid',
          'Status',
        ];
        jsonData = this.currentUserData;
      } else if (this.selectorTabData == 'license') {
        data = [
          'User Name',
          'License',
          'Type of License',
          'Status',
          'City',
          'State',
          'Account Manager',
        ];
        jsonData = this.currentLicenseData;
      } else {
        data = [
          'User Name',
          'Date',
          'Type of Request',
          'Current Status',
          'Days Lapsed',
          'City',
          'State',
          'Account Manager',
        ];
        jsonData = this.currentServiceData;
      }
      // console.log(this.currentUserData)
      // return
      let csvData = this.jsonToCsv(jsonData);
      let blob = new Blob(['\ufeff' + csvData], {
        type: 'text/csv;charset=utf-8;',
      });
      let dwldLink = document.createElement('a');
      let url = URL.createObjectURL(blob);
      let isSafariBrowser =
        navigator.userAgent.indexOf('Safari') != -1 &&
        navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {
        dwldLink.setAttribute('target', '_blank');
      }
      dwldLink.setAttribute('href', url);
      dwldLink.setAttribute(
        'download',
        this.selectorTabData == 'sr'
          ? 'ServiceRequest'
          : this.selectorTabData + '.csv',
      );
      dwldLink.style.visibility = 'hidden';
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
  }

  // public export(from:any)
  // {
  //   if(from=='pdf'){
  //       var data:any = document.getElementById('contentToConvert');
  //       var w:any = document.getElementById("contentToConvert")?.offsetWidth;
  //       var h :any= document.getElementById("contentToConvert")?.offsetHeight;
  //       var ele:any = document.getElementById("contentToConvert")

  //       window.scrollTo(document.body.scrollWidth, 0);
  //       let pdf = new jspdf('p', 'px', [w,h]);
  //       let width:any;
  //       if(this.selectorTabData=='user')
  //         width=1300
  //       else if(this.selectorTabData=='license')
  //         width=1000
  //       else
  //         width=2200
  //         // let doc = html2pdf().set(opt).from(data2).toPdf()
  //         // .get('pdf').then( (pdf) =>{
  //         // })

  //       console.log(width)

  //     var headers:any = this.createHeaders(["Date", "Number of Products", "Size", "Compressed Size"]);

  //     var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'landscape' });
  //     doc.table(1, 1, this.dataSourceService.data, headers, { autoSize: true });
  //     doc.save("test.pdf")
  //       console.log(document.getElementById("contentToConvert"))
  //       html2canvas(data ,{ allowTaint: true, useCORS: true, logging: true,width:width }).then(canvas => {
  //         // Few necessary settihtmlng options
  //         var imgWidth = 646;
  //         var imgHeight = canvas.height * imgWidth / canvas.width;
  //         var heightLeft = imgHeight;

  //         const contentDataURL = canvas.toDataURL('image/png',1)
  //          // A4 size page of PDF
  //         var position = 0;
  //         pdf.addImage(contentDataURL, 'PNG', 0, 0, w, h)
  //         if(this.selectorTabData=='user')
  //           pdf.save('User_Report.pdf')
  //         else if(this.selectorTabData=='sr')
  //           pdf.save('Service_request.pdf')
  //         else
  //           pdf.save('Licenses.pdf');
  //         });
  //   }
  //   else{
  //     let data:any;
  //     let jsonData;
  //     if(this.selectorTabData=='user'){
  //       data=['User Name','City','State','Number of Licenses','Account Manager','User Since','Number of Documents','Storage','Paid','Status']
  //       jsonData = this.currentUserData;
  //     }
  //     else if(this.selectorTabData=='license'){
  //       data=['User Name','License','Type of License','Status','City','State','Account Manager']
  //       jsonData = this.currentLicenseData;
  //     }
  //     else  {
  //       data=['User Name','Date','Type of Request','Current Status','Days Lapsed','City','State','Account Manager']
  //       jsonData = this.currentServiceData;
  //     }
  //     let csvData = this.jsonToCsv(jsonData);
  //       let blob = new Blob(['\ufeff' + csvData],
  //           { type: 'text/csv;charset=utf-8;' });
  //       let dwldLink = document.createElement("a");
  //       let url = URL.createObjectURL(blob);
  //       let isSafariBrowser = navigator.userAgent.indexOf('Safari') !=
  //           -1 && navigator.userAgent.indexOf('Chrome') == -1;
  //       if (isSafariBrowser) {
  //           dwldLink.setAttribute("target", "_blank");
  //       }
  //       dwldLink.setAttribute("href", url);
  //       dwldLink.setAttribute("download", this.selectorTabData=='sr'?'ServiceRequest':this.selectorTabData + ".csv");
  //       dwldLink.style.visibility = "hidden";
  //       document.body.appendChild(dwldLink);
  //       dwldLink.click();
  //       document.body.removeChild(dwldLink);
  //   }
  // }
  createHeaders(keys: any) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 65,
        align: 'center',
        padding: 0,
      });
    }
    return result;
  }

  filter(data: any, from: any) {
    this.selectedFilter = data;
    this.filterr = data;
    if (this.selectorTabData == 'user') this.getUserList();
    else if (this.selectorTabData == 'license') this.getLicenseData();
    else this.getServiceRequest();
  }
  ServiceApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.serviceUserFiler = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.serviceApiData;
        if (this.serviceStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() ==
                this.serviceStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.serviceStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() ==
                this.serviceTypeFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.city && data.city.toLowerCase() == this.serviceCityFilter)
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.serviceAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.serviceData.map((apiData: any) => {
          if (
            apiData.username &&
            apiData.username.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.serviceData = result;
      this.dataSourceService = this.serviceData;
      this.currentServiceData = this.serviceData;
    }
    if (from == 'type') {
      this.serviceTypeFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.serviceApiData;
        if (this.serviceStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() ==
                this.serviceStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.serviceStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.username &&
              data.username.toLowerCase() == this.serviceUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.city && data.city.toLowerCase() == this.serviceCityFilter)
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.serviceAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.serviceData.map((apiData: any) => {
          if (
            apiData.services &&
            apiData.services.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.serviceData = result;
      this.dataSourceService = this.serviceData;
      this.currentServiceData = this.serviceData;
    }
    if (from == 'city') {
      this.serviceCityFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.serviceApiData;
        if (this.serviceStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() ==
                this.serviceStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.serviceStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.username &&
              data.username.toLowerCase() == this.serviceUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.serviceTypeFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.serviceAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.serviceData.map((apiData: any) => {
          if (apiData.city && apiData.city.toLowerCase() == data.toLowerCase())
            result.push(apiData);
        });
      }
      this.serviceData = result;
      this.dataSourceService = this.serviceData;
      this.currentServiceData = this.serviceData;
    }
    if (from == 'status') {
      this.serviceStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.serviceApiData;
        if (this.serviceCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.serviceCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.serviceStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.username &&
              data.username.toLowerCase() == this.serviceUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.serviceTypeFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() == this.serviceAmFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.serviceData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.serviceData = result;
      this.dataSourceService = this.serviceData;
      this.currentServiceData = this.serviceData;
    }
    if (from == 'am') {
      this.serviceAmFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.serviceApiData;
        if (this.serviceCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.serviceCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceStateFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.state &&
              data.state.toLowerCase() == this.serviceStateFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.username &&
              data.username.toLowerCase() == this.serviceUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.serviceTypeFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.serviceStatusFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.serviceData.map((apiData: any) => {
          if (
            apiData.account_manager &&
            apiData.account_manager.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.serviceData = result;
      this.dataSourceService = this.serviceData;
      this.currentServiceData = this.serviceData;
    }
    if (from == 'state') {
      this.serviceStateFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.serviceApiData;
        if (this.serviceCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.serviceCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceAmFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.account_manager &&
              data.account_manager.toLowerCase() ==
                this.serviceAmFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceUserFiler.length > 0) {
          filterData.map((data: any) => {
            if (
              data.username &&
              data.username.toLowerCase() == this.serviceUserFiler.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceTypeFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.services &&
              data.services.toLowerCase() == this.serviceTypeFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.serviceStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.serviceStatusFilter
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (result.length == 0) result = filterData;
      } else {
        this.serviceData.map((apiData: any) => {
          if (
            apiData.state &&
            apiData.state.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.serviceData = result;
      this.dataSourceService = this.serviceData;
      this.currentServiceData = this.serviceData;
    }
  }
}
