<div class="reset_password_main_div">
  <div class="reset_password_content_main_div" *ngIf="phaseEmail">
    <div class="reset_password_content mr-2">
      <div style="width: 50%">Enter Your Registered EMAIL ID</div>
      <div class="passwordToggler w-100">
        <input
          type="email"
          autocomplete="new-usernamer"
          name="email"
          id=""
          [(ngModel)]="emailId"
        />
      </div>
    </div>
    <div class="login_btn_main_div" style="margin-top: 2rem">
      <button (click)="submit()">SUBMIT</button>
    </div>
  </div>
  <div class="reset_password_content_main_div" *ngIf="!phaseEmail">
    <div class="confirmationText">
      Please check your Registered Email for Further Details.
    </div>
  </div>
</div>
