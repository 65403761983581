<div class="ces_main_div">
  <div class="ces_header">
    <span>Add Document</span>
    <div class="header_quik_functions">
      <img src="../../../assets/img/close.svg" alt="" (click)="close()" />
    </div>
  </div>
  <div
    class="ces_content_main_div"
    style="
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding: 2rem;
      padding-bottom: 1rem;
    "
  >
    <div class="ces_pd_inp_sub_div">
      <label for="text">Document Name</label>
      <input type="text" [(ngModel)]="documentName" />
    </div>
    <div class="ces_pd_inp_sub_div">
      <label for="text"> Document Description</label>
      <input type="text" [(ngModel)]="documentDescription"/>
    </div>

    <div class="ces_pd_inp_sub_div">
      <label for="number">File Upload</label>
      <!-- <input type="file" accept=".pdf" (change)="onImageChange($event)" /> -->
      <input type="file" accept=".pdf,.xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" (change)="onImageChange($event)" />
      {{ fileName }}
    </div>
  </div>

  <div style="flex: 1">&nbsp;</div>
  <div class="ces_btn_main_div" style="margin-bottom: 2rem">
    <div class="ces_pd_btn_div">
      <div (click)="close()">CANCEL</div>
      <div (click)="save()">{{ !data.id ? "SAVE" : "UPDATE" }}</div>
    </div>
  </div>
</div>
