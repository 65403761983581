<div class="side_bar_common_css">
  <app-sidenav-bar></app-sidenav-bar>
  <div class="settings_main_div">
    <div class="notificationBar">
      <img
        *ngIf="role == 1"
        src="../../../assets/img/setting.png"
        (click)="navigate('/settings')"
        style="width: 35px; border-radius: 25px; cursor: pointer"
        alt=""
        srcset=""
        class="side_nav_bottom_dp"
      />
      <img
        *ngIf="!isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/NotifiationBell.svg"
      />
      <img
        *ngIf="isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/notificationIcon.svg"
      />
      <div class="shwoNotififications" *ngIf="showNotif">
        <div class="d-flex justify-content-end">
          <img
            style="width: 20px"
            (click)="toggleNotif()"
            src="../../../assets/img/carbon_close-filled.svg"
          />
        </div>
        <div *ngIf="notifications.length == 0">
          <div class="notificationHeader">No Notifications Found</div>
        </div>
        <div
          *ngFor="let notif of notifications"
          class="notif"
          (click)="readNotifications(notif)"
        >
          <div class="notificationHeader">
            {{ notif.type.toUpperCase().split("_").join(" ") }}
          </div>
          <div class="notificationContent">
            {{ notif.remarks }}
          </div>
        </div>
      </div>
      <button mat-button [matMenuTriggerFor]="menu" style="padding: 0">
        <img
          [src]="photo"
          style="width: 35px; border-radius: 25px"
          alt=""
          srcset=""
          class="side_nav_bottom_dp"
        />
      </button>
      <mat-menu #menu="matMenu">
        <div
          style="
            color: black;
            padding: 10px;
            font-weight: 700;
            border-bottom: 1px solid #a3c17e;
            width: 100%;
            max-width: 10rem;
            margin-right: 1rem;
          "
        >
          {{ username.toUpperCase() }}
        </div>
        <button mat-menu-item (click)="navigate('/profile')">
          <img style="width: 25px" [src]="tPhoto" alt="" /> Profile
        </button>
        <button mat-menu-item (click)="navigate('/login')">
          <img
            style="width: 25px"
            src="../../../assets/img/logout.svg"
            alt=""
          />
          Logout
        </button>
      </mat-menu>
    </div>
    <div class="header">SETTINGS</div>
    <div class="tabs">
      <div class="leftTab">
        <div
          class="selectorTab"
          (click)="selectorTab('storage')"
          [ngClass]="selectorTabData == 'storage' ? 'borderBottom' : ''"
        >
          STORAGE
        </div>
        <div
          class="selectorTab"
          (click)="selectorTab('license')"
          [ngClass]="selectorTabData == 'license' ? 'borderBottom' : ''"
        >
          LICENSES
        </div>
      </div>
    </div>
    <div class="settings_content_main_div">
      <div
        class="storageContainer"
        style="height: 40%"
        *ngIf="selectorTabData == 'storage'"
      >
        <div>
          <div class="storage_input_container">
            <label>Individual Retail User Account Storage Limit : </label>
            <input
              type="number"
              name="individualStorage"
              [(ngModel)]="retailStorage"
            />
            <label>mb</label>
          </div>
          <div class="storage_input_container">
            <label>Individual Upload File Size Limit (all accounts) : </label>
            <input
              type="number"
              name="individualStorage"
              [(ngModel)]="individualStorage"
            />
            <label>mb</label>
          </div>
        </div>
        <div style="align-self: flex-end">
          <div class="storageSubmit" (click)="confirmStorageSubmit('storage')">
            SUBMIT
          </div>
        </div>
      </div>
      <div
        class="storageContainer"
        style="height: 40%"
        *ngIf="selectorTabData == 'license'"
      >
        <div>
          <div class="storage_input_container">
            <label>Retail Users License Limit : </label>
            <input
              type="number"
              name="individualStorage"
              [(ngModel)]="retailLicenseCount"
            />
          </div>
        </div>
        <div style="align-self: flex-end">
          <div class="storageSubmit" (click)="confirmStorageSubmit('license')">
            SUBMIT
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
