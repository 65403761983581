import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class FssaiInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toster: ToastrService,
  ) {}
  token: any = localStorage.getItem('token');
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const reqCopy = request.clone({
      setHeaders: {
        Authorization: 'token ' + localStorage.getItem('token'),
      },
    });
    return next.handle(reqCopy).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (
          (error.error.detail == 'Invalid Token' ||
            error.error.detail == 'The Token is expired') &&
          error.status === 401
        ) {
          this.router.navigate(['/login']);
          this.toster.error('Login Expired', '', { timeOut: 2000 });
        }
        return throwError(error);
      }),
    );
  }
}
