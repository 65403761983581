import { Component, OnInit } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { HttpService } from './services/api/http.service';
import { DataService } from './services/data-share/data.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  public spinkit = Spinkit;
  title: any;
  constructor(
    private httpSv: HttpService,
    private dataShare: DataService,
  ) {}
  ngOnInit(): void {
    // this.httpSv.getProfileDtls().subscribe((res:any) => {
    //   if(res['status']){
    //     this.dataShare.profileDtls = res['results']
    //   }
    // })
  }
}
