//Reset password dialog
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/api/http.service';
import { DataService } from 'src/app/services/data-share/data.service';
import { AES, enc } from 'crypto-js';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'reset-password-dialog',
  templateUrl: './reset_password_dialog.html',
  styleUrls: ['./reset_password.css'],
})
export class DialogResetPassword implements OnInit {
  newPassword: String = '';
  cfPassword: String = '';
  passwordToggleHide: boolean = true;
  conpasswordToggleHide: boolean = true;
  role: any;
  constructor(
    public dialogRef: MatDialogRef<DialogResetPassword>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpService,
    private dataShare: DataService,
    private toster: ToastrService,
  ) {}

  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      this.role = AES.decrypt(roleIdEncoded, this.dataShare.secretKey).toString(
        enc.Utf8,
      );
      this.dataShare.roleId = this.role;
    }
  }
  close() {
    this.dialogRef.close(false);
  }
  update() {
    const data = {
      new_password: this.newPassword,
      confirm_password: this.cfPassword,
    };
    if (this.newPassword != this.cfPassword) {
      this.toster.error('Password does not match', '', { timeOut: 2000 });
      return;
    } else {
      this.http
        .resetPassword(
          this.data.element,
          this.role,
          data,
          this.dataShare.selectedTable,
        )
        ?.subscribe(
          (res: any) => {
            if (res['status']) {
              this.toster.success(
                'Password has been updated successfully.',
                '',
                { timeOut: 2000 },
              );
              this.dialogRef.close();
            } else {
              this.toster.error('Oops! Something went wrong', '', {
                timeOut: 2000,
              });
            }
          },
          (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 });
          },
        );
    }
  }
}
