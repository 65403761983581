<div class="ces_main_div">
  <div class="ces_header">
    <span>Modify Business Manager</span>
    <div class="header_quik_functions">
      <img
        src="../../../assets/img/reset_icon.svg"
        alt=""
        (click)="handleChangePassword()"
      />
      <img
        src="../../../assets/img/remove_icon_bold.svg"
        alt=""
        (click)="handleDeleteUser()"
      />
      <img src="../../../assets/img/close.svg" alt="" (click)="close()" />
    </div>
  </div>
  <div class="ces_content_main_div">
    <div class="ces_inp_main_div">
      <!-- ------------------------------------------ PERSONAL DETAILS --------------------------------------------------- -->

      <div class="ces_pd_main_div">
        <div class="ces_pd_inp_main_div">
          <div class="ces_pd_inp_sub_div">
            <label for="name">Name</label>
            <input type="text" name="name" [(ngModel)]="name" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" name="email" [(ngModel)]="email" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" name="mobile" [(ngModel)]="number" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="city">Address</label>
            <input type="text" name="city" [(ngModel)]="address" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="city">City</label>
            <input type="text" name="city" [(ngModel)]="city" />
          </div>
          <div class="ces_pd_inp_sub_div">
            <label for="comp_name">Company Name</label>
            <input type="text" name="comp_name" [(ngModel)]="compName" />
          </div>
          <!-- <div class="ces_pd_inp_sub_div" >
                        <label for="state">State</label>
                        <ng-select style="margin-top: 15px;" class="multi-select" [items]="allState" bindLabel="name" bindValue="id"
                            [multiple]="false" groupBy="selectedAllGroup" [selectableGroup]="true" name="KOB"
                            placeholder="Select state" (change)="onMaterialGroupChange($event)"
                            [(ngModel)]="selectedState">

                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
    
                            </ng-template>
                        </ng-select>
                    </div> -->
          <div class="ces_pd_inp_sub_div">
            <label for="status">Status</label>
            <div class="dropdown">
              <button
                class="btn dropdown-toggle updateSelect"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style="width: 100%; text-align: left; min-height: 48px"
              >
                <span *ngIf="txtStatus == ''">&nbsp;</span>
                <span
                  *ngIf="txtStatus != ''"
                  [ngClass]="txtStatus == 'active'?'colorGreen':txtStatus == 'inactive'?'colorRed':'colorBlue'"
                >
                  {{txtStatus}}</span
                >
              </button>
              <ul class="dropdown-menu" style="width: 100%">
                <li
                  class="dropdown-item"
                  style="color: #12c058"
                  [ngClass]="selectedFilter.active?'filterActiveCss':''"
                  (click)="filterByStatus('Active')"
                >
                  Active
                </li>
                <li
                  class="dropdown-item"
                  style="color: #d43738"
                  [ngClass]="selectedFilter.expired?'filterActiveCss':''"
                  (click)="filterByStatus('Inactive')"
                >
                  Inactive
                </li>
                <!-- <li class="dropdown-item" style="color: #1968B1;"
                                    [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                                    (click)="filterByStatus('Requested')">
                                    Requested</li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="flex: 1">&nbsp;</div>
  <div class="ces_btn_main_div">
    <div class="" *ngIf="role != '1'"></div>
    <div class="ces_pd_btn_div">
      <div (click)="close()">CANCEL</div>
      <div (click)="update()">UPDATE</div>
    </div>
  </div>
</div>
