<div class="side_bar_common_css">
  <app-sidenav-bar></app-sidenav-bar>
  <div class="app_user_main_div">
    <div class="header">APP USER</div>
    <div class="app_user_content_main_div">
      <div>
        <div class="app_user_search_main_div">
          <div class="web_user_search_sub_div">
            <div class="search_icon_inp_div">
              <img
                src="../../../assets/img/search_icon.svg"
                alt=""
                class="search_icon_inp_icon"
              />
              <input
                type="text"
                class="search_icon_inp"
                placeholder="Search"
                (keyup)="applyFilter($event)"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="app_user_table_main_div">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <!-- User Name  -->
          <ng-container matColumnDef="name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Business End User Name
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>

          <!-- Mobile number -->
          <ng-container matColumnDef="mobile_no">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Company Name
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.weight }}
            </td>
          </ng-container>

          <!-- Location -->
          <ng-container matColumnDef="location">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Location
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.symbol }}
            </td>
          </ng-container>
          <!-- Reset Password -->
          <ng-container matColumnDef="reset_password">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Reset Password
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/reset_icon.svg"
                alt=""
                srcset=""
                (click)="resetPassword()"
                style="cursor: pointer"
              />
            </td>
          </ng-container>

          <!-- Modify -->
          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/tabler_dots.svg"
                alt=""
                style="cursor: pointer"
                (click)="modifyAppUser()"
              />
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
